// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.freemium-modal h1 {
  font-size: 22px;
  color: #181c32;
}
.freemium-modal p {
  color: #3f4254;
  font-size: 16px;
}
.freemium-modal li, .freemium-modal .ant-typography {
  font-size: 15px;
  color: #5e6278;
}
.freemium-modal .ant-btn-primary {
  height: 38px;
  font-weight: 500;
  border: none;
}
.freemium-modal .ant-btn-default {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/coach-module/freemium-modal/freemium-modal.scss"],"names":[],"mappings":"AACI;EACI,eAAA;EACA,cAAA;AAAR;AAEI;EACI,cAAA;EACA,eAAA;AAAR;AAEI;EACI,eAAA;EACA,cAAA;AAAR;AAGI;EACI,YAAA;EACA,gBAAA;EACA,YAAA;AADR;AAGI;EACI,aAAA;AADR","sourcesContent":[".freemium-modal{\n    h1{\n        font-size: 22px;\n        color: #181c32;\n    }\n    p{\n        color: #3f4254;\n        font-size: 16px;\n    }\n    li , .ant-typography{\n        font-size: 15px;\n        color: #5e6278;\n\n    }\n    .ant-btn-primary{\n        height: 38px;\n        font-weight: 500;\n        border: none;\n    }\n    .ant-btn-default{\n        display: none;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
