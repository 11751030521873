import {
  Button,
  Card,
  Col,
  Image,
  Row,
  Input,
  Checkbox,
  Switch,
  Modal,
} from "antd";
import "./index.scss";
import React, { useContext, useState } from "react";
import ReactPlayer from "react-player";
import { convertSeconds } from "../../../util/config";
import SortableList, { SortableItem, SortableKnob } from "react-easy-sort";
import DragIndicator from "../../../assets/images/drag_indicator.svg";
import Delete from "../../../assets/images/delete.svg";
import Plus from "../../../assets/images/plus-red.svg";
import ExistingTask from "./existing-task-modal";
// import ExistingTask from "../new-workout/existing-task-modal";
// import NewTask from "../new-workout/new-task-modal";
import NewTask from "../../Groups/new-workout/new-task-modal";
import File from "../../../assets/images/files-folders.svg";
import AssignedWorkout from "./assigned-workout";
// import AssignedWorkout from "../../Groups/new-workout/assigned-workout";
import WorkoutPrivacyModal from "../../Groups/new-workout/privacy-modal";
// import WorkoutPrivacyModal from "../new-workout/privacy-modal";
import { UserContext } from "../../../context/user-context";
import {
  AssignMultipleGroupsContext,
  AssignMultipleGroupsContextProvider,
} from "../../../coach-context/team-training-context/assign-multiple-groups-context";
let totalReps = 0;
let updateData = false;
const AssignMultipleGroupsPage = ({
  setShowAllWorkouts,
  setWorkoutUpdateModal,
  setNewWorkout,
  generalDetail,
}) => {
  // const {
  //   workoutTasks,
  //   // deleteTask,
  //   setError,
  //   title,
  //   setTitle,
  //   error,
  //   setExistingTaskModal,
  //   existingTaskModal,
  //   setNewTaskModal,
  //   newTaskModal,
  //   createExistingWorkout,
  //   membersView,
  //   setMembersView,
  //   createdWorkout,
  //   selectedWorkoutTasks,
  //   // setSelectedWorkoutTasks,
  //   // privacy,
  //   // setPrivacy,
  //   setPrivacyModal,
  //   privacyModal,
  //   current ,
  //   setCurrent
  // } = useContext(ExistingWorkoutContext);
  const {
    workoutTasks,
    // deleteTask,
    setError,
    title,
    setTitle,
    error,
    setExistingTaskModal,
    existingTaskModal,
    setNewTaskModal,
    newTaskModal,
    createExistingWorkout,
    membersView,
    setMembersView,
    createdWorkout,
    selectedWorkoutTasks,
    // setSelectedWorkoutTasks,
    // privacy,
    // setPrivacy,
    setPrivacyModal,
    privacyModal,
    current,
    setCurrent,
    newTaskArray,
    setNewTaskArray,
    createWorkout,
    assignGroupView,
    setAssignGroupView,
  } = useContext(AssignMultipleGroupsContext);
  const { selectedSport, color, lightButtonBackground, darkButtonBackground } =
    useContext(UserContext);
  const [selectedTask, setSelectedTask] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletedItem, setDeletedItem] = useState({});
  const [url, setUrl] = useState("");

  const showExistingTaskModal = () => {
    setExistingTaskModal(true);
  };
  const showNewTaskModal = () => {
    setNewTaskModal(true);
  };
  const CustomKnob = React.forwardRef((props, ref) => {
    return (
      <Button
        ref={ref}
        icon={
          <img src={DragIndicator} className="drag-icon" draggable={false} />
        }
        style={{ cursor: "grab" }}
      ></Button>
    );
  });
  const onSortEnd = (oldIndex, newIndex) => {
    setNewTaskArray((prevState) => {
      const newItems = [...prevState];
      if (oldIndex > newIndex) {
        for (let i = oldIndex; i >= newIndex; i--) {
          newItems[i].order++;
          newItems[oldIndex].order = newIndex + 1;
        }
      } else if (oldIndex < newIndex) {
        for (let i = oldIndex; i <= newIndex; i++) {
          newItems[i].order--;
          newItems[oldIndex].order = newIndex;
        }
      }
      return newItems.sort((a, b) => a.order - b.order);
    });
  };

  const updateState = (index) => (e) => {
    const newArray = newTaskArray.map((item, i) => {
      if (index === i) {
        if (e.target) {
          return {
            ...item,
            [e.target.name]:
              typeof e.target.value === "string"
                ? parseInt(e.target.value)
                : e.target.checked,
          };
        } else {
          if (e === true) {
            setCurrent(index);
          } else {
            setCurrent();
          }
          return { ...item, lock: e };
        }
      } else {
        return item;
      }
    });
    setNewTaskArray(newArray);
  };
  const handleOk = () => {
    let newArray = newTaskArray.filter((e) => e.id !== deletedItem.id);
    setNewTaskArray(newArray);
    setDeletedItem({});
    setDeleteModal(false);
  };
  const deleteTask = (item) => {
    setDeletedItem(item);
    setDeleteModal(true);
  };

  // let tags = workoutTasks.length > 0 && workoutTasks.tags.split(",");

  // useEffect(() => {
  //   setPrivacy(workoutTasks?.is_public)
  //   setTitle(workoutTasks?.title);
  // }, [workoutTasks]);
  React.useEffect(() => {
    // setSelectedWorkoutTasks(workoutTasks?.tasks);
  }, []);
  let taskPrivacyArray = [];
  // const checkPrivacy = (e) => {
  //   selectedWorkoutTasks.map((e)=>taskPrivacyArray.push(e.is_public))
  //   if(taskPrivacyArray.every( (val, i, arr) => val === arr[0]) === false){
  //     setPrivacyModal(true)
  //   }else{
  //     setPrivacy(e.target.checked)
  //   }
  // }
  // useEffect(()=>{
  //   if(selectedWorkoutTasks.length !== 0 && selectedWorkoutTasks.length > workoutTasks?.tasks.length){
  //   setPrivacy(false)
  //   }
  // }, [selectedWorkoutTasks.length])
  totalReps = newTaskArray.reduce((accumulator, object) => {
    return accumulator + object.num_completion;
  }, 0);
  return (
    <div className="assign-group-main">
      <div className="tasks-filter tasks-list">
        <Row style={{ width: "100%" }}>
          {!assignGroupView && (
            <>
              <div style={{ width: "55%" }}>
                <>
                  <label htmlFor="category">Workout Title</label>
                  <br />
                  <Input
                    className="category-input"
                    onChange={(e) => {
                      setError(false);
                      setTitle(e.target.value);
                    }}
                    defaultValue={workoutTasks?.title}
                  />
                  {error && (
                    <span className="error-message" style={{ marginTop: 0 }}>
                      Please enter workout title.
                    </span>
                  )}
                  <div className="btn-div">
                    <Button
                      icon={<img src={File} />}
                      className="existing-task-btn"
                      onClick={showExistingTaskModal}
                    >
                      Add from existing tasks
                    </Button>
                    <Button
                      icon={
                        <img
                          src={Plus}
                          className={
                            selectedSport === 1
                              ? "orange-theme-icon"
                              : "blue-theme-icon"
                          }
                        />
                      }
                      className="new-task-btn"
                      onClick={showNewTaskModal}
                      style={{
                        borderColor: color,
                        background: lightButtonBackground,
                        color: color,
                      }}
                    >
                      Create a new task
                    </Button>
                  </div>
                </>
                {newTaskArray?.length > 0 && (
                  <div className="workout-overview">
                    <h3>Workout Overview</h3>
                    <div className="total">
                      Total Tasks
                      <span>
                        {newTaskArray &&
                          newTaskArray.filter(
                            (ele, ind) =>
                              ind ===
                              newTaskArray.findIndex(
                                (elem) => elem.id === ele.id
                              )
                          ).length}
                      </span>
                      {!url.includes("challenge") && (
                        <>
                          Total Reps
                          <span>{isNaN(totalReps) ? 0 : totalReps}</span>
                        </>
                      )}
                    </div>

                    <SortableList
                      onSortEnd={onSortEnd}
                      // className="list"
                      draggedItemClassName="dragged"
                    >
                      {newTaskArray &&
                        newTaskArray
                          .filter(
                            (ele, ind) =>
                              ind ===
                              newTaskArray.findIndex(
                                (elem) => elem.id === ele.id
                              )
                          )
                          .map((item, index) => {
                            return (
                              <SortableItem key={index}>
                                <div
                                  className="overview-card"
                                  style={{ listStyleType: "none" }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <h3>{item.title}</h3>
                                    <div className="right-div">
                                      {/* <Button icon={<img src={Edit} />}></Button> */}
                                      <Button
                                        icon={
                                          <img src={Delete} draggable={false} />
                                        }
                                        onClick={() => deleteTask(item)}
                                      ></Button>
                                      <SortableKnob>
                                        <CustomKnob />
                                      </SortableKnob>
                                    </div>
                                  </div>
                                  <div className="form-data">
                                    <div className="reps">
                                      <label htmlFor="complete-task">
                                        Complete task in
                                      </label>
                                      <br />
                                      <Input
                                        addonBefore={"Reps"}
                                        defaultValue={item.num_completion}
                                        className="reps-input"
                                        name={"num_completion"}
                                        onChange={updateState(index)}
                                        min={1}
                                        value={
                                          item.num_completion > 0
                                            ? item.num_completion
                                            : ""
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="extra">
                                    <Checkbox
                                      className="custom-checkbox"
                                      defaultChecked={item.allow_more_reps}
                                      checked={item.allow_more_reps}
                                      onChange={updateState(index)}
                                      name="allow_more_reps"
                                    />
                                    <span className="allowed">
                                      Allowed extra reps.
                                    </span>
                                    {index !== 0 && (
                                      <div className="lock-div">
                                        Lock this task
                                        <Switch
                                          defaultChecked={item.lock}
                                          checked={index >= current}
                                          disabled={index > current}
                                          name="lock"
                                          onChange={updateState(index)}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </SortableItem>
                            );
                          })}
                    </SortableList>
                    {/* <div style={{ display: "flex" }}>
                    <Checkbox
                      className="custom-checkbox"
                      // checked={privacy}
                      name="workout_privacy"
                      // onChange={checkPrivacy}
                      // defaultChecked={workoutTasks?.is_public}
                      // defaultChecked={true}
                    />
                    <p className="privacy">
                      Allow other coaches to assign this Workout Template to
                      their players
                    </p>
                  </div>  */}
                    <div style={{ marginTop: "24px" }}>
                      <button
                        className="cancel-btn"
                        onClick={() => setShowAllWorkouts(true)}
                      >
                        Cancel
                      </button>
                      <Button
                        className="save-btn"
                        onClick={() => createWorkout()}
                        style={{ background: darkButtonBackground }}
                      >
                        Create Workout
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              {Object.keys(selectedTask).length > 0 && (
                <div
                  className="task-detail"
                  style={{
                    paddingLeft: 100,
                    width: "45%",
                  }}
                >
                  <Row style={{ gap: 24 }}>
                    <span className="span">Task Details</span>
                    <h1>{selectedTask.title}</h1>
                    <Row>
                      <Col span={10} className="label-col">
                        <h3>Task type</h3>
                      </Col>
                      <Col span={14} className="data-col">
                        <h3>{selectedTask.type}</h3>
                      </Col>
                    </Row>
                    {selectedTask.media_required === true && (
                      <Row>
                        <Col span={10} className="label-col">
                          <h3>Time limit</h3>
                        </Col>
                        <Col span={14} className="data-col">
                          <h3>
                            {convertSeconds(selectedTask.time_limit_seconds)}
                          </h3>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col span={10} className="label-col">
                        <h3>Task Library status</h3>
                      </Col>
                      <Col span={14} className="data-col">
                        <h3>
                          {selectedTask.is_public === true
                            ? "Public"
                            : "Private"}
                        </h3>
                      </Col>
                    </Row>
                    {selectedTask.media_type && (
                      <>
                        <Col span={24} className="data-col">
                          <h3
                            style={{
                              fontSize: 18,
                              fontWeight: 600,
                              marginBottom: 8,
                            }}
                          >
                            Media
                          </h3>
                        </Col>
                        <Col span={24} className="label-col">
                          <Card hoverable className="media-card">
                            {selectedTask.media_type === "image" ? (
                              <div
                                style={{
                                  overflow: "hidden",
                                  height: "150px",
                                  borderRadius: 8,
                                }}
                              >
                                <Image
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                  }}
                                  className="img"
                                  src={selectedTask.image}
                                />
                              </div>
                            ) : (
                              <ReactPlayer url={url} controls key={url} />
                            )}
                          </Card>
                        </Col>
                      </>
                    )}
                    <Col span={24} className="data-col">
                      <h3 style={{ fontSize: 18 }}>Instructions </h3>
                    </Col>
                    <Row>
                      <Col span={6} className="label-col">
                        <h3>Step 1</h3>
                      </Col>
                      <Col span={18} className="data-col">
                        <h3>Watch/read the instructions above</h3>
                      </Col>
                    </Row>
                    {selectedTask.media_required === true && (
                      <Row>
                        <Col span={6} className="label-col">
                          <h3>Step 2</h3>
                        </Col>
                        <Col span={18} className="data-col">
                          <h3>
                            Record yourself completing the task and upload your
                            video.*
                          </h3>
                        </Col>
                      </Row>
                    )}
                  </Row>
                </div>
              )}
            </>
          )}
          {/* {membersView && ( */}
          {assignGroupView && (
            <AssignedWorkout
              createdWorkout={createdWorkout}
              setAssignGroupView={setAssignGroupView}
              setNewWorkout={setNewWorkout}
            />
          )}
        </Row>
        {existingTaskModal && (
          <ExistingTask
            existingTaskModal={existingTaskModal}
            setExistingTaskModal={setExistingTaskModal}
            setNewTaskArray={setNewTaskArray}
            newTaskArray={newTaskArray}
            generalDetail={generalDetail}
          />
        )}
        {newTaskModal && (
          <NewTask
            newTaskModal={newTaskModal}
            setNewTaskModal={setNewTaskModal}
            newTaskArray={newTaskArray}
            setNewTaskArray={setNewTaskArray}
            generalDetail={generalDetail}
          />
        )}
        <Modal
          className="delete-task-modal"
          wrapClassName={
            selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal"
          }
          open={deleteModal}
          onOk={handleOk}
          onCancel={() => setDeleteModal(false)}
          okText={"Remove"}
          cancelText={"Cancel"}
          width={"608px"}
        >
          <h1>Remove this task</h1>
          <p>Are you sure you want to remove this task? </p>
        </Modal>
        <WorkoutPrivacyModal
          privacyModal={privacyModal}
          setPrivacyModal={setPrivacyModal}
          // setPrivacy={setPrivacy}
        />
      </div>
    </div>
  );
};

const AssignMultipleGroups = () => {
  return (
    <AssignMultipleGroupsContextProvider>
      <AssignMultipleGroupsPage />
    </AssignMultipleGroupsContextProvider>
  );
};

export default AssignMultipleGroups;
