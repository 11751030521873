import React, { useEffect, useState, useContext } from "react";
import {
  deleteItem,
  getDataCoach,
  jsonPostDataCoach,
} from "../../util/coach-api-calls";
import { UserContext } from "../../context/user-context";
import { message, notification as antdNotification } from "antd";
antdNotification.config({
  placement: "top",
  duration: 20,
});
export const ManageSubscriptionContext = React.createContext({});
export const ManageSubscriptionContextProvider = ({ children = {} }) => {
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const { selectedSport } = useContext(UserContext);
  const [noSeatModal, setNoSeatModal] = useState(false);
  const [membersData, setMembersData] = useState();
  const [licensesData, setLicensesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [search, setSearch] = useState("");
  const [totalMembers, setTotalMembers] = useState(0);
  const [allMembers, setAllMembers] = useState([]);
  const [imageLoaded, setImageLoaded] = useState("");

  const [pageNumber, setPageNumber] = useState(1);
  const [clickedRow, setClickedRow] = useState({});
  const [manageSubscription, setManageSubscription] = useState();
  let profileId = localStorage.getItem("profile_id");
  const notification = {
    open: ({ message, description }) => {
      antdNotification.open({
        message,
        description,
        className: "notification",
        duration: 2,
        closable: false,
        style: {
          width: 795,
          minWidth: 320,
          maxWidth: 795,
          backgroundColor: "#FFF1ED",
          margin: 0,
          boxShadow: "unset",
          color: "#7E8299",
        },
      });
    },
  };
  const getLicenses = () => {
    setLoading(true);
    getDataCoach(`profile/team/management/licenses/${profileId}`)
      .then((res) => {
        setLicensesData(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (search.length > 0) {
      const delayDebounceFn = setTimeout(() => {
        listAllMembers();
      }, 1000);
      return () => clearTimeout(delayDebounceFn);
    } else {
      setTotalMembers(0);
      setAllMembers((allMembers) => []);
      setPageNumber(1);
      setHasMore(false);
    }
  }, [search]);
  const listAllMembers = (e) => {
    if (pageNumber === 1) {
      setLoading(true);
    }
    let url;
    if (!search) {
      url = `team/management/all-profiles/${licensesData?.team_management_tiers[0]?.team_management_detail?.id}?page=${pageNumber}`;
    } else {
      url = `team/management/all-profiles/${licensesData?.team_management_tiers[0]?.team_management_detail?.id}?search=${search}&page=${pageNumber}`;
    }
    getDataCoach(url)
      .then((res) => {
        setTotalMembers(res.data.count);
        setAllMembers([...allMembers, ...res.data.results.profiles]);
        if (res.data.next !== null) {
          setPageNumber(pageNumber + 1);
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const inviteMembers = (item) => {
    let data = {
      profile_id: item.id,
      team_management_id: manageSubscription?.team_management_detail?.id,
    };
    jsonPostDataCoach(`profile/team/management/invite/`, data)
      .then((res) => {
        notification.open({
          message: `Invitation Sent!`,
          description: `You successfully invited ${item.username} to your group. A notification has been sent and must be approved before you will see this user’s name in your account holders list.`,
          placement: "top",
        });
        listAllMembers();
      })
      .catch((err) => {
        console.log(err);
        message.error({
          content: err?.response?.data?.message,
        });
      });
  };
  const getManageSubscription = (id) => {
    getDataCoach(`profile/manage/your/subscription/${id}`)
      .then((res) => {
        setManageSubscription(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const removeAccountHolder = (id) => {
    console.log(id);
    deleteItem(`team/management/account/holder/${id}`)
      .then((res) => {
        console.log(res);
        setLicensesData([])
        setManageSubscription();
        message.success({
          content: "Account holder deleted successfully",
        });
        getManageSubscription(licensesData?.team_management_tiers[0]?.team_management_detail?.id);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getLicenses();
  }, []);
  const defaultContext = {
    noSeatModal,
    setNoSeatModal,
    licensesData,
    loading,
    setLoading,
    clickedRow,
    setClickedRow,
    getManageSubscription,

    allMembers,
    totalMembers,
    listAllMembers,
    hasMore,
    setAllMembers,
    imageLoaded,
    setImageLoaded,
    search,
    setPageNumber,
    setSearch,
    inviteMembers,
    membersData,
    setTotalMembers,
    setHasMore,
    manageSubscription,
    removeAccountHolder,
  };
  return (
    <ManageSubscriptionContext.Provider value={defaultContext}>
      {children}
    </ManageSubscriptionContext.Provider>
  );
};
