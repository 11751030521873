import { useContext, useEffect, useState } from "react";
import "./index.scss";
import { Button, Col, Divider, Form, Row, Skeleton } from "antd";
import Avatar from "react-avatar";
import { UserContext } from "../../../context/user-context";
import Delete from "../../../assets/images/delete1.svg";
import Plus from "../../../assets/images/plus-red.svg";
import {
  ManageSubscriptionContext,
  ManageSubscriptionContextProvider,
} from "../../../coach-context/team-training-context/manage-subscription-context";
import { useNavigate } from "react-router-dom";
import InviteAccountHolders from "./invite-modal";
import { getDate } from "../../../util/config";
let teamManagementDetail;
const ManageSubscriptionPage = () => {
  const {
    licensesData,
    getManageSubscription,
    manageSubscription,
    removeAccountHolder,
  } = useContext(ManageSubscriptionContext);
  const [imageLoaded, setImageLoaded] = useState("");
  const [inviteModal, setInviteModal] = useState(false);
  const { selectedSport } = useContext(UserContext);
  useEffect(() => {
    if (Object.keys(licensesData)?.length > 0) {
      teamManagementDetail =
        licensesData?.team_management_tiers[0]?.team_management_detail;
      getManageSubscription(teamManagementDetail.id);
    }
  }, [licensesData]);
  return (
    <div className="manage-sub-main">
      <label>Subscription Overview</label>
      <Divider />
      <div className="data-div">
        <div style={{ display: "flex" }}>
          <h3 style={{ margin: 0 }}>
            {teamManagementDetail?.tier_type === "tier1"
              ? "Tier 1 Subscription"
              : teamManagementDetail?.tier_type === "tier2"
              ? "Tier 2 Subscription"
              : teamManagementDetail?.tier_type === "tier3"
              ? "Tier 3 Subscription"
              : teamManagementDetail?.tier_type === "free_tier"
              ? "Free Tier"
              : teamManagementDetail?.tier_type === "tier4" &&
                "Tier 4 Subscription"}{" "}
          </h3>
          {teamManagementDetail?.tier_type !== "free_tier" && (
            <div className="sub-end">
              Subscription Start:{" "}
              {getDate(teamManagementDetail?.subscription_start_date)}
            </div>
          )}
        </div>
        <div style={{ display: "flex", marginTop: 16 }}>
          <div className="seats">
            Member Seats (<span>{teamManagementDetail?.used_seats}</span> Used |{" "}
            <span>{teamManagementDetail?.pending_seats}</span> Pending |{" "}
            <span>{teamManagementDetail?.remaining_seats}</span> Remaining)
          </div>
          {teamManagementDetail?.tier_type !== "free_tier" && (
            <div className="sub-end">
              Subscription Ends:{" "}
              {getDate(teamManagementDetail?.subscription_end_date)}
            </div>
          )}
        </div>
        <div className="sub-detail">
          <h4>Payment Details</h4>
          <Divider />
          {manageSubscription?.subscription_detail !== "" ? (
            <>
              {teamManagementDetail?.subscription_from === "android" ||
              teamManagementDetail?.subscription_from === "ios" ? (
                <p>Your Subscription was purchased through the Ballogy App.</p>
              ) : teamManagementDetail?.subscription_from === "web" ? (
                <p>
                  Your Subscription was purchased through the Ballogy Website.
                  To make changes{" "}
                  <span
                    style={{
                      color: "#F15A29",
                      fontWeight: 600,
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      window.open(
                        "https://www.ballogy.com/subscription-plans/",
                        "_blank"
                      )
                    }
                  >
                    click here
                  </span>
                  .
                </p>
              ) : (
                <>
                  <p>
                    Your Subscription was purchased via the Ballogy App. Contact
                    the original purchaser (
                    <span style={{ color: "#F15A29", fontWeight: 600 }}>
                      @
                      {
                        manageSubscription?.team_management_detail?.created_by
                          ?.username
                      }
                    </span>
                    ) to make changes.{" "}
                  </p>
                  {/* <p>
                    Your Subscription was purchased via offline payment. To make
                    changes{" "}
                    <span style={{ color: "#F15A29", fontWeight: 600 }}>
                      contact us
                    </span>
                    .
                  </p> */}
                </>
              )}
            </>
          ) : (
            <p>
              You are currently subscribed to the free tier and are limited to
              10 seats. To make changes,
              <span
                style={{ color: "#F15A29", fontWeight: 600, cursor: "pointer" }}
                onClick={() =>
                  window.open(
                    "https://www.ballogy.com/subscription-plans/",
                    "_blank"
                  )
                }
              >
                tap here.
              </span>
            </p>
          )}{" "}
          <div style={{ display: "flex", marginTop: 25 }}>
            <h4 style={{ margin: "7px 0" }}>Account Holders</h4>
            <Button
              className="manage-sub"
              icon={<img src={Plus} />}
              onClick={() => setInviteModal(true)}
            >
              Invite Account Holders
            </Button>
          </div>
          <Divider />
          <Row className="account-holders-list">
            {manageSubscription?.account_holders.map((item, index) => {
              return (
                <Col
                  span={24}
                  style={{ display: "flex", marginBottom: 20 }}
                  key={index}
                >
                  {item.profile.avatar && imageLoaded === "" && (
                    <Skeleton.Image active={true} className="user-skeleton" />
                  )}
                  {!item.profile.avatar ? (
                    <Avatar
                      name={item.profile.username}
                      color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
                      className="user-avatar"
                      maxInitials={1}
                    />
                  ) : (
                    <img
                      src={item.profile.avatar}
                      style={{
                        display: imageLoaded === "" ? "none" : "block",
                        width: 40,
                        height: 40,
                        borderRadius: 6,
                      }}
                      onLoad={() => setImageLoaded("loaded")}
                    ></img>
                  )}
                  <div className="name-div">
                    <div style={{ display: "flex" }}>
                      <div style={{ width: 200 }}>
                        <h3 className="user-name">
                          {item.profile.first_name +
                            " " +
                            item.profile.last_name}
                        </h3>
                        <h4 className="user-handle">
                          @{item.profile.username}
                        </h4>
                      </div>
                      <img
                        src={Delete}
                        onClick={() => removeAccountHolder(item.id)}
                        style={{
                          margin: "11px 0",
                          opacity: item.is_license_purchased ? 0.5 : "unset",
                          cursor: item.is_license_purchased
                            ? "not-allowed"
                            : "pointer",
                        }}
                      />
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
      <InviteAccountHolders
        inviteModal={inviteModal}
        setInviteModal={setInviteModal}
      />
    </div>
  );
};

const ManageSubscription = () => {
  return (
    <ManageSubscriptionContextProvider>
      <ManageSubscriptionPage />
    </ManageSubscriptionContextProvider>
  );
};

export default ManageSubscription;
