import {
  Button,
  Modal,
  Spin,
  Col,
  Row,
  Input,
  Select,
  Table,
  TimePicker,
} from "antd";
import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  Fragment,
} from "react";
import ReactPlayer from "react-player";
import { getDateWithTimeWithoutDay } from "../../../util/config";
import Avatar from "react-avatar";
import Delete from "../../../assets/images/delete.svg";
import DownArrow from "../../../assets/images/arrows.svg";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { UserContext } from "../../../context/user-context";
import dayjs from "dayjs";
import { VisionShootingContext } from "../../../context/group-context/vision-shooting-context";
import Chart from "../../shared/chart";
const style = {
  padding: "5px 0",
};
let isEditable;
let enableStart = 0;
let enableEnd = 0;
const ShootingDetailModal = () => {
  const playerRef = useRef(null)
  const [playing, setPlaying] = useState(false); // Control playing state
  const wrapperRef = useRef(null);
  const {
    shootingDetail,
    shootingDetailModal,
    setShootingDetailModal,
    setShootingDetail,
    setLoading,
    activeTab,
    deleteTeamSession,
    reviewSession,
    updateSession,
    sessionTitle,
    setSessionTitle,
    titleUpdate,
    setTitleUpdate,
    setTeamOffset,
    getSuggestionsList,
    suggestionsList,
    searchTitle,
    setSearchTitle,
    showDropdown,
    setShowDropdown,
    setSuggestionsList,
    removeSuggestion,
    titleError,
    setTitleError,
    editingKey,
    make,
    setMake,
    attempts,
    setAttempts,
    setEditingKey,
    updataSession,
    loading,
    tableData,
    setTableData,
    setPtr2Make,
    setPtr3Make,
    setPtr2Attempts,
    ptr2Make,
    setPtr3Attempts,
    ptr3Make,
    ptr2Attempts,
    ptr3Attempts,
    newTableData,
    setNewTableData,
  } = useContext(VisionShootingContext);
  const { selectedSport, darkButtonBackground } = useContext(UserContext);
  const [imageLoaded, setImageLoaded] = useState("");
  const [selectedZone, setSelectedZone] = useState();
  const zone = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
  ];
  const point2Zone = ["F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P"];
  const point3Zone = ["A", "B", "C", "D", "E"];
  const zoneOptions = [];
  for (let i = 0; i < zone.length; i++) {
    zoneOptions.push({
      value: zone[i],
      label: zone[i],
    });
  }
  const cancelModal = () => {
    setEditingKey(false);
    setSearchTitle("");
    setShowDropdown(false);
    setTableData((tableData) => []);
    isEditable = undefined;
    setNewTableData((newTableData) => []);
    setTableData((tableData) => []);
    sessionOptions = [];
    setSuggestionsList([]);
    setShootingDetail({});
    setShootingDetailModal(false);
  };
  useEffect(() => {
    setSearchTitle(shootingDetail?.title);
    setSessionTitle(shootingDetail?.title);
    if (Object.keys(shootingDetail).length > 0) {
      setMake(shootingDetail?.total_make);
      setAttempts(shootingDetail?.total_attempts);
      setTableData(shootingDetail?.attempts);
      setNewTableData(shootingDetail?.attempts);
      setPtr2Make(shootingDetail?.total_2ptr_make);
      setPtr2Attempts(shootingDetail?.total_2ptr_attempts);
      setPtr3Attempts(shootingDetail?.total_3ptr_attempts);
      setPtr3Make(shootingDetail?.total_3ptr_make);
    }
    if (Object.keys(shootingDetail).length > 0 && activeTab === "individual") {
      reviewSession();
    }
  }, [shootingDetail]);
  let updatedArray = newTableData?.filter(
    (item) => item?.primary_action !== "added"
  );
  let sessionOptions = [];
  for (let i = 0; i < suggestionsList.length; i++) {
    sessionOptions.push({
      value: suggestionsList[i].id,
      label: suggestionsList[i].title,
    });
  }
  const changeTitleInput = (e) => {
    setSessionTitle(e.target.value);
    setSearchTitle(e.target.value);
  };

  const handleClickOutside = (e) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });
  useEffect(() => {
    if (suggestionsList?.length === 0 || searchTitle.length === 0) {
      setSuggestionsList([]);
      sessionOptions = [];
      setShowDropdown(false);
    }
    if (searchTitle === "") {
      setSearchTitle("");
      setShowDropdown(false);
      sessionOptions = [];
      setSuggestionsList([]);
    } else if (
      sessionTitle?.length > 2 &&
      searchTitle !== shootingDetail?.title
    ) {
      setSuggestionsList([]);
      sessionOptions = [];
      const delayDebounceFn = setTimeout(() => {
        getSuggestionsList();
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    } else {
      setShowDropdown(false);
      sessionOptions = [];
      setSuggestionsList([]);
    }
  }, [searchTitle]);
  const edit = (record) => {
    setMake(shootingDetail?.total_make);
    setAttempts(shootingDetail?.total_attempts);
    setEditingKey(true);
    if (tableData?.length === 0) {
      const newObject = {
        id: `temp_id_${Math.random().toString(36).substr(2, 9)}`,
        make: null,
        // zone: "A",
        zone: shootingDetail?.court === "NO_THREE_POINT_LINE" ? "" : "A",
        score: 0,
        timestamp: new Date().toISOString(),
        shot_start_time: 0,
        shot_end_time: null,
      };
      setNewTableData((prevArray) => [...prevArray, newObject]); // add new object to the array
    }
  };

  const updateMake = (index) => {
    const newArray = updatedArray.map((item, i) => {
      if (index === i && item.make !== true) {
        if (activeTab === "teams") {
          setMake(make + 1);
        } else {
          if (item.ptr === 2) {
            setPtr2Make(ptr2Make + 1);
          } else {
            setPtr3Make(ptr3Make + 1);
          }
        }
        return {
          ...item,
          make: true,
          score: 1,
          timestamp: new Date().toISOString(),
        };
      } else {
        return item;
      }
    });
    setNewTableData(newArray);
    // setTableData(newArray);
  };
  const updateMiss = (index) => {
    const newArray = updatedArray.map((item, i) => {
      if (index === i && item.make !== false) {
        if (item.make !== null) {
          if (activeTab === "teams") {
            setMake(make - 1);
          } else {
            if (item.ptr === 2) {
              setPtr2Make(ptr2Make - 1);
            } else {
              setPtr3Make(ptr3Make - 1);
            }
          }
        }
        return {
          ...item,
          make: false,
          score: 0,
          timestamp: new Date().toISOString(),
        };
      } else {
        return item;
      }
    });
    setNewTableData(newArray);
    // setTableData(newArray);
  };
  function millisToMinutesAndSeconds(millis) {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  }
  function convertToMinuteSecond(totalSeconds) {
    const minutes = Math.floor(totalSeconds / 60); // Get full minutes
    const seconds = totalSeconds % 60; // Get remaining seconds
    return { minutes: minutes, seconds: seconds };
  }
  const updateZone = (record, zone, index) => {
    if (record.id === "") {
      const updatedUsers = updatedArray.map((shot, i) =>
        i === index
          ? { ...shot, zone: zone, timestamp: new Date().toISOString() }
          : shot
      );
      setNewTableData(updatedUsers);
      // setTableData(updatedUsers);
    } else {
      const newArray = updatedArray.map((item, i) => {
        if (record.id === item.id) {
          setSelectedZone(zone[record.id]);
          return {
            ...item,
            zone: zone,
            timestamp: new Date().toISOString(),
          };
        } else {
          return item;
        }
      });
      // setTableData(newArray);
      setNewTableData(newArray);
    }
  };
  const remove = (item, id) => {
    if (activeTab === "teams") {
      if (item.make === true) {
        setAttempts(attempts - 1);
        setMake(make - 1);
      } else {
        setAttempts(attempts - 1);
        setMake(make - 0);
      }
    } else {
      let point = item.make === true ? 1 : 0;
      if (item.ptr === 2) {
        setPtr2Attempts(ptr2Attempts - 1);
        setPtr2Make(ptr2Make - point);
      } else {
        setPtr3Attempts(ptr3Attempts - 1);
        setPtr3Make(ptr3Make - point);
      }
    }
    setNewTableData((prevData) =>
      prevData.filter((row, index) => index !== id)
    );
    // setTableData((prevData) => prevData.filter((row, index) => index !== id));
  };
  const seekToTime = (timeString) => {
    let time = millisToMinutesAndSeconds(timeString.shot_start_time)
    const [minutes, seconds] = time.split(":").map(Number);
    const totalSeconds = minutes * 60 + seconds; // Convert minutes and seconds to total seconds
    if (playerRef.current) {
      playerRef.current.seekTo(totalSeconds, "seconds");
      setPlaying(true)
    }
  };
  const add = (item, index) => {
    if (activeTab === "teams") {
      if (item.make === true) {
        setAttempts(attempts + 1);
        // setMake(make + 1);
      } else {
        setAttempts(attempts + 1);
        // setMake(make + 0);
      }
    } else {
      let point = item.make === true ? 1 : 0;
      if (item.ptr === 2) {
        setPtr2Attempts(ptr2Attempts + 1);
        setPtr2Make(ptr2Make + point);
      } else {
        setPtr3Attempts(ptr3Attempts + 1);
        setPtr3Make(ptr3Make + point);
      }
    }
    const newObject = {
      ...item,
      id: `temp_id_${Math.random().toString(36).substr(2, 9)}`,
      make: null,
      // zone: item.zone === "" ? "" : "A",
      timestamp: new Date().toISOString(),
      shot_start_time: item.shot_end_time,
      shot_end_time:
        tableData?.length === 0
          ? null
          : index + 1 === newTableData?.length &&
            item?.shot_start_time < shootingDetail?.video_duration * 1000
          ? item.shot_end_time
          : index + 1 === newTableData?.length &&
            item.shot_end_time === shootingDetail?.video_duration * 1000
          ? shootingDetail?.video_duration * 1000
          : null,
    };
    // seekToTime(millisToMinutesAndSeconds(item.shot_end_time))
    const newData = [
      ...newTableData.slice(0, index + 1), // Elements before the index+1
      newObject, // New object to add
      ...newTableData.slice(index + 1), // Elements after the +1index
    ];
    setNewTableData(newData);
    // setTableData(newData);
  };
  const handleTimeChange = (value, field, rowIndex) => {
    const newData = [...newTableData];
    if(newTableData[rowIndex + 1] && rowIndex + 1 === newTableData?.length){
    newData[rowIndex + 1]["shot_end_time"] = value;
    }
    if(field === "shot_start_time" && (newTableData[rowIndex].shot_end_time % 60000) / 1000   < dayjs(value).$s ){
      newData[rowIndex]["shot_end_time"] = value;
    }
    newData[rowIndex][field] = value;
    // For second row onwards, set shot_start_time to the previous row's shot_end_time
    if (field === "shot_end_time") {
      const nextRow = newData[rowIndex + 1];
      if (nextRow) {
        nextRow.shot_start_time = value;
      }
    }
    setNewTableData(newData);
  };

  const shotStartDisableMinutesNew = (index) => {
    if (index === 0) return []; // No restriction for first row
    const prevRow = index > 0 ? newTableData[index - 1] : null;
    if (!prevRow || !prevRow.shot_end_time) return [];
    const prevEndMinutes = dayjs(prevRow.shot_end_time).$m;
    return Array.from({ length: 60 }, (_, minute) => minute).filter(
      (minute) => minute < prevEndMinutes || minute > 16
    );
  };
  const shotStartDisabledSecondsNew = (record, index) => {
    if (index === 0) return []; // No restriction for first row

    const prevRow = index > 0 ? newTableData[index - 1] : null;
    if (!prevRow || !prevRow.shot_end_time) return [];

    const prevEndMinutes = dayjs(prevRow.shot_end_time).$m;
    const prevEndSeconds = dayjs(prevRow.shot_end_time).$s;

    // Get the actual selected minute from shot_start_time
    const selectedMinute = record.shot_start_time
      ? dayjs(record.shot_start_time).$m
      : null;

    // If the selected minute is the same as the previous row's end minute, disable $sefore it
    if (selectedMinute !== null && selectedMinute === prevEndMinutes) {
      return Array.from({ length: 60 }, (_, sec) => sec).filter(
        (sec) => sec < prevEndSeconds + 1
      );
    }

    return [];
  };
  const shotStartDisableMinutes = (index) => {
    const allMinutes = Array.from({ length: 60 }, (_, minute) => minute); // Generate full minute range (0-59)
    const validMinutes = new Set(
      Array.from({ length: 17 }, (_, minute) => minute)
    ); // Only allow 0-16

    if (index === 0)
      return allMinutes.filter((minute) => !validMinutes.has(minute)); // Disable all above 16

    const prevRow = index > 0 ? newTableData[index - 1] : null;
    const nextRow =
      index < newTableData.length - 1 ? newTableData[index + 1] : null;

    if (!prevRow || !prevRow.shot_end_time) {
      return allMinutes.filter((minute) => !validMinutes.has(minute)); // Ensure only 0-16 are allowed
    }

    const prevEndMinutes = dayjs(prevRow.shot_end_time).minute();
    const nextStartMinutes = nextRow
      ? dayjs(nextRow.shot_start_time).minute()
      : null;
    if (
      prevEndMinutes ===
      convertToMinuteSecond(shootingDetail?.video_duration).minutes
    ) {
      return allMinutes.filter(
        (minute) =>
          !validMinutes.has(minute) || // Disable all minutes above 16
          minute < prevEndMinutes || // Disable minutes before prev end time
          (nextStartMinutes !== null && minute > nextStartMinutes) || // Disable minutes after next start time
          (prevEndMinutes ===
            convertToMinuteSecond(shootingDetail?.video_duration).minutes &&
            minute >
              convertToMinuteSecond(shootingDetail?.video_duration).minutes)
      );
    } else {
      return allMinutes.filter(
        (minute) =>
          !validMinutes.has(minute) || // Disable all minutes above 16
          minute < prevEndMinutes || // Disable minutes before prev end time
          (nextStartMinutes !== null && minute > nextStartMinutes) || // Disable minutes after next start time
          (prevEndMinutes !==
            convertToMinuteSecond(shootingDetail?.video_duration).minutes &&
            minute >
              convertToMinuteSecond(shootingDetail?.video_duration).minutes)
      );
    }
  };
  const shotStartDisabledSeconds = (record, index) => {
    if (index === 0) return []; // No restriction for first row

    const prevRow = index > 0 ? newTableData[index - 1] : null;
    const nextRow =
      index < newTableData.length - 1 ? newTableData[index + 1] : null;
    const selectedMinute = record.shot_start_time
      ? dayjs(record.shot_start_time).$m
      : null;
    if (
      !prevRow ||
      !prevRow.shot_end_time ||
      !record.shot_start_time ||
      !record.shot_end_time
    )
      return [];

    const prevEndTime = dayjs(prevRow.shot_end_time);
    const startTime = dayjs(record.shot_start_time);
    const endTime = dayjs(record.shot_end_time);

    const prevEndMinutes = prevEndTime.minute();
    const prevEndSeconds = prevEndTime.second();

    const startMinutes = startTime.minute();
    const startSeconds = startTime.second();

    const endMinutes = endTime.minute();
    const endSeconds = endTime.second();

    let disabledSecs = [];
    if (index + 1 === newTableData?.length) {
      if (
        prevEndMinutes <
          convertToMinuteSecond(shootingDetail?.video_duration).minutes &&
        selectedMinute <
          convertToMinuteSecond(shootingDetail?.video_duration).minutes
      ) {
        return Array.from({ length: 60 }, (_, sec) => sec).filter(
          (sec) => sec < prevEndSeconds
        );
      } else if (
        prevEndMinutes <
          convertToMinuteSecond(shootingDetail?.video_duration).minutes &&
        selectedMinute ===
          convertToMinuteSecond(shootingDetail?.video_duration).minutes
      ) {
        return Array.from({ length: 60 }, (_, sec) => sec).filter(
          (sec) =>
            sec > convertToMinuteSecond(shootingDetail?.video_duration).seconds
        );
      } else {
        return Array.from({ length: 60 }, (_, sec) => sec).filter(
          (sec) =>
            sec < prevEndSeconds ||
            sec > convertToMinuteSecond(shootingDetail?.video_duration).seconds
        );
      }
    } else {
      // If shot_start_time is in the same minute as prevRow's shot_end_time, disable seconds before prevEndSeconds
      if (startMinutes === prevEndMinutes) {
        disabledSecs = [
          ...disabledSecs,
          ...Array.from({ length: prevEndSeconds }, (_, sec) => sec),
        ];
      }

      // If shot_end_time is in the same minute as shot_start_time, disable seconds after endSeconds
      if (startMinutes === endMinutes) {
        disabledSecs = [
          ...disabledSecs,
          ...Array.from(
            { length: 60 - endSeconds - 1 },
            (_, sec) => sec + endSeconds + 1
          ),
        ];
      }

      // If shot_start_time and shot_end_time are in different minutes
      if (startMinutes !== endMinutes) {
        // In the shot_start_time minute, disable seconds before startSeconds
        if (selectedMinute === startMinutes) {
          disabledSecs = [
            ...disabledSecs,
            ...Array.from({ length: startSeconds }, (_, sec) => sec),
          ];
        }

        // In the shot_end_time minute, disable seconds after endSeconds
        if (selectedMinute === endMinutes) {
          disabledSecs = [
            ...disabledSecs,
            ...Array.from(
              { length: 60 - endSeconds - 1 },
              (_, sec) => sec + endSeconds + 1
            ),
          ];
        }
      }

      return disabledSecs;
    }
  };

  const shotEndDisabledMinutes = (record, index) => {
    const startTime = record.shot_start_time;
    if (!startTime)
      return Array.from({ length: 60 }, (_, minute) => minute).filter(
        (m) => m > 16
      );

    const startMinutes = dayjs(startTime).minute();
    // const currentMinute = dayjs(record.shot_end_time).minute();
    var currentMinute =
      record?.shot_end_time === null
        ? null
        : typeof record.shot_end_time === "number"
        ? Math.floor(record.shot_end_time / 60000)
        : dayjs(record.shot_end_time).minute();
    var currentSeconds = ((record.shot_end_time % 60000) / 1000).toFixed(0);

    // Get the next row's shot_end_time minute
    const nextRow = tableData[index + 1];
    let nextRowEndMinute = 16; // Default max (16) if next row doesn't exist

    if (nextRow && nextRow.shot_end_time) {
      nextRowEndMinute = Math.min(dayjs(nextRow.shot_end_time).minute(), 16); // Ensure it's within range
    }

    // Disable minutes greater than next row's shot_end_time minute or outside 0-16 range
    if (nextRow) {
      return Array.from({ length: 60 }, (_, minute) => minute).filter(
        (minute) =>
          minute < startMinutes || minute > nextRowEndMinute || minute > 16
      );
    } else {
      return Array.from({ length: 60 }, (_, minute) => minute).filter(
        (minute) =>
          minute < startMinutes ||
          (minute > currentMinute &&
            minute >
              convertToMinuteSecond(shootingDetail?.video_duration).minutes) ||
          minute > 16
      );
    }
  };
  const shotEndDisabledSeconds = (record, index) => {
    const startTime = record.shot_start_time;
    if (!startTime) return [];

    const startMinutes = dayjs(startTime).minute();
    const startSeconds = dayjs(startTime).second();
    const selectedTime = record.shot_end_time;
    const selectedMinutes = dayjs(selectedTime).minute();

    // **Get the next row's shot_end_time**
    const nextRow = tableData[index];
    let nextRowEndMinute = null;
    let nextRowEndSeconds = null;

    if (nextRow && nextRow.shot_end_time) {
      const nextEndTime = dayjs(nextRow.shot_end_time);
      nextRowEndMinute = nextEndTime.minute();
      nextRowEndSeconds = nextEndTime.second();
    }

    enableStart = startSeconds;
    enableEnd = 0;

    // If the selected minute is the start minute, restrict seconds
    if (selectedMinutes === startMinutes) {
      enableEnd = 59;
    }
    if (typeof record.id !== "string") {
      enableStart = parseInt((record.shot_start_time % 60000) / 1000);
      enableEnd = parseInt((record.shot_end_time % 60000) / 1000);
    }

    // If the selected minute matches the next row’s `shot_end_time` minute, disable all seconds before `nextRowEndSeconds`
    if (selectedMinutes === nextRowEndMinute) {
      return Array.from({ length: 60 }, (_, sec) => sec).filter(
        (sec) =>
          sec >= nextRowEndSeconds + 1 ||
          sec < enableStart ||
          sec > enableEnd + 1
      );
    }
    if (index + 1 === newTableData?.length) {
      if (selectedMinutes > startMinutes) {
        return Array.from({ length: 60 }, (_, sec) => sec).filter(
          (sec) =>
            sec > convertToMinuteSecond(shootingDetail?.video_duration).seconds
        );
      } else if (
        selectedMinutes === startMinutes &&
        startMinutes ===
          convertToMinuteSecond(shootingDetail?.video_duration).minutes
      ) {
        return Array.from({ length: 60 }, (_, sec) => sec).filter(
          (sec) =>
            sec < enableStart ||
            sec > convertToMinuteSecond(shootingDetail?.video_duration).seconds
        );
      } else {
        return Array.from({ length: 60 }, (_, sec) => sec).filter(
          (sec) => sec < enableStart || sec > enableEnd
        );
      }
    } else {
      return Array.from({ length: 60 }, (_, sec) => sec).filter(
        (sec) => sec < enableStart || sec > enableEnd  || sec > convertToMinuteSecond(shootingDetail?.video_duration).seconds
      );
    }
  };

  const shotEndDisabledMinutesNew = (record) => {
    const startTime = record.shot_start_time;
    // if (!startTime) return [];

    const startMinutes = dayjs(startTime).minute();

    // Create an array of all 60 minutes and filter out those we want to disable
    return Array.from({ length: 60 }, (_, minute) => minute).filter(
      (minute) => minute < startMinutes || minute > 16 // Ensure minutes are between 0 and 16
    );
  };
  const shotEndDisabledSecondsNew = (record) => {
    const startTime = record.shot_start_time;
    if (!startTime) return [];
    const startMinutes = dayjs(startTime).$m;
    const startSeconds = dayjs(startTime).$s;
    const selectedMinute = record.shot_end_time
      ? dayjs(record.shot_end_time).$m
      : null;
    if (selectedMinute !== null && selectedMinute === startMinutes) {
      return Array.from({ length: 60 }, (_, sec) => sec).filter(
        (sec) => sec < startSeconds + 1
      );
    }
    return [];
  };
  const columns = [
    {
      title: "Shot count",
      dataIndex: "count",
      key: "count",
      render: (text, record, index) => {
        isEditable =
          index > 0 &&
          typeof newTableData[index - 1]?.id === "string" &&
          newTableData[index - 1]?.id.startsWith("temp_id");
        const nextShot =
          index > 0 &&
          newTableData[index - 1].shot_end_time === null &&
          newTableData[index];
        return (
          <div key={index}>
            <span className="span-1" onClick = {() =>  editingKey === true ?  seekToTime(record) : false}>{index + 1}</span>
            {(typeof record.id === "string" &&
              record?.id.startsWith("temp_id")) ||
            isEditable ? (
              <div
                style={{
                  fontSize: 14,
                  color: "A1A5B7",
                  fontWeight: 600,
                  marginTop: 5,
                }}
              >
                <div style={{ display: "flex", marginBottom: 5 }}>
                  <span style={{ padding: "8px 10px 8px 0px", width: 38 }}>
                    Start:
                  </span>
                  <TimePicker
                    value={
                      record?.shot_start_time !== null &&
                      dayjs(record.shot_start_time)
                    }
                    hideDisabledOptions
                    defaultValue={dayjs("00:00:00", "mm:ss")}
                    allowClear={false}
                    onCalendarChange={(value) => {
                      handleTimeChange(value, "shot_start_time", index);
                      if (
                        shotStartDisabledSeconds(record, index).includes(
                          value?.$s
                        ) &&
                        tableData?.length !== 0
                      ) {
                        handleTimeChange(
                          value.second(0),
                          "shot_start_time",
                          index
                        );
                      }
                    }}
                    onChange={(value) => {
                      if (value) {
                        const updatedValue = value.second(0); // Reset the seconds to 0
                        handleTimeChange(
                          updatedValue,
                          "shot_start_time",
                          index
                        );
                      }
                    }}
                    style={{ width: 100 }}
                    format="mm:ss"
                    minuteStep={1}
                    secondStep={1}
                    showNow={false}
                    needConfirm={false}
                    suffixIcon={false}
                    disabled={
                      index + 1 !== newTableData?.length &&
                      tableData?.length !== 0
                        ? (typeof record.id === "string" &&
                            record?.id.startsWith("temp_id")) ||
                          nextShot !== false
                        : // ? record.id === "" || nextShot !== false
                          record?.shot_start_time === 0
                    }
                    disabledTime={() => ({
                      disabledMinutes:
                        // () =>
                        tableData?.length === 0
                          ? () => shotStartDisableMinutesNew(index)
                          : () => shotStartDisableMinutes(index),
                      disabledSeconds:
                        tableData?.length === 0
                          ? () => shotStartDisabledSecondsNew(record, index)
                          : () => shotStartDisabledSeconds(record, index),
                    })}
                  />
                </div>
                <div style={{ display: "flex" }}>
                  <span style={{ padding: "8px 10px 8px 0px", width: 38 }}>
                    End:
                  </span>

                  <TimePicker
                    value={
                      record?.shot_end_time !== null
                        ? dayjs(record.shot_end_time)
                        : null
                    }
                    hideDisabledOptions
                    allowClear={false}
                    onCalendarChange={(value) => {
                      handleTimeChange(value, "shot_end_time", index);
                      if (
                        shotEndDisabledSeconds(record, index).includes(
                          value?.$s
                        ) &&
                        tableData?.length !== 0
                      ) {
                        handleTimeChange(
                          value.second(0),
                          "shot_end_time",
                          index
                        );
                      }
                    }}
                    disabled={
                      // index + 1 === newTableData?.length ||
                      record?.shot_end_time ===
                        shootingDetail?.video_duration * 1000 ||
                      (tableData?.length !== 0 && isEditable && newTableData?.length !== index + 1)
                    }
                    style={{ width: 100 }}
                    showNow={false}
                    needConfirm={false}
                    suffixIcon={false}
                    onChange={(value) => {
                      if (value) {
                        const updatedValue = value.second(0); // Reset the seconds to 0
                        handleTimeChange(updatedValue, "shot_end_time", index);
                      }
                    }}
                    format="mm:ss"
                    minuteStep={1}
                    secondStep={1}
                    disabledTime={() => ({
                      disabledMinutes:
                        tableData?.length === 0
                          ? () => shotEndDisabledMinutesNew(record)
                          : () => shotEndDisabledMinutes(record, index),
                      disabledSeconds:
                        tableData?.length === 0
                          ? () => shotEndDisabledSecondsNew(record)
                          : () => shotEndDisabledSeconds(record, index),
                    })}
                  />
                </div>
              </div>
            ) : (
              <div
                style={{
                  fontSize: 14,
                  color: "A1A5B7",
                  fontWeight: 600,
                  marginTop: 5,
                }}
              >
                Start:
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  {record?.shot_start_time?.$m || record?.shot_start_time?.$s
                    ? dayjs(record.shot_start_time).$m +
                      ":" +
                      dayjs(record.shot_start_time).$s
                    : millisToMinutesAndSeconds(record.shot_start_time)}
                </span>
                , End:
                <span style={{ fontWeight: 400 }}>
                  {" "}
                  {record?.shot_end_time?.$m || record?.shot_end_time?.$s
                    ? dayjs(record.shot_end_time).$m +
                      ":" +
                      dayjs(record.shot_end_time).$s
                    : millisToMinutesAndSeconds(record.shot_end_time)}
                  {/* {millisToMinutesAndSeconds(record.shot_end_time)} */}
                </span>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "zone",
      dataIndex: "zone",
      key: "zone",
      render: (text, record, index) => {
        return editingKey === false ? (
          <div key={index} style={{ textAlign: "center" }}>
            <span className="span-1">{record.zone}</span>
          </div>
        ) : (
          <Select
            placeholder="Newly Added"
            optionFilterProp="children"
            onChange={(e) => updateZone(record, e, index)}
            suffixIcon={<img src={DownArrow} />}
            className="select-input"
            defaultValue={record.zone}
            value={selectedZone}
            popupClassName={
              "custom-action-select-dropdown " +
              (selectedSport === 1
                ? "orange-theme-select"
                : "blue-theme-select")
            }
            disabled={record.make === null}
            options={zoneOptions}
          />
        );
      },
    },
    {
      title: "recorded result",
      dataIndex: "actions",
      key: "actions",
      render: (_, record, index) => {
        return editingKey ? (
          <div
            style={{ display: "flex", gap: "10px", justifyContent: "center" }}
          >
            <Button
              className={
                record.make === false || record.make === null
                  ? "grey-btn-disable"
                  : "green-btn"
              }
              onClick={() => {
                updateMake(index);
              }}
            >
              Make
            </Button>
            <Button
              className={
                record.make === true || record.make === null
                  ? "grey-btn-disable"
                  : "red-btn"
              }
              onClick={() => updateMiss(index)}
            >
              Miss
            </Button>
          </div>
        ) : (
          <span
            style={{ display: "inline", justifyContent: "left" }}
            className={record.make === true ? "green-tag" : "red-tag"}
          >
            {record.make === true ? "Make" : "Miss"}
          </span>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record, index) => {
        return (
          <div style={{ display: "flex", gap: 10, justifyContent: "center" }}>
            <img src={Delete} onClick={() => remove(record, index)} />
            <PlusOutlined
              // disabled={ newTableData.some((item) => item.shot_start_time === null||item.shot_end_time === null)}
              disabled={
                record?.shot_start_time === null ||
                record?.shot_end_time === null ||
                (tableData?.length !== 0 &&
                  index > 0 &&
                  index + 1 !== newTableData?.length &&
                  typeof newTableData[index]?.id === "string" &&
                  newTableData[index]?.id.startsWith("temp_id"))
                //  || index > 0 && newTableData[index].id === ""
              }
              style={{
                strokeWidth: 30,
                stroke: "#7e8299",
                fontSize: "large",
                cursor:
                  record?.shot_start_time === null ||
                  record?.shot_end_time === null ||
                  (tableData?.length !== 0 &&
                    index > 0 &&
                    index + 1 !== newTableData?.length &&
                    typeof newTableData[index]?.id === "string" &&
                    newTableData[index]?.id.startsWith("temp_id"))
                    ? //  || index > 0 && newTableData[index].id === ""
                      "not-allowed"
                    : "pointer",
              }}
              onClick={() =>
                record?.shot_start_time === null ||
                record?.shot_end_time === null ||
                (tableData?.length !== 0 &&
                  index > 0 &&
                  index + 1 !== newTableData?.length &&
                  typeof newTableData[index]?.id === "string" &&
                  newTableData[index]?.id.startsWith("temp_id"))
                  ? //  || index > 0 && newTableData[index].id === ""
                    false
                  : add(record, index)
              }
            />
          </div>
        );
      },
    },
  ];
  console.log(updatedArray, newTableData);

  return (
    <Modal
      className="shooting-detail-modal user-shooting-modal"
      rootClassName={
        "shooting-detail-modal " +
        (selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal")
      }
      open={shootingDetailModal}
      // onOk={handleOk}
      onCancel={() => cancelModal()}
      okText={"Add & Next"}
      cancelText={"Cancel"}
      width={"1000px"}
      footer={false}
      destroyOnClose={true}
    >
      {Object.keys(shootingDetail).length > 0 ? (
        <div>
          {activeTab === "teams" ? (
            <>
              <div style={{ display: "flex" }}>
                <h1>Vision Team Shooting Results</h1>
                <div style={{ display: "flex", marginLeft: "auto" }}>
                  <div className="submitted-date">
                    <h3 className="user-name">Submitted</h3>
                    <h4 className="user-handle">
                      {getDateWithTimeWithoutDay(shootingDetail?.session_time)}
                    </h4>
                  </div>
                  {editingKey === false && activeTab === "teams" && (
                    <Button
                      className="grey-btn"
                      onClick={() => {
                        edit();
                      }}
                    >
                      Edit
                    </Button>
                  )}
                </div>
              </div>
              <div
                style={{ display: "flex", marginBottom: 20 }}
                className="title-div"
              >
                <div className="title">Session Title</div>
                {/* <Select
                  showSearch
                  style={{
                    width: 260,
                    height: 48,
                  }}
                  placeholder="Search to Select"
                  optionFilterProp="label"
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase())
                  }
                  defaultValue={sessionTitle}
                  value={sessionTitle}
                  onInputKeyDown={(e) => {
                    setSessionTitle(e.target.value);
                    setTeamOffset(0);
                    setTitleUpdate(true);
                  }}
                  onSearch={(e)=>{setSessionTitle(e);setTeamOffset(0);setTitleUpdate(true)}}
                  onChange={(e)=>{setSessionTitle(e);setTeamOffset(0);setTitleUpdate(true)}}
                  options={sessionOptions}
                /> */}
                <div className="title-input" ref={wrapperRef}>
                  <Input
                    defaultValue={sessionTitle}
                    value={sessionTitle}
                    onChange={(e) => {
                      setSessionTitle(e.target.value);
                      setTitleError(false);
                      setTeamOffset(0);
                      setTitleUpdate(true);
                      changeTitleInput(e);
                    }}
                  />
                  {titleError === true && (
                    <span className="error-message" style={{ marginTop: 0 }}>
                      Please enter session title
                    </span>
                  )}
                  {showDropdown && sessionOptions?.length > 0 && (
                    <div className="suggestions-dropdown">
                      {sessionOptions && sessionOptions.length > 0 && (
                        <Fragment>
                          {sessionOptions.map((suggestion, index) => (
                            <div
                              key={"suggestion_" + index}
                              className="suggestion-option"
                              style={{ display: "flex" }}
                            >
                              <div
                                onClick={() => {
                                  setTitleError(false);
                                  setSessionTitle(suggestion.label);
                                  setShowDropdown(false);
                                }}
                                style={{ width: "90%" }}
                              >
                                {" "}
                                {suggestion.label}
                              </div>
                              <span
                                style={{ textAlign: "end", width: "10%" }}
                                onClick={() =>
                                  removeSuggestion(suggestion.value)
                                }
                              >
                                <CloseOutlined
                                  onClick={() =>
                                    removeSuggestion(suggestion.value)
                                  }
                                />
                              </span>
                            </div>
                          ))}
                        </Fragment>
                      )}
                    </div>
                  )}
                </div>
                {titleUpdate === true && (
                  <>
                    <Button
                      className="dark-base-btn"
                      style={{ background: darkButtonBackground }}
                      onClick={() => updateSession(true)}
                    >
                      Keep
                    </Button>
                    <Button
                      className="gray-base-btn"
                      onClick={() => {
                        setTitleUpdate(false);
                        setSessionTitle(shootingDetail?.title);
                      }}
                    >
                      Revert
                    </Button>
                  </>
                )}
              </div>
            </>
          ) : (
            <div style={{ display: "flex" }}>
              {shootingDetail?.profile?.avatar === null ? (
                <Avatar
                  name={shootingDetail?.profile?.name}
                  color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
                  maxInitials={2}
                  className="profile-avatar"
                />
              ) : (
                <img
                  src={shootingDetail?.profile?.avatar}
                  className="profile-logo"
                  style={
                    imageLoaded === ""
                      ? { display: "none" }
                      : { display: "block" }
                  }
                  onLoad={() => setImageLoaded("loaded")}
                />
              )}
              <div className="name-div">
                <h3 className="user-name">{shootingDetail?.profile?.name}</h3>
                <h4 className="user-handle">
                  @{shootingDetail?.profile?.username}
                </h4>
              </div>
              <div style={{ marginLeft: "auto" }} className="submitted-date">
                <h3 className="user-name">Submitted</h3>
                <h4 className="user-handle">
                  {getDateWithTimeWithoutDay(shootingDetail?.session_time)}
                </h4>
              </div>
            </div>
          )}
          {shootingDetail?.media_url !== null && (
            <ReactPlayer
              url={shootingDetail?.media_url}
              controls
              width={"100%"}
              height={"100%"}
              style={{ objectFit: "cover", textAlign: "center" }}
              className="submission-video"
              ref={playerRef}
              playing={playing}
            />
          )}
          <h2>Your Shots</h2>
          <Row gutter={16} className="score-row">
            {/* {editingKey === true ? (
              <Col className="gutter-row" span={8}>
                <div style={style} className="dotted-box">
                  {" "}
                  <p>
                    <Input
                      value={make}
                      onChange={(e) => setMake(e.target.value)}
                      style={{ width: 50, height: 32 }}
                    />
                    /
                    <Input
                      value={attempts}
                      onChange={(e) => setAttempts(e.target.value)}
                      style={{ width: 50, height: 32 }}
                    />
                    (
                    {isNaN(Math.round(make * 100) / attempts) ||
                    attempts === 0 ||
                    attempts?.length === 0 ||
                    attempts === "0"
                      ? 0
                      : (Math.round(make * 100) / attempts).toFixed(1)}
                    %)
                  </p>
                  <span>Overall</span>
                </div>
              </Col>
            ) : ( */}
            {activeTab !== "teams" ? (
              <Col className="gutter-row" span={8}>
                <div style={style} className="dotted-box">
                  {" "}
                  <p>
                    {ptr2Make + ptr3Make} / {ptr2Attempts + ptr3Attempts}(
                    {isNaN(
                      Math.round((ptr2Make + ptr3Make) * 100) /
                        (ptr2Attempts + ptr3Attempts)
                    )
                      ? 0
                      : (
                          Math.round((ptr2Make + ptr3Make) * 100) /
                          (ptr2Attempts + ptr3Attempts)
                        ).toFixed(1)}
                    %)
                  </p>
                  <span>Overall</span>
                </div>
              </Col>
            ) : (
              <Col className="gutter-row" span={8}>
                <div style={style} className="dotted-box">
                  {" "}
                  <p>
                    {make} / {attempts}(
                    {isNaN(Math.round(make * 100) / attempts)
                      ? 0
                      : (Math.round(make * 100) / attempts).toFixed(1)}
                    %)
                  </p>
                  <span>Overall</span>
                </div>
              </Col>
            )}
            {/* )} */}
            {activeTab !== "teams" && (
              <>
                <Col className="gutter-row" span={8}>
                  <div style={style} className="dotted-box">
                    {" "}
                    <p>
                      {ptr2Make}/{ptr2Attempts}(
                      {isNaN(Math.round(ptr2Make * 100) / ptr2Attempts)
                        ? 0
                        : (Math.round(ptr2Make * 100) / ptr2Attempts).toFixed(
                            1
                          )}
                      %)
                    </p>
                    <span>2PT FG%</span>
                  </div>
                </Col>
                {shootingDetail.court !== "NO_THREE_POINT_LINE" && (
                  <Col className="gutter-row" span={8}>
                    <div style={style} className="dotted-box">
                      {" "}
                      <p>
                        {ptr3Make}/{ptr3Attempts}(
                        {isNaN(Math.round(ptr3Make * 100) / ptr3Attempts)
                          ? 0
                          : (Math.round(ptr3Make * 100) / ptr3Attempts).toFixed(
                              1
                            )}
                        %)
                      </p>
                      <span>3PT FG%</span>
                    </div>
                  </Col>
                )}
              </>
            )}
          </Row>
          <Table
            rowKey="id"
            // rowKey={() => Math.random().toString(36).substr(2, 9)} // Generate a random key
            onRow={(record, rowIndex) => {
              return {
                onClick:() =>  editingKey === false &&  seekToTime(record),
              };
            }}
            pagination={false}
            columns={
              editingKey && shootingDetail?.court === "NO_THREE_POINT_LINE"
                ? columns.filter((column) => column.key !== "zone")
                : !editingKey && shootingDetail?.court === "NO_THREE_POINT_LINE"
                ? columns.filter(
                    (column) => column.key !== "zone" && column.key !== "action"
                  )
                : columns.filter((column) => column.key !== "action")
            }
            dataSource={updatedArray}
            className={
              "table-container " +
              (selectedSport === 1 ? "orange-theme" : "blue-theme")
            }
            scroll={{
              y: 55 * 5,
            }}
          />
          {editingKey === true && (
            <div style={{ float: "right" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  margin: "10px 8px",
                  background: darkButtonBackground,
                  height: 40,
                  border: "none",
                  boxShadow: "none",
                  color: "#fff",
                }}
                loading={loading}
                disabled={
                  loading ||
                  newTableData.some(
                    (item) =>
                      item.make === null ||
                      item.shot_start_time === null ||
                      item.shot_end_time === null
                  )
                }
                onClick={updataSession}
                className="dark-base-btn"
              >
                Save
              </Button>
              <Button
                className="grey-btn"
                onClick={() => {
                  isEditable = undefined;
                  setNewTableData(tableData);
                  setEditingKey(false);
                  setPtr2Make(shootingDetail?.total_2ptr_make);
                  setPtr2Attempts(shootingDetail?.total_2ptr_attempts);
                  setPtr3Attempts(shootingDetail?.total_3ptr_attempts);
                  setPtr3Make(shootingDetail?.total_3ptr_make);
                  setMake(shootingDetail?.total_make);
                  setAttempts(shootingDetail?.total_attempts);
                }}
                style={{
                  margin: "10px 8px",
                  height: 40,
                  width: 85,
                  border: "none",
                  boxShadow: "none",
                  float: "left",
                }}
              >
                Cancel
              </Button>
            </div>
          )}
          {activeTab === "individual" &&
            shootingDetail.court !== "NO_THREE_POINT_LINE" && (
              <Chart
                setLoading={setLoading}
                shotChart={shootingDetail}
                edit={false}
              />
            )}
        </div>
      ) : (
        <Spin
          className={
            "loader " +
            (selectedSport === 1 ? "orange-theme-loader" : "blue-theme-loader")
          }
          size="large"
          style={{
            position: "absolute",
            left: "49%",
          }}
        />
      )}
      {activeTab === "teams" && editingKey === false && (
        // shootingDetail.court !== "NO_THREE_POINT_LINE" &&
        <Button
          className="grey-btn"
          style={{ marginTop: 20 }}
          onClick={() => deleteTeamSession(shootingDetail?.id)}
        >
          Delete session
        </Button>
      )}
    </Modal>
  );
};
export default ShootingDetailModal;
