import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { UserContext } from "../../../context/user-context";
import {
  Button,
  Checkbox,
  Dropdown,
  Space,
  Spin,
  Row,
  Col,
  Skeleton,
  Radio,
} from "antd";
import Plus from "../../../assets/images/plus-red.svg";
import Avatar from "react-avatar";
import DownArrow from "../../../assets/images/down-arrow.svg";
import User from "../../../assets/images/dual-user.svg";
import { MessagesContext } from "../../../coach-context/group-context/messages-context";
import InfiniteScroll from "react-infinite-scroll-component";
import SubgroupModal from "./subgroup-modal";
import EditSubgroupModal from "./edit-subgroup-modal";
import ConversationBox from "./conversation-box";
import { MessagingServiceContext } from "../../../coach-context/messaging-service-context";
const Recepients = () => {
  const navigate = useNavigate();
  const { color, selectedSport, lightButtonBackground, darkButtonBackground } =
    useContext(UserContext);
  const {
    messages,
    selectedMessage,
    setSelectedMessage,
    setMessages,
    setSelectedConversationSid,
    setToken,
    setChat,
    conversation,
    setIsLoading,
    setActiveConversation,
    setLastMessage
  } = useContext(MessagingServiceContext);
  const {
    loading,
    checkedGroups,
    setIsCheckMembers,
    isCheckMembers,
    isCheckOwners,
    setIsCheckOwners,
    setCheckedGroups,
    allProfiles,
    setImageLoaded,
    imageLoaded,
    setEditSubGroup,
    getGroupMembers,
    setCheckedItems,
    setClickedSubGroup,
    setSubGroupMembers,
    setSubGroupOwners,
    params,
    senderView,
    setSenderView,
    listingMembers,
    subGroups,
    ownerSubGroups,
    members,
    setSubgroupModal,
    clickedSubgroup,
    continueDisabled,
    setContinueDisabled,
    allIndiviually,
    setAllIndiviually,
    postSingleMemberChat,
    postSubgroupChat,
    postWholeGroupChat,
    setCheckAllMembers,
    checkAllMembers,
    setNewMessage,
    newMessage,
    setChatRoomDetail,
    setSelectedUserProfileData,
    getMessageListing,
    setImageUrl,
    setImgSrc,
    setMessageText,
    setPageNumber,
    setMessageListing,
    owners,
    profileType,
    setProfileType,
    selectedProfile,
    setSelectedProfile,
    selectedSubgroup,
    setSelectedSubgroup
  } = useContext(MessagesContext);

  useEffect(() => {
    if (Object.keys(selectedMessage).length === 0) {
      listingMembers();
    }
  }, [selectedMessage]);
  const items = [
    {
      key: "1",
      label: "Last Name A-Z",
    },
    {
      key: "2",
      label: "Last Name Z-A",
    },
  ];
  const handleGroup = (e, item) => {
    const { id, checked } = e.target;
    setCheckedGroups([...checkedGroups, item]);
    if (!checked) {
      setCheckedGroups(checkedGroups.filter((prev) => prev !== item));
      setIsCheckMembers(
        isCheckMembers.filter((el) => !item.members.includes(el))
      );
      setIsCheckOwners(
        isCheckOwners.filter((el) => !item.members.includes(el))
      );
    }
  };
  const handleClick = (e, item) => {
    setSelectedUserProfileData(item);
    const { id, checked } = e.target;
    setCheckedItems(true);
    if (profileType === "members") {
      setIsCheckMembers([...isCheckMembers, item]);
    } else {
      setIsCheckOwners([...isCheckOwners, item]);
    }
    setSelectedUserProfileData(item);
    if (!checked) {
      setSelectedUserProfileData();
      if (profileType === "members") {
        setIsCheckMembers(isCheckMembers.filter((prev) => prev !== item));
      } else {
        setIsCheckOwners(isCheckOwners.filter((prev) => prev !== item));
      }
    }
    if (isCheckMembers.length === 0 || isCheckOwners.length === 0) {
      setCheckedItems(false);
    }
  };
  const showSubGroupModal = () => {
    setSubgroupModal(true);
  };
  useEffect(() => {
    if (allIndiviually === true || checkAllMembers) {
      setContinueDisabled(false);
    } else if (
      checkedGroups.length === 0 &&
      (isCheckMembers.length !== 0 || isCheckOwners.length !== 0)
    ) {
      setContinueDisabled(false);
    } else if (
      checkedGroups.length === 1 &&
      (isCheckMembers.length === 0 || isCheckOwners.length === 0)
    ) {
      setContinueDisabled(false);
    } else {
      setContinueDisabled(true);
    }
  }, [
    checkedGroups,
    isCheckMembers,
    allIndiviually,
    checkAllMembers,
    isCheckOwners,
  ]);

  const sendIndividually = () => {
    setIsCheckOwners([]);
    setIsCheckMembers([]);
    setCheckedGroups([]);
    if (allIndiviually === false) {
      setAllIndiviually(true);
    } else {
      setAllIndiviually(false);
    }
  };
  const clickContinue = () => {
    setSenderView(false);
    if (
      (allIndiviually === false &&
        isCheckMembers?.length === 1 &&
        isCheckOwners?.length === 0) ||
      (isCheckMembers?.length === 0 && isCheckOwners?.length === 1) ||
      (isCheckMembers?.length === 1 && isCheckOwners?.length !== 1) ||
      (isCheckMembers?.length !== 1 && isCheckOwners?.length === 1) ||
      checkedGroups?.length > 0 ||
      checkAllMembers
    ) {
      // if (
      //   allIndiviually === false ||
      //   (isCheckMembers?.length === 1 && isCheckOwners?.length === ) ||
      //   (isCheckMembers?.length === 0 && isCheckOwners?.length > 1) ||
      // )
      if (checkedGroups.length !== 0) {
        postSubgroupChat();
      } else if (isCheckMembers?.length === 1 && isCheckOwners?.length === 0) {
        postSingleMemberChat();
      } else if (isCheckMembers?.length === 0 && isCheckOwners?.length === 1) {
        postSingleMemberChat();
      } else if (checkAllMembers === true) {
        postWholeGroupChat();
      }
      setIsCheckOwners([]);
      setIsCheckMembers([]);
      setCheckedGroups([]);
      setCheckAllMembers(false);
    } else {
      setIsLoading(false);
    }
  };
  const checkMembers = () => {
    setIsCheckOwners([]);
    setIsCheckMembers([]);
    setCheckedGroups([]);
    if (checkAllMembers === true) {
      setCheckAllMembers(false);
    } else {
      setCheckAllMembers(true);
    }
  };
  const goBack = () => {
    if (conversation) {
      conversation.removeAllListeners("messageAdded");
      conversation.removeAllListeners("participantUpdated");
    }
    setChat([]);
    setLastMessage()
    setActiveConversation(null)
    setSelectedSubgroup({})
    setSelectedProfile({})
    setMessageListing([]);
    setPageNumber(1);
    setMessageText("");
    setImageUrl();
    setImgSrc();
    setAllIndiviually(false);
    setChatRoomDetail();
    setMessages([]);
    // setToken(null);
    setCheckAllMembers(false);
    setSelectedConversationSid(null);
    setIsCheckMembers([]);
    setIsCheckOwners([]);
    setCheckedGroups([]);
    if (Object.keys(selectedMessage).length === 0) {
      if (senderView && newMessage) {
        getMessageListing();
        setNewMessage(false);
      } else {
        setSenderView(true);
      }
    } else {
      setSenderView(true);
      setNewMessage(false);
      setSelectedMessage({});
      getMessageListing();
    }
  };
  const handleModeChange = (e) => {
    setProfileType(e.target.value);
  };
  return (
    <div className="recepients-main">
      <div className="heading-row">
        <FaArrowLeftLong
          className="left-arrow"
          style={{ margin: 0, color: color, stroke: color }}
          onClick={goBack}
        />
        <h1>New Message</h1>
      </div>
      <div className="breadcrumbs">
        <div style={{ display: "flex" }}>
          <div className="crumb">
            <Link
              onClick={() => setSenderView(false)}
              style={{ cursor: "text" }}
            >
              Messages
            </Link>
          </div>
          <div className="crumb" style={{ cursor: "text" }}>
            <a
              style={{ cursor: "text" }}
              className="name"
              title={
                Object.keys(selectedMessage).length > 0
                  ? selectedMessage.username
                  : Object.keys(selectedProfile)?.length !== 0 && isCheckOwners?.length === 0
                  // : isCheckMembers?.length === 1 && isCheckOwners?.length === 0
                  ? selectedProfile?.username
                  // ? isCheckMembers[0].username
                  : Object.keys(selectedSubgroup)?.length !== 0
                  // : checkedGroups?.length === 1
                  ? selectedSubgroup?.name
                  // ? checkedGroups[0].name
                  : Object.keys(selectedProfile)?.length !== 0 && isCheckOwners?.length === 0
                  // : isCheckOwners?.length === 1 && isCheckMembers?.length === 0
                  ? selectedProfile?.username
                  // ? isCheckOwners[0].username
                  : "New Message"
              }
            >
                {
                   Object.keys(selectedMessage).length > 0
                   ? selectedMessage.username
                   : Object.keys(selectedProfile)?.length !== 0 && isCheckOwners?.length === 0
                   // : isCheckMembers?.length === 1 && isCheckOwners?.length === 0
                   ? selectedProfile?.username
                   // ? isCheckMembers[0].username
                   : Object.keys(selectedSubgroup)?.length !== 0
                  //  : checkedGroups?.length === 1
                  //  ? checkedGroups[0].name
                  ? selectedSubgroup.name
                   : Object.keys(selectedProfile)?.length !== 0 && isCheckOwners?.length === 0
                   // : isCheckOwners?.length === 1 && isCheckMembers?.length === 0
                   ? selectedProfile?.username
                   // ? isCheckOwners[0].username
                   : "New Message"
                }
            </a>
          </div>
        </div>
      </div>
      {senderView && messages.length === 0 ? (
        <>
          <h2>Send to</h2>
          <Checkbox
            className="custom-checkbox"
            onChange={sendIndividually}
            // checked={allMembers}
            disabled={checkAllMembers}
          >
            <p
              style={{
                margin: 0,
                fontSize: 18,
                color: "#3f4254",
                fontWeight: 600,
              }}
            >
              Send Individually to all Group members{" "}
            </p>
          </Checkbox>
          <div className="assign-to">
            <p>
              Select the group members you want as your message’s recipients.
            </p>
            <p>
              To create a subgroup using the selected names, press the{" "}
              <span style={{ color: "#3f4254", fontWeight: 700 }}>
                {" "}
                + Create Subgroup{" "}
              </span>{" "}
              Button
            </p>
            <Button
              className="red-base-btn"
              disabled={
                isCheckMembers?.length === 1 && isCheckOwners?.length === 1
                  ? false
                  : isCheckMembers &&
                    isCheckMembers.length < 2 &&
                    isCheckOwners &&
                    isCheckOwners.length < 2
              }
              onClick={showSubGroupModal}
              style={{
                color: color,
                background: lightButtonBackground,
                marginLeft: 0,
                height: 48,
                cursor:allIndiviually ? "not-allowed" : "pointer"              
              }}
            >
              <img
                src={Plus}
                className={
                  selectedSport === 1 ? "orange-theme-svg" : "blue-theme-svg"
                }
              />
              Create Subgroup
            </Button>
            <div className="members-div" style={{opacity:allIndiviually ? 0.5 : "unset", cursor:allIndiviually ? "not-allowed" : "default"}}>
              <Radio.Group
                onChange={handleModeChange}
                disabled={allIndiviually}
                value={profileType}
                style={{
                  marginBottom: 30,
                  justifyContent: "center",
                  display: "flex",
                }}
                className={
                  selectedSport === 1
                    ? "category-radio-tabs-orange"
                    : "category-radio-tabs-blue"
                }
              >
                <Radio.Button value="members">Members</Radio.Button>
                <Radio.Button value="owners">Owners</Radio.Button>
              </Radio.Group>
              <div
                style={{ display: "flex", width: "100%", marginBottom: "25px" }}
              >
                <Checkbox
                  className="custom-checkbox"
                  disabled={allIndiviually}
                  onChange={checkMembers}
                />

                <h2 style={{ marginLeft: 20 }}>
                  Members (
                  {checkAllMembers === true
                    ? owners.length + members?.results?.length
                    : isCheckMembers?.length +
                      checkedGroups?.length +
                      isCheckOwners?.length}
                  {/* {subGroups &&
                    Object.keys(members).length > 0 &&
                    subGroups.length + members.results.length}
                    */}
                  ){" "}
                </h2>
                {/* <Dropdown
                  className="sort-dropdown"
                  menu={{ items }}
                  overlayClassName="sort-dropdown-menu"
                >
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      Last Name A-Z
                      <img src={DownArrow} />
                    </Space>
                  </a>
                </Dropdown> */}
              </div>
              {loading && loading == true ? (
                <Spin
                  className={
                    "loader " +
                    (selectedSport === 1
                      ? "orange-theme-loader"
                      : "blue-theme-loader")
                  }
                  size="large"
                  style={{
                    position: "absolute",
                    left: "32%",
                    marginTop: -16,
                  }}
                />
              ) : (
                <Row className="member-row">
                  {profileType === "members" ? (
                    <>
                      {subGroups &&
                        subGroups.map(function (item, index) {
                          return (
                            <Row key={index} style={{ width: "100%" }}>
                              <Col span={2}>
                                <Checkbox
                                  className="custom-checkbox"
                                  onChange={(e) => handleGroup(e, item)}
                                  disabled={
                                    allIndiviually ||
                                    checkAllMembers ||
                                    isCheckMembers?.length > 0 ||
                                    isCheckOwners?.length > 0
                                  }
                                  checked={checkedGroups.includes(item)}
                                />
                              </Col>
                              <Col span={18} style={{ display: "flex" }}>
                                {item.avatar && imageLoaded === "" && (
                                  <Skeleton.Image
                                    active={true}
                                    className="user-skeleton"
                                  />
                                )}
                                {!item.avatar ? (
                                  <Avatar
                                    name={item.name}
                                    color={
                                      selectedSport === 1
                                        ? "#F15A29"
                                        : "#4EA7F8"
                                    }
                                    className="user-avatar"
                                    maxInitials={1}
                                  />
                                ) : (
                                  <img
                                    src={item.avatar}
                                    style={
                                      imageLoaded === ""
                                        ? { display: "none" }
                                        : { display: "block" }
                                    }
                                    onLoad={() => setImageLoaded("loaded")}
                                  ></img>
                                )}
                                <div className="name-div">
                                  <div style={{ display: "flex" }}>
                                    <h3 className="user-name">{item.name}</h3>{" "}
                                    <img src={User} />
                                  </div>

                                  <h4 className="user-handle">
                                    ({item.members.length + item.owners.length}{" "}
                                    members)
                                  </h4>
                                </div>
                              </Col>
                              {item?.created_by_id ===
                                parseInt(
                                  localStorage.getItem("profile_id")
                                ) && (
                                <Col span={4}>
                                  <Button
                                    className="edit-group"
                                    onClick={() => {
                                      setEditSubGroup(true);
                                      setClickedSubGroup(item);
                                      setSubGroupMembers(item.members);
                                      setSubGroupOwners(item.owners);
                                    }}
                                  >
                                    Edit Group
                                  </Button>
                                </Col>
                              )}
                              <hr />
                            </Row>
                          );
                        })}
                      <Col span={24}>
                        <InfiniteScroll
                          dataLength={
                            Object.keys(members).length > 0 &&
                            members.results.length
                          }
                          next={getGroupMembers}
                          loader={<h4>Loading...</h4>}
                        >
                          {Object.keys(members).length > 0 &&
                            members.results.map(function (item, index) {
                              return (
                                <Row className="owner-row" key={index}>
                                  <Col span={24} style={{ display: "flex" }}>
                                    <Checkbox
                                      key={index}
                                      type="checkbox"
                                      name={item.first_name}
                                      id={item.id}
                                      className="custom-checkbox"
                                      onChange={(e) => handleClick(e, item)}
                                      style={{ marginRight: 26 }}
                                      disabled={
                                        allIndiviually ||
                                        checkAllMembers ||
                                        checkedGroups?.length > 0
                                      }
                                      checked={
                                        isCheckMembers.includes(item) ||
                                        checkAllMembers
                                      }
                                    />
                                    {item.avatar !== null &&
                                      imageLoaded === "" && (
                                        <Skeleton.Image
                                          active={true}
                                          className="user-skeleton"
                                        />
                                      )}
                                    {!item.avatar ? (
                                      <Avatar
                                        name={
                                          item.first_name + " " + item.last_name
                                        }
                                        color={
                                          selectedSport === 1
                                            ? "#F15A29"
                                            : "#4EA7F8"
                                        }
                                        className="user-avatar"
                                      />
                                    ) : (
                                      <img
                                        src={item.avatar}
                                        style={
                                          imageLoaded === ""
                                            ? { display: "none" }
                                            : { display: "block" }
                                        }
                                        onLoad={() => setImageLoaded("loaded")}
                                      ></img>
                                    )}
                                    <div className="name-div">
                                      <h3 className="user-name">
                                        {item.first_name + " " + item.last_name}{" "}
                                      </h3>
                                      <h4 className="user-handle">
                                        {item.username}
                                      </h4>
                                    </div>
                                  </Col>
                                  <hr />
                                </Row>
                              );
                              // }
                            })}
                        </InfiniteScroll>
                      </Col>
                    </>
                  ) : (
                    <>
                      {ownerSubGroups &&
                        ownerSubGroups.map(function (item, index) {
                          return (
                            <Row key={index} style={{ width: "100%" }}>
                              <Col span={2}>
                                <Checkbox
                                  className="custom-checkbox"
                                  onChange={(e) => handleGroup(e, item)}
                                  disabled={
                                    allIndiviually ||
                                    checkAllMembers ||
                                    isCheckMembers?.length > 0 ||
                                    isCheckOwners?.length > 0
                                  }
                                  checked={checkedGroups.includes(item)}
                                />
                              </Col>
                              <Col span={18} style={{ display: "flex" }}>
                                {item.avatar && imageLoaded === "" && (
                                  <Skeleton.Image
                                    active={true}
                                    className="user-skeleton"
                                  />
                                )}
                                {!item.avatar ? (
                                  <Avatar
                                    name={item.name}
                                    color={
                                      selectedSport === 1
                                        ? "#F15A29"
                                        : "#4EA7F8"
                                    }
                                    className="user-avatar"
                                    maxInitials={1}
                                  />
                                ) : (
                                  <img
                                    src={item.avatar}
                                    style={
                                      imageLoaded === ""
                                        ? { display: "none" }
                                        : { display: "block" }
                                    }
                                    onLoad={() => setImageLoaded("loaded")}
                                  ></img>
                                )}
                                <div className="name-div">
                                  <div style={{ display: "flex" }}>
                                    <h3 className="user-name">{item.name}</h3>{" "}
                                    <img src={User} />
                                  </div>

                                  <h4 className="user-handle">
                                    ({item.members.length + item.owners.length}{" "}
                                    members)
                                  </h4>
                                </div>
                              </Col>
                              {item?.created_by_id ===
                                parseInt(
                                  localStorage.getItem("profile_id")
                                ) && (
                                <Col span={4}>
                                  <Button
                                    className="edit-group"
                                    onClick={() => {
                                      setEditSubGroup(true);
                                      setClickedSubGroup(item);
                                      setSubGroupMembers(item.members);
                                      setSubGroupOwners(item.owners);
                                    }}
                                  >
                                    Edit Group
                                  </Button>
                                </Col>
                              )}
                              <hr />
                            </Row>
                          );
                        })}
                      <Col span={24}>
                        <InfiniteScroll
                          dataLength={
                            Object.keys(owners).length > 0 && owners.length
                          }
                          next={getGroupMembers}
                          loader={<h4>Loading...</h4>}
                        >
                          {Object.keys(owners).length > 0 &&
                            owners.map(function (item, index) {
                              return (
                                <Row className="owner-row" key={index}>
                                  <Col span={24} style={{ display: "flex" }}>
                                    <Checkbox
                                      key={index}
                                      type="checkbox"
                                      name={item.first_name}
                                      id={item.id}
                                      className="custom-checkbox"
                                      onChange={(e) => handleClick(e, item)}
                                      style={{ marginRight: 26 }}
                                      disabled={
                                        allIndiviually ||
                                        checkAllMembers ||
                                        checkedGroups?.length > 0
                                      }
                                      checked={
                                        isCheckOwners.includes(item) ||
                                        checkAllMembers
                                      }
                                    />
                                    {item.avatar !== null &&
                                      imageLoaded === "" && (
                                        <Skeleton.Image
                                          active={true}
                                          className="user-skeleton"
                                        />
                                      )}
                                    {!item.avatar ? (
                                      <Avatar
                                        name={
                                          item.first_name + " " + item.last_name
                                        }
                                        color={
                                          selectedSport === 1
                                            ? "#F15A29"
                                            : "#4EA7F8"
                                        }
                                        className="user-avatar"
                                      />
                                    ) : (
                                      <img
                                        src={item.avatar}
                                        style={
                                          imageLoaded === ""
                                            ? { display: "none" }
                                            : { display: "block" }
                                        }
                                        onLoad={() => setImageLoaded("loaded")}
                                      ></img>
                                    )}
                                    <div className="name-div">
                                      <h3 className="user-name">
                                        {item.first_name + " " + item.last_name}{" "}
                                      </h3>
                                      <h4 className="user-handle">
                                        {item.username}
                                      </h4>
                                    </div>
                                  </Col>
                                  <hr />
                                </Row>
                              );
                              // }
                            })}
                        </InfiniteScroll>
                      </Col>
                    </>
                  )}
                </Row>
              )}
            </div>
            <div style={{ marginTop: "8px", marginBottom: 30 }}>
              <Button
                className="cancel-btn"
                onClick={() =>
                  navigate({
                    pathname: `../${params.group_name}`,
                    search: `id=${params.id}&tab=workouts`,
                  })
                }
              >
                Back
              </Button>
              <Button
                className="next-btn"
                onClick={clickContinue}
                disabled={continueDisabled}
                style={{ background: darkButtonBackground }}
              >
                Continue
              </Button>
            </div>
          </div>
        </>
      ) : (
        <ConversationBox />
      )}
      <SubgroupModal />
      {Object.keys(clickedSubgroup).length > 0 && <EditSubgroupModal />}
    </div>
  );
};
export default Recepients;
