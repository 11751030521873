import React, { useEffect, useState, useContext } from "react";
import {
  deleteItemTraining,
  formPostDataTraining,
  getDataCoach,
  getDataTrainingCoach,
  jsonPostDataTraining,
  patchDataTraining,
} from "../../util/coach-api-calls";
import { Form, message } from "antd";
import { UserContext } from "../../context/user-context";
import { useNavigate } from "react-router-dom";
export const AssignMultipleGroupsContext = React.createContext({});
export const AssignMultipleGroupsContextProvider = ({ children = {} }) => {
  const navigate = useNavigate();
  const { selectedSport } = useContext(UserContext);
  const [form] = Form.useForm();
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  let groupName = window.location.pathname.slice(
    window.location.pathname.lastIndexOf("/") + 1,
    window.location.pathname.length
  );
  const [detailVideoId, setDetailVideoId] = useState("");
  const [mediaType, setMediaType] = useState("upload");
  const [editTaskModal, setEditTaskModal] = useState(false);
  const [title, setTitle] = useState("");
  const [privacyModal, setPrivacyModal] = useState(false);
  const [privacy, setPrivacy] = useState(false);
  const [newTaskArray, setNewTaskArray] = useState([]);
  const [data, setData] = useState(newTaskArray);
  const [timeLimitSeconds, setTimeLimitSeconds] = useState("");
  const [existingTaskModal, setExistingTaskModal] = useState(false);
  const [newTaskModal, setNewTaskModal] = useState(false);
  const [detailImgSrc, setDetailImgSrc] = useState();
  const [detailImageUrl, setDetailImageUrl] = useState();
  const [existingTasks, setExistingTasks] = useState([]);
  const [skillLevel, setSkillLevel] = useState("");
  const [uploadError, setUploadError] = useState(false);
  const [taskType, setTaskType] = useState(true);
  const [selectedTask, setSelectedTask] = useState({});
  const [isCheckMembers, setIsCheckMembers] = useState([]);
  const [subgroupModal, setSubgroupModal] = useState(false);
  const [subGroupName, setSubGroupName] = useState("");
  const [subLoading, setSubLoading] = useState(false);
  const [checkAllGroups, setCheckAllGroups] = useState(false);
  const [imageLoaded, setImageLoaded] = useState("");
  const [editSubGroupName, setEditSubGroupName] = useState("");
  const [createdWorkout, setCreatedWorkout] = useState();
  const [subGroupMembers, setSubGroupMembers] = useState([]);
  const [membersView, setMembersView] = useState(false);
  const [error, setError] = useState(false);
  const [tags, setTags] = useState([]);
  const [sort, setSort] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [current, setCurrent] = useState();
  const [assignGroupView, setAssignGroupView] = useState(false);
  const [allGroups, setAllGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [subGroups, setSubGroups] = useState([]);
  const [members, setMembers] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [editSubGroup, setEditSubGroup] = useState(false);
  const [clickedSubgroup, setClickedSubGroup] = useState({});
  const [endDate, setEndDate] = useState("");
  const [checkedGroups, setCheckedGroups] = useState([]);
  const [assignGroupMembers, setAssignGroupMembers] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState();
  let profileId = localStorage.getItem("profile_id");
  const [allGroupMembers, setAllGroupMembers] = useState(false);

  const createNewTask = (values) => {
    let instructionError =
      values.instructions === undefined || values.instructions === ""
        ? true
        : false;
    if (
      mediaType === "upload" &&
      instructionError === true &&
      detailVideoId === "" &&
      !detailImageUrl
    ) {
      setUploadError(true);
    } else {
      setUploadError(false);
      let formData = new FormData();
      formData.append("title", values.task_title);
      formData.append("order", newTaskArray.length + 1);
      if (detailVideoId) {
        formData.append("media_uri", detailVideoId);
      }
      if (detailImageUrl) {
        formData.append("file", detailImageUrl);
      }
      if (values.media_url) {
        formData.append("media_url", values.media_url);
      }
      formData.append(
        "media_type",
        detailVideoId
          ? "vimeo"
          : values.media_url
          ? "embedded"
          : detailImageUrl
          ? "image"
          : undefined
      );
      formData.append(
        "media_required",
        values.video_required === "yes" ? true : false
      );
      formData.append("instruction", values.instructions);
      formData.append(
        "is_public",
        // values.task_status === "public" ? true :
        "false"
      );
      formData.append("skill_level", values.level);
      formData.append(
        "is_time_limit",
        values.time_limit === true ? true : false
      );
      formData.append("time_limit_seconds", "30");
      formData.append(
        "score_based_test",
        values.task_type === true ? true : false
      );
      formData.append("tags", tags.toString());
      formData.append("group_id", params.id);
      formData.append("group_name", decodeURI(groupName));
      formData.append("num_completion", 1);
      formData.append("legacy_point", 0);
      formData.append("allow_more_reps", false);
      formData.append("lock", false);
      formData.append("best_score", 100);
      formPostDataTraining(`task`, formData)
        .then((res) => {
          setNewTaskArray((prevTask) => [...prevTask, res.data]);
          setNewTaskModal(false);
          form.resetFields();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const getAllGroups = () => {
    setLoading(true);
    getDataCoach(`profile/team/management/licenses/${profileId}`)
      .then((res) => {
        setAllGroups(res.data.team_management_tiers[0].groups)
        // setAllGroups(res?.data[0]?.groups);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getGroupMembers = (id) => {
    setSubLoading(true);
    getDataTrainingCoach(
      `group/${id}/workout/${createdWorkout?.id}/subgroup?is_tm=true`
    )
      .then((res) => {
        if (res && res.status === 200) {
          setSubGroups(res.data.subgroups);
          setMembers(res.data.members);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setSubLoading(false);
      });
  };
  const createWorkout = () => {
    if (title === "") {
      setError(true);
    } else {
      setError(false);
      const list_tasks = newTaskArray
        .filter(
          (ele, ind) =>
            ind === newTaskArray.findIndex((elem) => elem.id === ele.id)
        )
        .map((e, index) => ({
          id: e.id,
          order: index + 1,
          //   lock: e.lock,
          lock:
            current === index || (index > current && index !== 0)
              ? true
              : false,
          num_completion: isNaN(e.num_completion) ? 1 : e.num_completion,
          allow_more_reps: e.allow_more_reps,
          is_cw: false,
        }));
      let workoutData = JSON.stringify({
        title: title,
        is_public: privacy,
        list_tasks: list_tasks,
        is_cw: false,
      });
      jsonPostDataTraining(`workout`, workoutData)
        .then((res) => {
          setCreatedWorkout(res.data);
          setNewTaskArray((newTaskArray) => []);
          setAssignGroupView(true);
        })
        .catch((err) => {
          console.log(err);
          message.error({
            content: err.response.data.detail,
          });
        });
    }
  };
  const createSubGroup = () => {
    let profiles = [];
    isCheckMembers.map((e) => profiles.push(e));
    let data = {
      name: subGroupName,
      profiles: profiles,
      taskgroup_id: createdWorkout.id,
      group_id: selectedGroup.id,
    };
    jsonPostDataTraining(`subgroup`, data)
      .then((res) => {
        setAssignGroupMembers(assignGroupMembers => [])
        setIsCheckMembers([]);
        setIsCheckMembers((isCheckMembers) => []);
        setSubGroupName("");
        getGroupMembers(selectedGroup?.id);
        profiles = []
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateSubGroup = () => {
    let data = {
      name: editSubGroupName === "" ? clickedSubgroup.name : editSubGroupName,
      profiles: subGroupMembers,
      taskgroup_id: createdWorkout.id,
    };
    patchDataTraining(`subgroup/${clickedSubgroup.id}`, data)
      .then((res) => {
        setSubGroupMembers((subGroupMembers) => []);
        getGroupMembers(selectedGroup.id);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const deleteTask = (id) => {
    deleteItemTraining(`task/${id}`)
      .then((res) => {
        if (res && res.status === 200) {
          message.success({
            content: "Task deleted successfully",
          });
          const updatedArray = newTaskArray.filter((item) => item.id !== id);
          setNewTaskArray(updatedArray);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const assignWorkout = (createdWorkout) => {
    const uniqueProfiles = assignGroupMembers.map(group => ({
      ...group,
      profiles: [...new Map(group?.profiles?.map(profile => [profile.id, profile])).values()]
  }));
    let data = {
      start_date:
        startDate && startDate !== "" ? startDate.toISOString() : null,
      end_date: endDate && endDate !== "" ? endDate.toISOString() : null,
      group_member_assign: uniqueProfiles,
      assignAll: allGroupMembers,
    };

    jsonPostDataTraining(
      `team-management/workout/${createdWorkout?.id}/assign`,
      data
    )
      .then((res) => {
        if (res && res.status === 200) {
          setAssignGroupMembers([]);
          navigate("/coach/ballogy-team-training");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  React.useEffect(() => {
    if (existingTaskModal) {
    }
  }, [skillLevel, taskType, sort, selectedCategory]);
  useEffect(() => {
    if (!existingTaskModal) {
      setExistingTasks((existingTasks) => []);
    } else {
    }
  }, [existingTaskModal]);
  const defaultContext = {
    createNewTask,
    detailVideoId,
    setDetailVideoId,
    setTags,
    mediaType,
    membersView,
    createdWorkout,
    setMediaType,
    timeLimitSeconds,
    setTimeLimitSeconds,
    setError,
    error,
    newTaskArray,
    deleteTask,
    newTaskModal,
    setNewTaskModal,
    existingTaskModal,
    setExistingTaskModal,
    form,
    detailImgSrc,
    setDetailImgSrc,
    detailImageUrl,
    setDetailImageUrl,
    createWorkout,
    setTitle,
    data,
    setData,
    uploadError,
    existingTasks,
    setSkillLevel,
    setTaskType,
    setSort,
    setSelectedCategory,
    setNewTaskArray,
    setPrivacyModal,
    privacyModal,
    privacy,
    setPrivacy,
    editTaskModal,
    setEditTaskModal,
    selectedTask,
    setSelectedTask,
    params,
    setMembersView,
    current,
    setCurrent,
    assignGroupView,
    setAssignGroupView,
    allGroups,
    getAllGroups,
    loading,
    subGroups,
    members,
    setSubGroups,
    setMembers,
    getGroupMembers,
    isCheckMembers,
    setIsCheckMembers,
    subgroupModal,
    setSubgroupModal,
    subGroupName,
    setSubGroupName,
    createSubGroup,
    subLoading,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    assignWorkout,
    checkedGroups,
    setCheckedGroups,
    selectedGroup,
    setSelectedGroup,
    assignGroupMembers,
    setAssignGroupMembers,
    checkAllGroups,
    setCheckAllGroups,
    allGroupMembers,
    setAllGroupMembers,
    setClickedSubGroup,
    members,
    // groupDetail,
    updateSubGroup,
    loading,
    imageLoaded,
    getGroupMembers,
    setImageLoaded,
    setSubGroupMembers,
    subGroupMembers,
    setEditSubGroup,
    setEditSubGroupName,
    editSubGroup,
    clickedSubgroup,
  };
  return (
    <AssignMultipleGroupsContext.Provider value={defaultContext}>
      {children}
    </AssignMultipleGroupsContext.Provider>
  );
};
