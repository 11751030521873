import React, { useContext, useEffect, useState } from "react";
import { formPostData } from "../../util/coach-api-calls";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import { UserContext } from "../../context/user-context";
export const CreateGroupContext = React.createContext({});
export const CreateGroupContextProvider = ({ children = {} }) => {
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const { selectedSport } = useContext(UserContext);
  const navigate = useNavigate();
  const [workoutLeaderBoard, setWorkoutLeaderBoard] = useState(true);
  const [bsaLeaderBoard, setBsaLeaderBoard] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [imgSrc, setImgSrc] = useState();
  const [loading, setLoading] = useState(false);
  const [imageError, setImageError] = useState(false);
  const createGroup = (values) => {
    setLoading(true);
    setImageError(false);
    let formData = new FormData();
    if (imageUrl) {
      formData.append("avatar", imageUrl);
    }
    formData.append("name", values.name);
    formData.append("sport_id", selectedSport);
    formData.append(
      "display_announcements",
      values.display_announcements_section
    );
    formData.append("workout_leaderboards ", workoutLeaderBoard);
    formData.append("bsa_leaderboards ", bsaLeaderBoard);
    formData.append("team_management_id", params.team_management_id);
    formPostData(`profile/team/management/create/group`, formData)
      .then((res) => {
        if (res.status === 200) {
          message.success({
            content: "Group created successfully.",
            duration: 1,
          });
          setTimeout(() => {
            navigate("/coach/ballogy-team-training");
          }, 1200);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const defaultContext = {
    createGroup,
    setImageUrl,
    setBsaLeaderBoard,
    setWorkoutLeaderBoard,
    imgSrc,
    setImgSrc,
    loading,
    imageError,
    setImageError,
  };
  return (
    <CreateGroupContext.Provider value={defaultContext}>
      {children}
    </CreateGroupContext.Provider>
  );
};
