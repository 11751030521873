import React, { useEffect, useState } from "react";
import {
  getData,
  jsonPostData,
  deleteItem,
} from "../../util/api-calls";
export const TrialCodeContext = React.createContext({});
export const TrialCodeContextProvider = ({ children = {} }) => {
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const [loading, setLoading] = useState(false);
  const [allCodes, setAllCodes] = useState([]);
  const [days, setDays] = useState();
  const [selectedTrial, setSelectedTrial] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const getAllCodes = () => {
    setLoading(true);
    getData(`group/${params.id}/free-trial`)
      .then((res) => {
        console.log(res);
        setAllCodes(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const generateCode = () => {
    jsonPostData(`group/${params.id}/free-trial`, {
      days_limit: days,
    })
      .then((res) => {
        setDays();
        getAllCodes();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const deleteCode = (members) => {
    deleteItem(
      `group/${params.id}/free-trial/${selectedTrial?.id}?keep_members=${members}`
    )
      .then((res) => {
        setDeleteModal(false);
        getAllCodes();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const defaultContext = {
    getAllCodes,
    loading,
    allCodes,
    generateCode,
    setDays,
    deleteCode,
    selectedTrial,
    setSelectedTrial,
    deleteModal,
    setDeleteModal,
    days,
  };
  return (
    <TrialCodeContext.Provider value={defaultContext}>
      {children}
    </TrialCodeContext.Provider>
  );
};
