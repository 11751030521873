import React, { useContext, useEffect, useState } from "react";
import { Input, Row, Col, Modal, Skeleton, Checkbox, Form, Button } from "antd";
import Search from "../../../assets/images/search.svg";
import Avatar from "react-avatar";
import "./owners-members.scss";
import { MemberContext } from "../../../context/group-context/members-context";
import InfiniteScroll from "react-infinite-scroll-component";
import { UserContext } from "../../../context/user-context";
const MembersModal = ({ memberModal, setMemberModal }) => {
  const {
    allMembers,
    totalMembers,
    listAllMembers,
    hasMore,
    setAllMembers,
    imageLoaded,
    setImageLoaded,
    addMembers,
    selectedTab,
    isCheck,
    setIsCheck,
    setPageNumber,
    setSearch,
    setTotalMembers,
    setHasMore,
    setTrialModal,
  } = useContext(MemberContext);
  const [form] = Form.useForm();
  const { selectedSport, color, lightButtonBackground } =
    useContext(UserContext);
  const [selectedInput, setSelectedInput] = useState(
    "Search owners by name..."
  );
  const [isCheckAll, setIsCheckAll] = useState(false);
  const handleCancel = () => {
    setTotalMembers(0);
    form.resetFields();
    setSearch("");
    setAllMembers((allMembers) => []);
    setPageNumber(1);
    setHasMore(false);
    setMemberModal(false);
  };
  const handleClick = (e, index) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    for (let i = 0; i < allMembers.length; i++) {
      setIsCheck((prevMembers) => [...prevMembers, allMembers[i].id]);
    }
    if (isCheckAll) {
      setIsCheck([]);
    }
  };
  const searchMembers = (e) => {
    if (e.target.value.length >= 2) {
      setPageNumber(1);
      setAllMembers([]);
      setSearch(e.target.value);
    } else if (e.target.value.length === 0) {
      setAllMembers((allMembers) => []);
      setSearch("");
    }
  };
  useEffect(() => {
    if (selectedTab === "1") {
      setSelectedInput("Search owners by name...");
    } else {
      setSelectedInput("Search members by name...");
    }
  }, [selectedTab]);
  return (
    <Modal
      wrapClassName={
        "add-members-modal " +
        (selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal")
      }
      open={memberModal}
      onOk={addMembers}
      onCancel={handleCancel}
      okText={"Finish Adding"}
      cancelText={"Cancel"}
      width={"937px"}
    >
      <div className="add-members-main">
        <h1>Add {selectedTab === "1" ? " Owners" : " Members"}</h1>
        <br />
        <div style={{ display: "flex" }}>
          <Form form={form}>
            <Form.Item name="search">
              <Input
                addonBefore={<img src={Search} />}
                placeholder={selectedInput}
                inputProps={{
                  "aria-label": "description",
                }}
                className="search-input"
                onChange={searchMembers}
                style={{ width: 413 }}
              />
            </Form.Item>
          </Form>
          <Button
            className="red-base-btn"
            style={{
              color: color,
              background: lightButtonBackground,
              marginLeft: "auto",
              width: "auto",
            }}
            onClick={() => {
              setTrialModal(true);
              setMemberModal(false);
            }}
          >
            Trial Period Code
          </Button>
        </div>

        <div className="members-list">
          <div style={{ display: "flex", marginBottom: "37px" }}>
            {selectedTab === "2" && (
              <Checkbox
                type="checkbox"
                name="selectAll"
                id="selectAll"
                className="custom-checkbox"
                onChange={handleSelectAll}
                style={{ marginRight: 16 }}
              />
            )}
            <h2>
              {" "}
              {selectedTab === "1" ? "Owners " : "Members "}({totalMembers})
            </h2>
          </div>

          <InfiniteScroll
            dataLength={allMembers.length}
            next={listAllMembers}
            height={400}
            hasMore={hasMore}
            loader={<h4>Loading...</h4>}
          >
            {allMembers.map((item, index) => (
              <Row className="owner-row" key={index}>
                <Col span={24} style={{ display: "flex" }}>
                  <Checkbox
                    key={index}
                    type="checkbox"
                    name={item.first_name}
                    id={item.id}
                    className="custom-checkbox"
                    onChange={(e) => handleClick(e, index)}
                    style={{ marginRight: 26 }}
                    checked={isCheck.includes(item.id)}
                  />
                  {item.avatar !== null && imageLoaded === "" && (
                    <Skeleton.Image active={true} className="user-skeleton" />
                  )}
                  {!item.avatar ? (
                    <Avatar
                      name={item.first_name + " " + item.last_name}
                      color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
                      className="user-avatar"
                    />
                  ) : (
                    <img
                      src={item.avatar}
                      style={
                        imageLoaded === ""
                          ? { display: "none" }
                          : { display: "block" }
                      }
                      onLoad={() => setImageLoaded("loaded")}
                    ></img>
                  )}
                  <div className="name-div">
                    <h3 className="user-name">
                      {item.first_name + " " + item.last_name}
                    </h3>
                    <h4 className="user-handle">@{item.username}</h4>
                  </div>
                </Col>
                {/* <Col span={4} style={{ textAlign: "end" }}>
                  <Select
                    placeholder="Select"
                    optionFilterProp="children"
                    popupClassName="member-select-dropdown"
                    onChange={(e) => onChange(e, item.id)}
                    className="member-select"
                    suffixIcon={<img src={DownArrow} />}
                    options={[
                      {
                        value: "owner",
                        label: "Owner",
                      },
                      {
                        value: "member",
                        label: "Member",
                      },
                    ]}
                  />
                </Col> */}
                <hr />
              </Row>
            ))}
          </InfiniteScroll>
        </div>
      </div>
    </Modal>
  );
};

export default MembersModal;
