import React, { useState } from "react";
import { formPostData, getDataCoach } from "../../util/coach-api-calls";
import { useNavigate } from "react-router-dom";
export const MessageMultipleGroupsContext = React.createContext({});
export const MessageMultipleGroupsContextProvider = ({ children = {} }) => {
  const navigate = useNavigate();
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const [allGroups, setAllGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageLoaded, setImageLoaded] = useState("");
  const [imageUrl, setImageUrl] = useState();
  const [imgSrc, setImgSrc] = useState();
  const [fileType, setFileType] = useState("image");
  const [messageText, setMessageText] = useState("");
  const [messageBox, setMessageBox] = useState(false);
  const [checkedGroups, setCheckedGroups] = useState([]);
  const [checkAllGroups, setCheckAllGroups] = useState(false);

  let profileId = localStorage.getItem("profile_id");

  const getAllGroups = () => {
    setLoading(true);
    getDataCoach(`profile/team/management/licenses/${profileId}`)
      .then((res) => {
        setAllGroups(res.data.team_management_tiers[0].groups)
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  let groupdIds = [];
  const sendMessage = () => {
    if (checkAllGroups) {
      for (let i = 0; i < allGroups?.length; i++) {
        if(allGroups[i]?.is_active){
        groupdIds.push(allGroups[i]["group"].id);
        }
      }
    } else {
      for (let i = 0; i < checkedGroups.length; i++) {
        groupdIds.push(checkedGroups[i]["group"].id);
      }
    }
    let formData = new FormData();
    formData.append("group_ids", groupdIds?.toString());
    formData.append("message", messageText);
    if (imageUrl) {
      formData.append("file", imageUrl);
      formData.append("file_type", fileType);
    }
    formPostData(`team-management/group/messages/create`, formData)
      .then((res) => {
        navigate("/coach/ballogy-team-training");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const defaultContext = {
    allGroups,
    setAllGroups,
    getAllGroups,
    loading,
    imageLoaded,
    setImageLoaded,
    setFileType,
    setImageUrl,
    setImgSrc,
    messageBox,
    setMessageBox,
    imageUrl,
    imgSrc,
    messageText,
    checkedGroups,
    setCheckedGroups,
    setCheckAllGroups,
    checkAllGroups,
    setMessageText,
    fileType,
    sendMessage,
  };
  return (
    <MessageMultipleGroupsContext.Provider value={defaultContext}>
      {children}
    </MessageMultipleGroupsContext.Provider>
  );
};
