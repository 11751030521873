import React, { useEffect, useRef, useState } from "react";
import {
  getDataCoach,
  patchData,
  jsonPostDataCoach,
  deleteItem,
} from "../../util/coach-api-calls";

import { message, notification as antdNotification, } from "antd";
antdNotification.config({
  placement: "top",
  duration: 20,
});
export const MemberContext = React.createContext({});
let selectedList = [];
let updateMember = false;
const notification = {
  open: ({ message, description }) => {
    antdNotification.open({
      message,
      description,
      className: "notification",
      duration: 2,
      closable: false,
      style: {
        width: 795,
        minWidth: 320,
        maxWidth: 795,
        backgroundColor: "#FFF1ED",
        margin: 0,
        boxShadow: "unset",
        color: "#7E8299"
      },
    });
  },
};
export const MemberContextProvider = ({ children = {} }) => {
  // const [api, contextHolder] = notification.useNotification();
  const [membersTotal, setMembersTotal] = useState(0);
  const [ownerTotal, setOwnersTotal] = useState(0);
  const [ownersList, setOwnersList] = useState([]);
  const [pendingTotal, setPendingTotal] = useState(0);
  const [pendingList, setPendingList] = useState([]);
  const [membersList, setMembersList] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [hasMoreMembers, setHasMoreMembers] = useState(true);
  const [hasMoreOwners, setHasMoreOwners] = useState(true);
  const [hasMorePending, setHasMorePending] = useState(true);
  const [allMembers, setAllMembers] = useState([]);
  const [owner, setOwner] = useState([{ id: "", is_owner: "" }]);
  const [search, setSearch] = useState("");
  const [ownerSearch, setOwnerSearch] = useState("");
  const [pendingSearch, setPendingSearch] = useState("");
  const [memberSearch, setMemberSearch] = useState("");
  const [totalMembers, setTotalMembers] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [ownerPageNumber, setOwnerPageNumber] = useState(1);
  const [pendingPageNumber, setPendingPageNumber] = useState(1);
  const [memberPageNumber, setMemberPageNumber] = useState(1);
  const [imageLoaded, setImageLoaded] = useState("");
  const [memberModal, setMemberModal] = useState(false);
  const [trialModal , setTrialModal] = useState(false)
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheckMembers, setIsCheckMembers] = useState([]);
  const [isCheckOwners, setIsCheckOwners] = useState([])
  const [isCheck, setIsCheck] = useState([]);
  const [selectedTab, setSelectedTab] = useState("1");
  const [expiryModal, setExpiryModal] = useState(false)
  const [membersData, setMembersData] = useState()
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false; // toggle flag after first render/mounting
      return;
    }
    if (
      ownersList.length === 0 &&
      updateMember === true &&
      ownerPageNumber === 1
    ) {
      setIsCheckMembers((isCheckMembers) => []);
      setIsCheckAll();
      getGroupOwners();
    }
    if (
      membersList.length === 0 &&
      updateMember === true &&
      memberPageNumber === 1
    ) {
      setIsCheckMembers((isCheckMembers) => []);
      setIsCheckAll();
      getGroupMembers();
    }
    if (
      pendingList.length === 0 &&
      updateMember === true &&
      pendingPageNumber === 1
    ) {
      setIsCheckMembers((isCheckMembers) => []);
      setIsCheckAll();
      getPendingMembers();
    }
  }, [ownersList, ownerPageNumber, membersList, memberPageNumber, pendingList, pendingPageNumber]);
  useEffect(() => {
    if(search.length > 0){
    const delayDebounceFn = setTimeout(() => {
      listAllMembers();
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
    }else{
      setTotalMembers(0);
      setAllMembers(allMembers=>[]);
      setPageNumber(1);
      setHasMore(false);
    }
  }, [search]);
  useEffect(() => {
    if (memberModal === true) {
      listAllMembers()
    }
  }, [memberModal])
  useEffect(() => {
    getGroupMembers();
  }, [memberSearch]);
  useEffect(() => {
    getGroupOwners();
  }, [ownerSearch]);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getPendingMembers();
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
  }, [pendingSearch]);
  const getPendingMembers = (e) => {
    if (e) {
      setPendingPageNumber(1);
      setMembersList((membersList) => []);
      setOwnersList((ownersList) => [])
    }
    getDataCoach(
      `coach/group/${params.id
      }/profiles?member_type=pending&search=${pendingSearch}&page=${e === 1 ? e : pendingPageNumber}`
    )
      .then((res) => {
        updateMember = false;
        setPendingTotal(res.data.count);
        setPendingList(oldArray => [...oldArray, ...res.data.results.profiles]);
        if (res.data.next !== null) {
          setPendingPageNumber(ownerPageNumber + 1);
          setHasMorePending(true);
        } else {
          setHasMorePending(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getGroupOwners = (e) => {
    if (e) {
      setOwnerPageNumber(1);
      setMembersList((membersList) => []);
      setPendingList((pendingList) => [])
    }
    getDataCoach(
      `coach/group/${params.id
      }/profiles?member_type=owner&search=${ownerSearch}&page=${ownerPageNumber}`
    )
      .then((res) => {
        updateMember = false;
        setOwnersTotal(res.data.count);
        setOwnersList([...ownersList, ...res.data.results.profiles]);
        if (res.data.next !== null) {
          setOwnerPageNumber(ownerPageNumber + 1);
          setHasMoreOwners(true);
        } else {
          setHasMoreOwners(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getGroupMembers = (e) => {
    if (e) {
      setPageNumber(1);
      setMembersList((membersList) => []);
      setOwnersList((ownersList) => [])
    }
    // is_owner=${false}&
    getDataCoach(
      `coach/group/${params.id
      }/profiles?member_type=member&search=${memberSearch}&page=${e ? e : memberPageNumber
      }`
    )
      .then((res) => {
        updateMember = false;
        setMembersTotal(res.data.count);
        setMembersList([...membersList, ...res.data.results.profiles]);

        if (res.data.next !== null) {
          setMemberPageNumber(memberPageNumber + 1);
          setHasMoreMembers(true);
        } else {
          setHasMoreMembers(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateMemberStatus = (e, status) => {
    updateMember = true;
    let status1 = status === true ? true : false;
    let data = {
      "profile_id": e.member.id,
      "is_owner": status1
    }
    patchData(`coach/group/${params.id}/profiles`, data)
      .then((res) => {
        if (status === false) {
          setOwnersList([]);
          setOwnerPageNumber(1);
        } else {
          setMembersList([]);
          setMemberPageNumber(1);
        }
      })
      .catch((err) => {
        message.error({
          content: err?.response?.data?.details
        })
        console.log(err);
      });
  };
  const deleteMemberOwner = (e, status) => {
    updateMember = true;
    deleteItem(`coach/group/${params.id}/profiles?ids=${e.member.id}`)
      .then((res) => {
        if (e.is_owner === true) {
          setOwnersList([]);
          setOwnerPageNumber(1);
        } else {
          setMembersList([]);
          setMemberPageNumber(1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const listAllMembers = (e) => {
    if (e === 1 || pageNumber === 1) {
      setAllMembers([])
      setAllMembers(allMembers => []);
      setTotalMembers(0)
      setMembersData()
    }
    getDataCoach(`coach/group/${params.id}/all-profiles?page=${e === 1 ? e : pageNumber}&search=${search}`)
      .then((res) => {
        setTotalMembers(res.data.count);
        setMembersData(res.data.results)
        setAllMembers(oldArray => [...oldArray, ...res.data.results.profiles]);
        if (res.data.next !== null) {
          setPageNumber(pageNumber + 1);
          setHasMore(true);
        } else {
          setHasMore(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const addMembers = (e) => {
    updateMember = true;
    for (let i = 0; i < isCheck.length; i++) {
      selectedList.push({
        id: isCheck[i],
        is_owner: selectedTab === "1" ? true : false,
      });
    }
    jsonPostDataCoach(`admin/group/${params.id}/profile`, selectedList)
      .then((res) => {
        selectedList = [];
        setIsCheck([]);
        setMemberModal(false);
        if (selectedTab === "1") {
          setOwnersList([]);
          setOwnerPageNumber(1);
        } else {
          setMembersList([]);
          setMemberPageNumber(1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const profileSearch = (e) => {
    setAllMembers([])
    setAllMembers(allMembers => []);
    let query = e.target.value;
    getDataCoach(`admin/group/${params.id}/profile?search=${query}`)
      .then((res) => {
        setAllMembers(oldArray => [...oldArray, ...res.data]);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const deleteMembers = () => {
    updateMember = true;
    deleteItem(`coach/group/${params.id}/profiles?ids=${isCheckMembers}`)
      .then((res) => {
        message.success({
          content: "Member(s) deleted successfully",
          duration: 1,
        });
        setIsCheckMembers((isCheckMembers) => []);
        setIsCheckAll();
        setOwnerPageNumber(1)
        setOwnersList([])
        setMemberPageNumber(1);
        setMembersList([]);

      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onChangeTabs = (key) => {
    updateMember = true;
    setPendingSearch("")
    setOwnerSearch("")
    setMemberSearch("")
    setSelectedTab(key);
    if (key === "1") {
      setOwnersList(ownersList => []);
      setOwnerPageNumber(1);
    } else if (key === "2") {
      setMembersList(membersList => []);
      setMemberPageNumber(1);
    }
    else {
      setPendingList(pendingList => []);
      setPendingPageNumber(1)
    }
  };
  const inviteMembers = (e, access_code) => {
    setPendingList((pendingList) => [])
    let data = {
      "group_id": params.id,
      "access_code": access_code,
      "profile_id": e.id
    }
    jsonPostDataCoach(`coach/group/invite`, data).then((res) => {
      setAllMembers(allMembers => [])
      setPageNumber(1);
      getPendingMembers(1);
      listAllMembers(1);
      notification.open({
        message: `Invitation Sent!`,
        description: `You successfully invited ${e.username} to your group. A notification has been sent and must be approved before you will see this user’s name in your Group Members list.`,
        placement: "top"
      });
      getGroupMembers();
      getGroupOwners();
    }).catch((err) => {
      console.log(err)
    })
  }
  const defaultContext = {
    onChangeTabs,
    hasMore,
    totalMembers,
    ownerTotal,
    membersTotal,
    setMembersTotal,
    ownersList,
    membersList,
    search,
    setSearch,
    updateMemberStatus,
    setAllMembers,
    listAllMembers,
    searchResult,
    setSearchResult,
    setOwnersList,
    allMembers,
    owner,
    setOwner,
    setTotalMembers,
    imageLoaded,
    setImageLoaded,
    addMembers,
    memberModal,
    setMemberModal,
    deleteMemberOwner,
    selectedTab,
    setSelectedTab,
    isCheck,
    setIsCheck,
    setOwnerPageNumber,
    setOwnerSearch,
    setMembersList,
    setMemberSearch,
    hasMoreOwners,
    getGroupOwners,
    getPendingMembers,
    profileSearch,
    setPageNumber,
    deleteMembers,
    isCheckMembers,
    setIsCheckMembers,
    isCheckAll,
    setIsCheckAll,
    hasMoreMembers,
    setHasMoreMembers,
    getGroupMembers,
    isCheckOwners,
    setIsCheckOwners,
    expiryModal,
    setExpiryModal,
    pendingTotal,
    pendingList,
    setPendingPageNumber,
    setPendingSearch,
    setPendingList,
    hasMorePending,
    inviteMembers,
    setMemberPageNumber,
    membersData,
    search,
    setHasMore,
    trialModal , 
    setTrialModal

    // contextHolder
  };
  return (
    <MemberContext.Provider value={defaultContext}>
      {children}
    </MemberContext.Provider>
  );
};
