import React, { useContext, useEffect } from "react";
import { Modal, Button, Select, Table, Popover } from "antd";
import "./index.scss";
import { MemberContext } from "../../../../coach-context/group-context/members-context";
import DownArrow from "../../../../assets/images/arrows.svg";
import { UserContext } from "../../../../context/user-context";
import Dropdown from "antd/es/dropdown/dropdown";
import Copy from "../../../../assets/images/copy.svg";
import { TrialCodeContext , TrialCodeContextProvider } from "../../../../coach-context/group-context/trial-code-context";
const TrialPeriodModal = () => {
  const { selectedSport, darkButtonBackground, lightButtonBackground } =
    useContext(UserContext);
  const { trialModal, setTrialModal } = useContext(MemberContext);
  const {
    getAllCodes,
    loading,
    allCodes,
    generateCode,
    setDays,
    deleteCode,
    setSelectedTrial,
    deleteModal,
    setDeleteModal,
    days,
  } = useContext(TrialCodeContext);
  useEffect(() => {
    if (trialModal) {
      getAllCodes();
    }
  }, [trialModal]);
  const handleCancel = () => {
    setDays();
    setTrialModal(false);
  };

  let daysList = [...Array(30).keys()].map((i) => ({
    value: i + 1,
    label: `${i + 1}`,
  }));
  daysList.push({ value: 45, label: "45" });
  daysList.push({ value: 60, label: "60" });
  daysList.push({ value: 90, label: "90" });
  const onChangeDays = (value) => {
    setDays(value);
  };
  const items = [
    {
      key: "delete",
      label: "Delete Code",
    },
  ];
  const columns = [
    {
      title: "code",
      dataIndex: "access_code",
      render: (text, record, key) => {
        return (
          <div style={{ display: "flex", gap: 15 }}>
            <span style={{ marginTop: 7 }}>{record.access_code}</span>

            <Popover
              content={"Copied"}
              trigger="click"
              className="copied-popover"
              overlayClassName="copied-popover"
            >
              <img
                src={Copy}
                onClick={() =>
                  navigator.clipboard.writeText(record.access_code)
                }
                style={{ marginBottom: "-5px" }}
              />
            </Popover>
          </div>
        );
      },
    },
    {
      title: "expiring in",
      dataIndex: "days_limit",
    },
    {
      title: "used by",
      dataIndex: "members_joined",
    },
    {
      title: "action",
      dataIndex: "action",
      render: (text, record, key) => (
        <Dropdown
          placeholder="Actions"
          className={
            "custom-dropdown " +
            (selectedSport === 1
              ? "orange-theme-dropdown"
              : "blue-theme-dropdown")
          }
          menu={{
            items,
            onClick: ({ key }) => {
              setDeleteModal(true);
              setSelectedTrial(record);
            },
          }}
          overlayClassName={
            "custom-dropdown-menu " +
            (selectedSport === 1
              ? "orange-theme-dropdown"
              : "blue-theme-dropdown")
          }
        >
          <a onClick={(e) => e.preventDefault()}>
            Actions
            <img src={DownArrow} />
          </a>
        </Dropdown>
      ),
    },
  ];
  return (
    <Modal
      wrapClassName={
        "trial-period-modal " +
        (selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal")
      }
      open={trialModal}
      onOk={() => setTrialModal(false)}
      onCancel={handleCancel}
      okText={"Close"}
      width={"937px"}
    >
      <div className="trial-period-main">
        <h1>Trial Period Codes</h1>
        <br />
        <p>
          Select the number of days you would like your Trial Period to last:
        </p>
        <div style={{ display: "flex" }}>
          <Select
            placeholder="Select"
            optionFilterProp="children"
            onChange={onChangeDays}
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            defaultValue={days}
            value={days}
            size={"large"}
            suffixIcon={<img src={DownArrow} />}
            style={{ height: 38, width: 87 }}
            popupClassName={
              "custom-action-select-dropdown " +
              (selectedSport === 1
                ? "orange-theme-select"
                : "blue-theme-select")
            }
            className="custom-select"
            options={daysList}
          />
          <Button
            className="generate-btn"
            style={{ color: darkButtonBackground , opacity:!days ? 0.7 : 1 , background:"none" }}
            onClick={generateCode}
            disabled={!days}
          >
            Generate Code
          </Button>
        </div>
        <p>
          Users who join your group with this code will have their access
          suspended upon completion of the Trial Period.
        </p>
        <p style={{ fontWeight: 600, color: "#181c32" }}>
          A code can only be used once by each user!
        </p>
        <div className="table-div">
          <Table
            rowKey={(obj) => obj.id}
            pagination={{
              pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
              showSizeChanger: false,
              locale: { items_per_page: "" },
              defaultPageSize: 10,
              // current: 1,
              pageSize: 10,
              // total: 85,
              showQuickJumper: false,
            }}
            loading={loading}
            columns={columns}
            dataSource={allCodes}
            className={
              "table-container " +
              (selectedSport === 1 ? "orange-theme" : "blue-theme")
            }
          />
        </div>
      </div>
      <Modal
        className="delete-code-modal"
        wrapClassName={
          selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal"
        }
        open={deleteModal}
        width={"608px"}
        onCancel={() => setDeleteModal(false)}
        footer={[
          <Button
            type="primary"
            key={"false"}
            onClick={() => deleteCode(false)}
            className="red-base-btn"
            style={{
              background: lightButtonBackground,
              color: darkButtonBackground,
            }}
          >
            Delete and suspend members{" "}
          </Button>,
          <Button
            onClick={() => deleteCode(true)}
            type="primary"
            key="true"
            className="dark-base-btn"
            style={{ background: darkButtonBackground }}
          >
            Delete, but keep members
          </Button>,
        ]}
      >
        <h1>Delete Code</h1>
        <p>
          After deleting this code, would you like to allow existing members to
          complete their Trial Period, or should all users of this code be
          suspended from group access immediately?
        </p>
      </Modal>
    </Modal>
  );
};

const TrialCode = () => {
  return (
    <TrialCodeContextProvider>
      <TrialPeriodModal />
    </TrialCodeContextProvider>
  );
};

export default TrialCode;
