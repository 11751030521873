import React, { useContext, useEffect, useState } from "react";
import "./header-wrapper.scss";
import { Menu, Dropdown, Space, message, Select, Badge, Button } from "antd";
import { Link, NavLink, useNavigate } from "react-router-dom";
// import { Menu } from "antd";
// import { Link } from "react-router-dom";
import DownArrow from "../../assets/images/down-arrow.svg";
import MessageIcon from "../../assets/images/messages.svg";
import Logo from "../../assets/images/ballogy-logo.svg";
import NotificationIcon from "../../assets/images/bell.svg";
import User from "../../assets/images/user.svg";
import Logout from "../../assets/images/logout.svg";
import CryptoJs from "crypto-js";
import { getDataNotification, jsonPostData } from "../../util/api-calls";
import { UserContext } from "../../context/user-context";
import NotificationList from "../notification-window/notification-window";
const HeaderWrapper = () => {
  const {
    selectedSport,
    postSelectedSport,
    sportsList,
    notificationData,
    setNotificationData,
    setNotificationCount,
    notificationCount,
  } = useContext(UserContext);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("");
  const [loading, setLoading] = useState(false);
  function getItem(label, key, children) {
    return {
      key,
      children,
      label,
    };
  }
  let url = window.location.pathname;
  let sportOptions = [];
  for (let i = 0; i < sportsList.length; i++) {
    sportOptions.push({
      value: sportsList[i].id,
      label: sportsList[i].name,
    });
  }
  const logout = () => {
    let tokenData = "";
    let decryptStr = "";
    decryptStr = CryptoJs.AES.decrypt(
      localStorage.getItem("u_info"),
      "sec"
    ).toString(CryptoJs.enc.Utf8);
    if (decryptStr !== "") {
      tokenData = JSON.parse(decryptStr);
    }
    const refresh_token = tokenData.refresh_token;
    let refreshToken = { refresh_token: refresh_token };
    jsonPostData(`user/logout`, refreshToken)
      .then((res) => {
        localStorage.removeItem("u_info");
        localStorage.removeItem("u_id");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  React.useEffect(() => {
    if(url.includes("settings")){
      setActiveTab("")
    }else{
    if (url.includes("groups")) {
      setActiveTab("groups");
    } else if (url.includes("users")) {
      setActiveTab("users");
    } else if (url.includes("tasks")) {
      setActiveTab("tasks");
    } else if (url.includes("submissions")) {
      setActiveTab("submissions");
    } else if (url.includes("rotator")) {
      setActiveTab("rotator");
    } else if (url.includes("workouts")) {
      setActiveTab("workouts");
    } else if (url.includes("purchases")) {
      setActiveTab("purchases");
    } else {
      // setActiveTab("dashboard");
      setActiveTab("users");
    }
  }
  }, [url]);
  const items = [
    {
      key: "1",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => navigate("./settings")}
        >
          Account Settings
        </a>
      ),
    },
  ];
  const getNotifications = (list) => {
    let url = "";
    if (list === true) {
      setLoading(true);
      url = `admin/notification`;
    } else {
      url = `admin/notification?unread_count=1`;
    }
    getDataNotification(url)
      .then((res) => {
        setNotificationCount(res.data?.total_unread);
        if(list){
        setNotificationData(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const notificationItems = [
    {
      key: "1",
      label: <NotificationList loading={loading} getNotifications={getNotifications}/>,
    },
  ];
  const openNotificationList = (e) => {
    if (e === true) {
      getNotifications(e);
    }
  };
  const centerMenu = [
    // getItem(<Link to="/home">Dashboard</Link>, "dashboard", null),
    getItem(<Link to="/home/users">Users</Link>, "users", null),
    getItem(<Link to="/home/groups">Groups</Link>, "groups", null),
    getItem(<Link to="/home/tasks">Tasks</Link>, "tasks", null),
    getItem(
      <Link to="/home/submissions">Submissions</Link>,
      "submissions",
      null
    ),
    getItem(<Link to="/home/workouts">Workouts</Link>, "workouts", null),
    getItem(<Link to="/home/home-rotator">Home Rotator</Link>, "rotator", null),
    // getItem(<Link to="/home/purchases">Purchases</Link>, "purchases", null),
  ];
  const rightMenu = [
    getItem(<img src={MessageIcon} />, "message", null),
    getItem(
      <Badge
        count={notificationCount}
        style={{ backgroundColor: "#ffc53d", color: "#231f20" }}
        id="notification-dropdown"
      >
        <Dropdown
          menu={{
            items: notificationItems,
          }}
          onOpenChange={(e) => openNotificationList(e)}
          trigger={["click"]}
          getPopupContainer={() =>
            document.getElementById("notification-dropdown")
          }
          rootClassName="notification-dropdown"
          style={{ maxWidth: 400, width: 400 }}
        >
          <img src={NotificationIcon} />
        </Dropdown>
      </Badge>,
      "notification",
      null
    ),
    getItem(
      <Dropdown
        overlayClassName="header-dropdown-menu"
        className="header-dropdown"
        placement="bottomm"
        menu={{
          items,
        }}
      >
        <a onClick={(e) => e.preventDefault()}>
          <Space>
            <img
              src={User}
              className={
                selectedSport === 1 ? "orange-theme-icon" : "blue-theme-icon"
              }
              style={{ borderRadius: 6 }}
            />
          </Space>
        </a>
      </Dropdown>,
      "user",
      null
    ),
    getItem(<img src={Logout} onClick={logout} />, "logout", null),
  ];
  const changeMenu = (e) => {
    let key = e.key;
    if(key.includes("settings")){
      setActiveTab("")
    }else{
    if (key.includes("groups")) {
      getNotifications();
      setActiveTab("groups");
    } else if (key.includes("users")) {
      setActiveTab("users");
    } else if (key.includes("tasks")) {
      setActiveTab("tasks");
    } else if (key.includes("submissions")) {
      getNotifications();
      setActiveTab("submissions");
    } else if (key.includes("rotator")) {
      setActiveTab("rotator");
    } else if (key.includes("workouts")) {
      setActiveTab("workouts");
    } else if (key.includes("purchases")) {
      setActiveTab("purchases");
    } else {
      // setActiveTab("dashboard");
      setActiveTab("users");
    }
  }
  };
  // useEffect(() => {
  //   const intervalCall = setInterval(() => {
  //     getNotifications();
  //   }, 5000);
  //   return () => {
  //     clearInterval(intervalCall);
  //   };
  // });
  useEffect(() => {
    getNotifications();
  }, []);
  return (
    <div
      className={
        "header-wrapper-main " +
        (selectedSport === 1 ? "orange-theme-header" : "blue-theme-header")
      }
    >
      <img src={Logo} />
      {selectedSport && (
        <Select
          placeholder="Select Group"
          optionFilterProp="children"
          onChange={postSelectedSport}
          suffixIcon={<img src={DownArrow} />}
          className="sport-select"
          popupClassName={
            "custom-action-select-dropdown " +
            (selectedSport === 1 ? "orange-theme-select" : "blue-theme-select")
          }
          options={sportOptions}
          defaultActiveFirstOption
          defaultValue={selectedSport}
        />
      )}
      <Menu
        theme="light"
        mode="horizontal"
        activeKey={activeTab}
        items={centerMenu}
        className="center-menu"
        onClick={changeMenu}
        style={{ width: "100%" }}
      />
      <Menu
        theme="light"
        mode="horizontal"
        items={rightMenu}
        className="right-menu"
      />
    </div>
  );
};
export default HeaderWrapper;
