import React, { useEffect, useState } from "react";
import { getData, getDataTraining, jsonPostData } from "../util/api-calls";
import {
  getDataCoach,
  jsonPostDataCoach,
  getDataTrainingCoach,
} from "../util/coach-api-calls";
export const UserContext = React.createContext({});
export const UserContextProvider = ({ children = {} }) => {
  const [userAccessToken, setUserAccessToken] = React.useState(null);
  const [user, setUser] = React.useState(null);
  const [accountDetail, setAccountDetail] = useState({});
  const [selectedSport, setSelectedSport] = useState();
  const [darkButtonBackground, setDarkButtonBackground] = useState("");
  const [lightButtonBackground, setLightButtonBackground] = useState("");
  const [sportsList, setSportsList] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [color, setColor] = useState("");
  const [border, setBorder] = useState("");
  const [notificationData, setNotificationData] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [selectedNotification, setSelectedNotification] = useState();
  const [notificationProfileId, setNotificationProfileId] = useState("");
  let user_id = localStorage.getItem("u_id");
  let coach_id = localStorage.getItem("profile_id");
  let coach_ifo = localStorage.getItem("coach_info");
  const getAccountDetails = () => {
    getData(`admin/user/account/${user_id}`)
      .then((res) => {
        setAccountDetail(res.data);
        if (res.data?.profile?.selected_sport.name === "") {
          setSelectedSport(1);
        } else {
          setSelectedSport(res.data.profile?.selected_sport?.id);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllTags = (category) => {
    let url = "";
    if (window.location.href.includes("home")) {
      url = getDataTraining(
        `sport/${selectedSport ? selectedSport : 1}/tags?category=${category}`
      );
    } else {
      url = getDataTrainingCoach(
        `sport/${selectedSport ? selectedSport : 1}/tags?category=${category}`
      );
    }
    url
      .then((res) => {
        setAllTags(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCoachAccountDetails = () => {
    getDataCoach(`user/profile/${coach_id}`)
      .then((res) => {
        // console.log(res)
        if (res.data?.selected_sport === "") {
          setSelectedSport(1);
        } else {
          setSelectedSport(res.data?.selected_sport?.id);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllSports = () => {
    let url = "";
    if (window.location.href.includes("home")) {
      url = getData(`sport`);
    } else {
      url = getDataCoach(`sport`);
    }
    url
      .then((res) => {
        setSportsList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (
      window.location.href.includes("ballogy-team-training") ||
      window.location.href.includes("home")
    ) {
      getAllSports();
    }
  }, []);
  const postSelectedSport = (id) => {
    let url = "";
    if (window.location.href.includes("home")) {
      url = jsonPostData(`user/sports/switch`, { sport_id: id });
    } else {
      url = jsonPostDataCoach(`user/sports/switch`, { sport_id: id });
    }
    url
      .then((res) => {
        window.location.reload();
        if (window.location.href.includes("home")) {
          window.location.href = "/home/users";
        } else {
          window.location.href = "/coach/ballogy-team-training";
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (selectedSport === 1 || selectedSport === undefined) {
      setDarkButtonBackground("#F15A29");
      setLightButtonBackground("#FFF1ED");
      setColor("#F15A29");
      setBorder("#F15A29");
    } else {
      setDarkButtonBackground("#4EA7F8");
      setLightButtonBackground("#EDF6FE");
      setColor("#4EA7F8");
      setBorder("#4EA7F8");
    }
    if (user_id !== null && window.location.href.includes("home")) {
      getAccountDetails();
      // getAllTags();
    } else if (coach_id !== null && coach_ifo !== null) {
      getCoachAccountDetails();
      // getAllTags();
    }
  }, [selectedSport]);
  const defaultContext = {
    userAccessToken,
    setUserAccessToken,
    setUser,
    accountDetail,
    selectedSport,
    darkButtonBackground,
    color,
    border,
    lightButtonBackground,
    sportsList,
    postSelectedSport,
    allTags,
    notificationData,
    setNotificationData,
    notificationCount,
    setNotificationCount,
    selectedNotification,
    setSelectedNotification,
    notificationProfileId,
    setNotificationProfileId,
    getAllTags,
  };

  return (
    <UserContext.Provider value={defaultContext}>
      {children}
    </UserContext.Provider>
  );
};
