import React, { useContext, useEffect, useState } from "react";
import {
  Input,
  Row,
  Col,
  Modal,
  Skeleton,
  Checkbox,
  Button,
  Space,
  Spin,
  message,
  Form,
} from "antd";
import Search from "../../../assets/images/search.svg";
import Avatar from "react-avatar";
import "./owners-members.scss";
import { MemberContext } from "../../../coach-context/group-context/members-context";
import InfiniteScroll from "react-infinite-scroll-component";
import { UserContext } from "../../../context/user-context";
const MembersModal = ({ memberModal, setMemberModal, generalDetail }) => {
  const { color, lightButtonBackground, selectedSport } =
    useContext(UserContext);
  const {
    allMembers,
    totalMembers,
    listAllMembers,
    hasMore,
    setAllMembers,
    imageLoaded,
    setImageLoaded,
    addMembers,
    selectedTab,
    isCheck,
    setIsCheck,
    setPageNumber,
    setSearch,
    inviteMembers,
    membersData,
    setTotalMembers,
    setHasMore,
    setTrialModal,
    // contextHolder
  } = useContext(MemberContext);
  const [form] = Form.useForm();
  const [selectedInput, setSelectedInput] = useState(
    "Search owners by name..."
  );
  const [isCheckAll, setIsCheckAll] = useState(false);
  const handleCancel = () => {
    setTotalMembers(0);
    form.resetFields();
    setAllMembers([]);
    setAllMembers((allMembers) => []);
    setSearch("");
    setHasMore(false);
    setPageNumber(1);
    setMemberModal(false);
  };
  const handleClick = (e, index) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    for (let i = 0; i < allMembers.length; i++) {
      setIsCheck((prevMembers) => [...prevMembers, allMembers[i].id]);
    }
    if (isCheckAll) {
      setIsCheck([]);
    }
  };
  const searchMembers = (e) => {
    if (e.target.value.length >= 2) {
      setPageNumber(1);
      setAllMembers([]);
      setSearch(e.target.value);
    } else if (e.target.value.length === 0) {
      setPageNumber(1);
      setAllMembers((allMembers) => []);
      setSearch("");
    }
  };
  // const inviteMembers = (e,access_code) =>{
  //   //   setPendingList((pendingList)=>[])
  //   //   let data ={
  //   //     "group_id": params.id,
  //   //     "access_code": access_code,
  //   //     "profile_id": e.id
  //   // }
  //   //   jsonPostData(`coach/group/invite`,data).then((res)=>{
  //   //     setAllMembers((allMembers)=>[])
  //   //     setPageNumber(1)
  //   //     listAllMembers(1);
  //   api.info({
  //     message: `Notification`,
  //     description:
  //       'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
  //     placement : "top"
  //   });
  // }
  useEffect(() => {
    if (selectedTab === "1") {
      setSelectedInput("Search owners by name...");
    } else {
      setSelectedInput("Search members by name...");
    }
  }, [selectedTab]);
  const sendInvites = (item) => {
    if (
      membersData?.members_limit ===
        membersData?.members_pending + membersData?.members_joined &&
      selectedTab !== "1"
    ) {
      message.error({
        content: "Members limit exceeded",
        duration: 1,
      });
    } else {
      inviteMembers(
        item,
        selectedTab === "1"
          ? generalDetail.owner_code
          : generalDetail.member_code
      );
      setAllMembers([]);
      setAllMembers((allMembers) => []);
    }
  };
  return (
    <Modal
      wrapClassName={
        "add-members-modal " +
        (selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal")
      }
      open={memberModal}
      onOk={() => setMemberModal(false)}
      onCancel={handleCancel}
      // okText={"Finish Adding"}
      okText={"Close"}
      width={"937px"}
    >
      {/* {contextHolder} */}
      <div className="add-members-main">
        <h1>Invite Group {selectedTab === "1" ? " Owners" : " Members"}</h1>
        <br />
        <div style={{ display: "flex" }}>
          <Form form={form}>
            <Form.Item name="search">
              <Input
                defaultValue={""}
                addonBefore={<img src={Search} />}
                placeholder={selectedInput}
                inputProps={{
                  "aria-label": "description",
                }}
                className="search-input"
                onChange={searchMembers}
                style={{ width: 413 }}
              />
            </Form.Item>
          </Form>
          <Button
            className="red-base-btn"
            style={{
              color: color,
              background: lightButtonBackground,
              marginLeft: "auto",
              width: "auto",
            }}
            onClick={() => {
              setTrialModal(true);
              setMemberModal(false);
            }}
          >
            Trial Period Code
          </Button>
        </div>

        <div className="members-list">
          {membersData && (
            <div style={{ display: "flex", marginBottom: "37px" }}>
              {/* {selectedTab === "2" && (
              <Checkbox
                type="checkbox"
                name="selectAll"
                id="selectAll"
                className="custom-checkbox"
                onChange={handleSelectAll}
                style={{ marginRight: 16 }}
              />
            )} */}
              {selectedTab === "1" ? (
                <h2>
                  Invite (
                  {membersData?.members_pending + membersData?.members_joined})
                </h2>
              ) : (
                <h2>
                  Invite (
                  {membersData?.members_pending + membersData?.members_joined}{" "}
                  Used |{" "}
                  {membersData?.members_limit -
                    (membersData?.members_pending +
                      membersData?.members_joined)}{" "}
                  Remaining)
                  {/* {selectedTab === "1" ? "Owners " : "Members "}({totalMembers}) */}
                </h2>
              )}
            </div>
          )}
          <InfiniteScroll
            dataLength={allMembers.length}
            next={listAllMembers}
            height={400}
            hasMore={hasMore}
            loader={<h4>Loading...</h4>}
          >
            {allMembers.map((item, index) => (
              <Row className="owner-row" key={index}>
                <Col span={21} style={{ display: "flex" }}>
                  {/* <Checkbox
                    key={index}
                    type="checkbox"
                    name={item.first_name}
                    id={item.id}
                    className="custom-checkbox"
                    onChange={(e) => handleClick(e, index)}
                    style={{ marginRight: 26 }}
                    checked={isCheck.includes(item.id)}
                  /> */}
                  {item.avatar !== null && imageLoaded === "" && (
                    <Skeleton.Image active={true} className="user-skeleton" />
                  )}
                  {!item.avatar ? (
                    <Avatar
                      name={item.first_name + " " + item.last_name}
                      color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
                      className="user-avatar"
                    />
                  ) : (
                    <img
                      src={item.avatar}
                      style={
                        imageLoaded === ""
                          ? { display: "none" }
                          : { display: "block" }
                      }
                      onLoad={() => setImageLoaded("loaded")}
                    ></img>
                  )}
                  <div className="name-div">
                    <h3 className="user-name">
                      {item.first_name + " " + item.last_name}
                    </h3>
                    <h4 className="user-handle">@{item.username}</h4>
                  </div>
                </Col>
                <Col span={3} style={{ textAlign: "end" }}>
                  {item.already_invited === true &&
                  item.rejected_invite === false ? (
                    <Button className="gray-base-btn">Invite Sent</Button>
                  ) : item.already_joined === true ? (
                    <Button
                      className="red-base-btn"
                      style={{
                        color: color,
                        background: lightButtonBackground,
                      }}
                    >
                      Joined
                    </Button>
                  ) : item.rejected_invite === true && item.already_invited ? (
                    <>
                      <Button
                        className="resend-invite"
                        onClick={() =>
                          inviteMembers(
                            item,
                            selectedTab === "1"
                              ? generalDetail.owner_code
                              : generalDetail.member_code
                          )
                        }
                      >
                        Resend Invite
                      </Button>
                      <Button
                        className="red-base-btn"
                        style={{
                          color: color,
                          background: lightButtonBackground,
                        }}
                      >
                        Rejected
                      </Button>
                    </>
                  ) : (
                    <Button
                      className="red-base-btn"
                      onClick={() => sendInvites(item)}
                      style={{
                        color: color,
                        background: lightButtonBackground,
                      }}
                    >
                      Invite
                    </Button>
                  )}
                </Col>
                {/* <Col span={4} style={{ textAlign: "end" }}>
                  <Select
                    placeholder="Select"
                    optionFilterProp="children"
                    popupClassName="member-select-dropdown"
                    onChange={(e) => onChange(e, item.id)}
                    className="member-select"
                    suffixIcon={<img src={DownArrow} />}
                    options={[
                      {
                        value: "owner",
                        label: "Owner",
                      },
                      {
                        value: "member",
                        label: "Member",
                      },
                    ]}
                  />
                </Col> */}
                <hr />
              </Row>
            ))}
          </InfiniteScroll>
        </div>
      </div>
    </Modal>
  );
};

export default MembersModal;
