import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Modal,
  Input,
  Radio,
  Form,
  Upload,
  Button,
  Card,
  Image,
  Progress,
  Checkbox,
  Select,
} from "antd";
import * as tus from "tus-js-client";
import axios from "axios";
// import Player from "../../Player/Player";
import { CloseOutlined } from "@ant-design/icons";
import QuestionMark from "../../../assets/images/question_mark.svg";
import DownArrow from "../../../assets/images/down-arrow.svg";
import {
  EditTaskContext,
  EditTaskContextProvider,
} from "../../../coach-context/edit-task-context";
import UpdateTaskModal from "./update-task-modal";
import ReactPlayer from "react-player";
import Play from "../../../assets/images/play.svg";
import { UserContext } from "../../../context/user-context";
const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const { Dragger } = Upload;
const { TextArea } = Input;
let modification = false;
let limit;
const EditTaskPage = ({
  newTaskArray,
  selectedTask,
  setNewTaskArray,
  setExistingTaskModal,
  editTaskModal,
  setEditTaskModal,
  generalDetail,
}) => {
  const { darkButtonBackground, selectedSport, allTags, getAllTags } =
    useContext(UserContext);
  const wrapperRef = useRef();
  const {
    createNewTask,
    detailVideoId,
    setDetailVideoId,
    value,
    setValue,
    setTitle,
    level,
    setLevel,
    taskStatus,
    setTaskStatus,
    timeLimit,
    setTimeLimit,
    postUpdateTask,
    setEmbeddedUrl,
    embeddedUrl,
    setTags,
    mediaType,
    setMediaType,
    form,
    detailImgSrc,
    setDetailImgSrc,
    detailImageUrl,
    setDetailImageUrl,
    uploadError,
    timeLimitSeconds,
    setTimeLimitSeconds,
    tags,
    params,
    taskType,
    setTaskType,
    videoRequired,
    setVideoRequired,
  } = useContext(EditTaskContext);
  const [videoUrl, setVideoUrl] = useState();
  const [percentage, setPercentage] = useState(0);
  const [videoPreview, setVideoPreview] = useState(false);
  const [detailImagePreview, setDetailImgaePreview] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [imgSrc, setImgSrc] = useState();
  const [imagePreview, setImagePreview] = useState(false);
  const [detailMediaError, setDetailMediaError] = useState(false);
  const [uploadingStart, setUploadingStart] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [videoSrc, setVideoSrc] = useState("");
  // const [taskType, setTaskType] = useState(false);
  const [updateTaskModal, setUpdateTaskModal] = useState(false);
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  useEffect(() => {
    getAllTags(3);
  }, []);
  const onChangeTags = (checkedValues) => {
    setTags(checkedValues);
  };
  const tagOptions = [];
  for (let i = 0; i < allTags.length; i++) {
    tagOptions.push({
      value: allTags[i].tag.toLowerCase(),
      label: allTags[i].tag,
    });
  }
  async function handleBeforeUpload(file) {
    setDetailVideoId("");
    setDetailImageUrl();
    if (file.type === "image/png" || file.type === "image/jpeg") {
      setVideoUrl();
      setDetailImageUrl(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setDetailImgSrc(reader.result);
        modification = true;
      };
      setVideoPreview(true);
      setDetailMediaError(false);
      reader.readAsDataURL(file);
      return false;
    } else {
      setDetailImgSrc();
      setUploadingStart(true);
      setVideoUrl(file);
      setVideoPreview(true);
      setDetailMediaError(false);
      modification = true;
      await uploadVideo(file, handleSuccessfullBytes, uploadCompleteDone);
    }
  }
  const uploadVideo = async (
    file,
    handleSuccessfullBytes,
    uploadCompleteDone
  ) => {
    console.log("LgssAAAA", file);
    const uploadLink = await createVideo(file.size);
    let bytes = 0;
    const upload = new tus.Upload(file, {
      uploadUrl: uploadLink?.upload?.upload_link,
      retryDelays: [1000, 3000, 5000],
      uploadSize: file.size,
      metadata: {
        filename: file.name,
        filetype: file.type,
      },
      onShouldRetry: function (err, retryAttempt, options) {
        console.log("onShouldRetry Error", err);
        console.log(" onShouldRetry Request", err.originalRequest);
        console.log(" onShouldRetry Response", err.originalResponse);
        var status = err.originalResponse
          ? err.originalResponse.getStatus()
          : 0;
        if (status === 403) {
          return false;
        }
        return true;
      },
      onChunkComplete: (status) => {
        console.log(`upload failed:`, status);
      },
      onError: (error) => {
        console.log("Request", error.originalRequest);
        console.log("Response", error.originalResponse);
        console.log(`upload failed:`, error);
      },
      onProgress: (uploadedBytes, totalBytes) => {
        bytes = uploadedBytes;
        handleSuccessfullBytes(uploadedBytes, totalBytes);
      },
      onSuccess: () => {
        console.log(uploadLink);
        uploadCompleteDone(true, uploadLink?.uri, uploadLink.link);
        console.log("Upload URL:", upload.url);
      },
    });
    upload.start();
  };
  const handleSuccessfullBytes = (bytes, totalBytes) => {
    const bytesInPercentage = Math.round((bytes / totalBytes) * 100);
    setPercentage(bytesInPercentage);
  };
  const uploadCompleteDone = (isCompleted, detailVideoId, url) => {
    setVideoSrc(url);
    setVideoPreview(true);
    console.log("status", isCompleted);
    console.log("detailVideoId", detailVideoId);
    setUploadComplete(isCompleted);
    const video_id = detailVideoId.split("/");
    setDetailVideoId(video_id[2]);
    setUploadingStart(false);
  };
  const createVideo = async (fileSize) => {
    const paramss = {
      upload: {
        approach: "tus",
        size: fileSize,
      },
      name: "My Video",
    };
    const response = await axios.post(
      "https://api.vimeo.com/me/videos",
      paramss,
      {
        headers: {
          Accept: "application/vnd.vimeo.*+json;version=3.4",
          "Content-Type": "application/json",
          Authorization: `Bearer be82d2e853d829ed867903ec070a64f7`,
        },
      }
    );
    console.log("LogggggAAA", response);
    return response?.data;
  };
  const handleOk = () => {
    setEditTaskModal(false);
  };
  const handleCancel = () => {
    setEditTaskModal(false);
  };
  const onChangeMediaType = (e) => {
    setMediaType(e.target.value);
  };
  const resetDragger = () => {
    setVideoUrl();
    setDetailVideoId("");
    setDetailImageUrl();
    setVideoPreview(false);
    setDetailImgSrc();
  };
  const [editable, setEditable] = useState(false);
  React.useEffect(() => {
    if (selectedTask.group_id?.toString() === params.id) {
      setEditable(true);
    } else {
      setEditable(true);
      // setEditable(false);
    }
    if (selectedTask && Object.keys(selectedTask).length > 0) {
      form.setFieldsValue({
        task_title: selectedTask.title,
        instructions: selectedTask.instruction,
        task_type: selectedTask.score_based_test,
        video_required: selectedTask.media_required,
        time_limit: selectedTask.is_time_limit,
        task_status: selectedTask.is_public,
        level: selectedTask.skill_level,
        tags:
          Object.keys(selectedTask).length > 0 &&
          selectedTask.tags.toLowerCase().split(","),
        media_url: selectedTask.media_url,
      });
      setVideoRequired(selectedTask.media_required);
      if (selectedTask.media_image) {
        setImagePreview(true);
        setImgSrc(selectedTask.media_image);
      }
      if (selectedTask.media_type === "image") {
        setDetailImgSrc(selectedTask.image);
      } else if (selectedTask.media_type === "vimeo") {
        setDetailImgSrc();
        setVideoPreview(true);
      }
      if (selectedTask.media_type === "embedded") {
        setEmbeddedUrl(selectedTask.media_url);
      }
      setTimeLimitSeconds(selectedTask.time_limit_seconds);
      limit = convertSeconds(selectedTask.time_limit_seconds);
      if (selectedTask.media_type === "embedded") {
        setMediaType("embedded");
      } else if (
        selectedTask.media_type === "image" ||
        selectedTask.media_type === "vimeo"
      ) {
        setMediaType("upload");
      } else {
        setMediaType();
      }
      setTaskStatus(selectedTask.is_public);
      setTimeLimit(selectedTask.is_time_limit);
      setTaskType(selectedTask.score_based_test);
      setLevel(selectedTask.skill_level);
    }
  }, [selectedTask, editTaskModal]);
  const onChangeTimeLimit = (e) => {
    setTimeLimitSeconds(e);
    limit = convertSeconds(e);
  };
  function areTagsEqual() {
    let selectedTags =
      Object.keys(selectedTask).length > 0 && selectedTask.tags.split(",");
    if (tags && tags.length > 0) {
      if (tags.length === selectedTags.length) {
        return tags.every((element) => {
          if (selectedTags.includes(element)) {
            return true;
          }
          return false;
        });
      }
      return false;
    }
  }
  const checkMediaType = () => {
    if (selectedTask.media_type !== null) {
      if (selectedTask.media_type === "image" && mediaType === "upload") {
        return true;
      } else if (
        selectedTask.media_type === "vimeo" &&
        mediaType === "upload"
      ) {
        return true;
      } else if (
        selectedTask.media_type === "embedded" &&
        mediaType === "embedded"
      ) {
        return true;
      }
      return false;
    }
  };
  const addExistingTask = (values) => {
    if (params.id === selectedTask.group_id?.toString()) {
      // setUpdateTaskModal(true)
      let selectedMedia =
        selectedTask.media_type === "embedded" ? "embedded" : "upload";
      if (
        values.task_title !== selectedTask.title ||
        (values.instructions &&
          values.instructions !== selectedTask.instruction) ||
        values.task_type !== selectedTask.score_based_test ||
        values.video_required !== selectedTask.media_required ||
        values.task_status !== selectedTask.is_public ||
        values.level !== selectedTask.skill_level ||
        areTagsEqual() === false ||
        (mediaType === "embedded" && embeddedUrl !== selectedTask.media_url)
      ) {
        setUpdateTaskModal(true);
      } else if (
        videoRequired === true &&
        values.time_limit === true &&
        selectedTask.time_limit_seconds !== timeLimitSeconds
      ) {
        setUpdateTaskModal(true);
      } else if (mediaType !== undefined && selectedMedia !== mediaType) {
        setUpdateTaskModal(true);
      } else {
        setEditTaskModal(false);
        setExistingTaskModal(false);
        postUpdateTask(
          selectedTask,
          setNewTaskArray,
          newTaskArray,
          setEditTaskModal,
          generalDetail?.is_curriculum_group
        );
      }
    } else {
      setEditTaskModal(false);
      setExistingTaskModal(false);
      postUpdateTask(
        selectedTask,
        setNewTaskArray,
        newTaskArray,
        setEditTaskModal,
        generalDetail?.is_curriculum_group
      );
    }
  };
  const changeTaskType = (e) => {
    setTaskType(e.target.value);
  };
  const convertSeconds = (sec) => {
    const minutes = Math.floor((sec % 3600) / 60);
    const seconds = sec % 60;
    if (minutes > 0 && seconds > 0) {
      return `${minutes} min  ${seconds} sec`;
    } else if (minutes > 0 && seconds <= 0) {
      return `${minutes} min`;
    } else if (seconds > 0) {
      return `${seconds} sec`;
    }
  };
  return (
    <Modal
      className="task-modal"
      wrapClassName={
        selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal"
      }
      open={editTaskModal}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"Add & Next"}
      cancelText={"Cancel"}
      width={"699px"}
      footer={false}
    >
      <h1>Add Existing Task</h1>
      {/* <p>Create an new task</p> */}
      {/* <label htmlFor="category">Task Title</label>
      <br />
      <Input className="task-name" /> */}
      <Form
        form={form}
        name="new_task"
        className="new-task-form"
        onFinish={addExistingTask}
      >
        <Form.Item
          {...formItemLayout}
          name="task_title"
          className="task-title"
          label="Task Title"
        >
          <Input
            className={"task-name " + (!editable ? "readonly" : "")}
            onChange={(e) => setTitle(e.target.value)}
            disabled={!editable}
          />
        </Form.Item>{" "}
        {mediaType !== undefined && (
          <>
            <Form.Item
              {...formItemLayout}
              label="Add Media"
              className="media-div"
            >
              <p className="instructions">
                Upload or link a photo or a video for instructions.
              </p>
              <Radio.Group
                onChange={onChangeMediaType}
                value={mediaType}
                className={"custom-radio " + (!editable ? "readonly" : "")}
                disabled={!editable}
              >
                <Radio value={"upload"}>Upload File</Radio>
                <Radio value={"embedded"}>Add from URL</Radio>
              </Radio.Group>
              {mediaType === "upload" ? (
                <div
                  style={{ marginTop: "32px" }}
                  className={
                    videoPreview || detailImagePreview ? "uploader-div" : ""
                  }
                >
                  {/* <Dragger
              type="file"
              customRequest={dummyRequest}
              beforeUpload={handleBeforeUpload}
              showUploadList={false}
            >
              <p className="ant-upload-drag-icon">
                <img src={UploadFile} alt="" />
              </p>
              <div style={{ textAlign: "left", padding: "12px 0" }}>
                {" "}
                <p className="ant-upload-text">
                  Drag and Drop or
                  <span>Browse</span>
                </p>
                <p className="ant-upload-hint">
                  Upload photo or video (16:9 ratio)
                </p>
              </div>
            </Dragger> */}
                  {/* {videoPreview && ( */}
                  <Card hoverable className="media-card">
                    <>
                      {detailVideoId && (
                        <span className="top-cancel-btn">
                          <CloseOutlined onClick={resetDragger} />
                        </span>
                      )}
                      {detailImgSrc ? (
                        <div
                          style={{
                            overflow: "hidden",
                            height: "15Private0px",
                            borderRadius: 8,
                          }}
                        >
                          <Image
                            style={{
                              width: "100%",
                              height: "100%",
                            }}
                            className="img"
                            src={detailImgSrc}
                          />
                        </div>
                      ) : selectedTask.media_type === "vimeo" && !videoUrl ? (
                        <div id="video-container">
                          <ReactPlayer
                            key={selectedTask.media_url}
                            url={selectedTask.media_url}
                            light={selectedTask.media_thumbnail}
                            playIcon={<img src={Play} />}
                            controls
                          />
                          {/* <Player
                      url={selectedTask.media_url}
                      percentage={100}
                      wrapperRef={wrapperRef}
                      fromUrl={true}
                      light={selectedTask.media_thumbnail}
                    /> */}
                        </div>
                      ) : (
                        <div id="video-container">
                          {percentage !== 100 && (
                            <div
                              className="progress-div"
                              style={{
                                zIndex: percentage !== "100" ? "1" : "0",
                              }}
                            >
                              {" "}
                              <Progress
                                percent={percentage}
                                status="active"
                                className="custom-progress-bar"
                              />
                            </div>
                          )}
                          {/* {videoUrl && (
                        <Player
                          url={URL.createObjectURL(videoUrl)}
                          percentage={percentage}
                          wrapperRef={wrapperRef}
                        />
                      )} */}
                        </div>
                      )}
                    </>
                    {/* <div onClick={handleFullscreen}>
        <img src={Expand} style={{ fontSize: "2rem", color: "white" }} />
      </div> */}
                    <div
                      className="name"
                      title={
                        videoUrl
                          ? videoUrl.name
                          : detailImageUrl && detailImageUrl.name
                      }
                    >
                      {videoUrl
                        ? videoUrl.name
                        : detailImageUrl && detailImageUrl.name}
                    </div>
                    {/* {!detailVideoId ?
                    <span style={{bottom:21}} className="expand-video"><CloseOutlined onClick={resetDragger}/></span> : 
                      <span onClick={handleFullscreen} className="expand-video">
        <img src={Expand} style={{ fontSize: "2rem", color: "white" }} />
      </span> 
                    } */}
                    {detailVideoId && (
                      <div className="size">
                        {videoUrl
                          ? (videoUrl && videoUrl.size / 1024 ** 2).toFixed(1) +
                            "MB"
                          : (
                              detailImageUrl && detailImageUrl.size / 1000
                            ).toFixed(1) + "KB"}
                      </div>
                    )}
                  </Card>
                  {/* )} */}
                </div>
              ) : (
                <Form.Item
                  {...formItemLayout}
                  style={{ marginTop: "32px" }}
                  name="media_url"
                  label="URL"
                  rules={[
                    {
                      required: false,
                    },
                    {
                      type: "url",
                      message: "Please enter valid url",
                    },
                  ]}
                >
                  <Input
                    placeholder="Please enter url"
                    className={!editable ? "readonly" : ""}
                    disabled={!editable}
                    onChange={(e) => setEmbeddedUrl(e.target.value)}
                    value={embeddedUrl}
                  />
                </Form.Item>
              )}
            </Form.Item>
          </>
        )}
        {/* {mediaType === "upload" && ( */}
        <>
          <Form.Item
            {...formItemLayout}
            name="instructions"
            className="task-title"
            label="Instructions"
          >
            <TextArea
              value={value}
              onChange={(e) => setValue(e.target.value)}
              className={!editable ? "readonly" : ""}
              disabled={!editable}
              placeholder="Provide a short description or instructions for your group members to follow..."
              autoSize={{
                minRows: 5,
                maxRows: 7,
              }}
            />
          </Form.Item>
          {uploadError === true && (
            <span className="error-message">
              Please upload media or enter instructions.
            </span>
          )}
        </>
        {/* <Form.Item
          {...formItemLayout}
          name="dragger"
          className="media-div"
        >
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <img src={UploadFile} alt="" />
            </p>
            <div style={{ textAlign: "left", padding: "12px 0" }}>
              {" "}
              <p className="ant-upload-text">
                Drag and Drop or
                <span>Browse</span>
              </p>
              <p className="ant-upload-hint">
                Upload photo or video (16:9 ratio)
              </p>
            </div>
          </Dragger>
        </Form.Item> */}
        <Form.Item
          {...formItemLayout}
          name="task_type"
          label="Task Type"
          className="media-div"
          style={{ marginTop: 8 }}
          tooltip={{
            title: (
              <div className="pop-up">
                <h3>Task Type</h3>
                <p>
                  <span>Training Task: </span>group members receive credit for
                  completing the task.
                </p>
                <p>
                  <span>Scored Task: </span>group members receive or provide
                  custom point totals for each submission.
                </p>
              </div>
            ),
            icon: <img src={QuestionMark} style={{ marginInlineStart: 8 }} />,
            color: "#fff",
            className:
              selectedSport === 1 ? "orange-theme-icon" : "blue-theme-icon",
            overlayClassName: "pop-up",
          }}
        >
          <Radio.Group
            onChange={(e) => setTaskType(e.target.value)}
            value={taskType}
            className={"custom-radio " + (!editable ? "readonly" : "")}
            disabled={!editable}
          >
            <Radio value={false}>Training Task</Radio>
            <Radio value={true} style={{ marginLeft: 16 }}>
              Scored Task
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="video_required"
          label="Video Required?"
          className="media-div"
          style={{ marginTop: 8 }}
          tooltip={{
            title: (
              <div className="pop-up">
                <h3>Video Required?</h3>
                <p>
                  <span>Yes: </span>group members record and upload their video
                  submissions. For Scored Tasks, group owners watch these videos
                  and provide custom points totals. For Training Tasks,
                  completion credit is granted upon submission upload, but can
                  be revoked(invalidated) by the group owner.
                </p>
                <p>
                  <span>No: </span> group members self-report their completion
                  (Training Task) or provide their own score (Scored Task)
                  without an accompanying video.{" "}
                </p>
              </div>
            ),
            icon: <img src={QuestionMark} style={{ marginInlineStart: 8 }} />,
            color: "#fff",
            className:
              selectedSport === 1 ? "orange-theme-icon" : "blue-theme-icon",
            overlayClassName: "pop-up",
          }}
        >
          <Radio.Group
            onChange={(e) => setVideoRequired(e.target.value)}
            value={videoRequired}
            className={"custom-radio " + (!editable ? "readonly" : "")}
            disabled={!editable}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false} style={{ marginLeft: 16 }}>
              No
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="time_limit"
          label="Time Limit"
          className={
            "time-limit-div " + (videoRequired === false ? "disable-label" : "")
          }
          tooltip={{
            title: (
              <div className="pop-up">
                <h3>Video Time Limit</h3>
                <p>
                  <span>Record until completed: </span>the app records until the
                  group member manually stops recording (maximum 15 minutes).
                </p>
                <p>
                  <span>Stop recording after: </span>the app stops recording at
                  the specified limit.
                </p>
              </div>
            ),
            icon: <img src={QuestionMark} style={{ marginInlineStart: 8 }} />,
            color: "#fff",
            className:
              selectedSport === 1 ? "orange-theme-icon" : "blue-theme-icon",
            overlayClassName: "pop-up",
          }}
        >
          <Radio.Group
            onChange={(e) => setTimeLimit(e.target.value)}
            value={timeLimit}
            className={"custom-radio " + (!editable ? "readonly" : "")}
            disabled={videoRequired === false || !editable}
          >
            <Radio value={false}>
              No time limit-record until task is completed
            </Radio>
            {/* <div style={{display:"flex"}}>
            <Radio value={true}>Stop recording after
</Radio>
<Select
                    placeholder="Select"
                    style={{ marginTop: 8, width: 140, height: 30 }}
                    suffixIcon={<imPrivateg src={DownArrow} />}
                    size={"large"}
                    className="select-input"
                    options={options}
                />   
                </div>          */}
            <div style={{ display: "flex" }}>
              <Radio value={true} style={{ width: 180 }}>
                Stop recording after
              </Radio>
              {/* {bsaCredit === 2 ? ( */}
              <Select
                placeholder="Select"
                optionFilterProp="children"
                popupClassName={
                  "custom-action-select-dropdown " +
                  (selectedSport === 1
                    ? "orange-theme-select"
                    : "blue-theme-select")
                }
                value={limit}
                style={{
                  marginTop: "8px",
                  width: 140,
                  height: 30,
                  zIndex: 9,
                }}
                onChange={onChangeTimeLimit}
                suffixIcon={<img src={DownArrow} />}
                size={"large"}
                options={[
                  {
                    label: "30sec",
                    value: "30",
                  },
                  {
                    label: "1min",
                    value: "60",
                  },
                  {
                    label: "1min 30sec",
                    value: "90",
                  },
                  {
                    label: "2min",
                    value: "120",
                  },
                  {
                    label: "2min 30sec",
                    value: "150",
                  },
                  {
                    label: "3min",
                    value: "180",
                  },
                  {
                    label: "3min 30sec",
                    value: "210",
                  },
                  {
                    label: "4min",
                    value: "240",
                  },
                  {
                    label: "4min 30sec",
                    value: "270",
                  },
                  {
                    label: "5min",
                    value: "300",
                  },
                  {
                    label: "5min 30sec",
                    value: "330",
                  },
                  {
                    label: "6min",
                    value: "360",
                  },
                  {
                    label: "6min 30sec",
                    value: "390",
                  },
                  {
                    label: "7min",
                    value: "420",
                  },
                  {
                    label: "7min 30sec",
                    value: "450",
                  },
                  {
                    label: "8min",
                    value: "480",
                  },
                  {
                    label: "8min 30sec",
                    value: "510",
                  },
                  {
                    label: "9min",
                    value: "540",
                  },
                  {
                    label: "9min 30sec",
                    value: "570",
                  },
                  {
                    label: "10min",
                    value: "600",
                  },
                  {
                    label: "10min 30sec",
                    value: "630",
                  },
                  {
                    label: "11min",
                    value: "660",
                  },
                  {
                    label: "11min 30sec",
                    value: "690",
                  },
                  {
                    label: "12min",
                    value: "720",
                  },
                  {
                    label: "12min 30sec",
                    value: "750",
                  },
                  {
                    label: "13min",
                    value: "780",
                  },
                  {
                    label: "13min 30sec",
                    value: "810",
                  },
                  {
                    label: "14min",
                    value: "840",
                  },
                  {
                    label: "14min 30sec",
                    value: "870",
                  },
                  {
                    label: "15min",
                    value: "900",
                  },
                ]}
                disabled={videoRequired === false || !editable}
                className={"select-input " + (!editable ? "readonly" : "")}
              />
            </div>
          </Radio.Group>
        </Form.Item>
        {/* <Form.Item
          {...formItemLayout}
          name="task_status"
          label="Task Library Status"
          className="time-limit-div"
          tooltip={{
            title: (
              <div className="pop-up">
                <h3>Task Library Status</h3>
                <p>
                  <span>Public: </span>your task is shared with other coaches in
                  the Ballogy Workout Library.
                </p>
                <p>
                  <span>Private: </span>your task can only be used within your
                  Team Training group.
                </p>
              </div>
            ),
            icon: <img src={QuestionMark} style={{ marginInlineStart: 8 }} />,
            color: "#fff",
            className:selectedSport === 1 ? "orange-theme-icon" : "blue-theme-icon",
            overlayClassName: "pop-up",
          }}
        >
          <Radio.Group
            onChange={(e) => setTaskStatus(e.target.value)}
            value={taskStatus}
            className={"custom-radio " + (!editable ? "readonly" : "")}
            disabled={!editable}
          >
            <Radio value={true}>Public</Radio>
            <Radio value={false}>Private</Radio>
          </Radio.Group>
        </Form.Item> */}
        <Form.Item
          {...formItemLayout}
          name="level"
          label="Skill Level"
          className="media-div"
        >
          <Radio.Group
            onChange={(e) => setLevel(e.target.value)}
            value={level}
            className={"custom-radio " + (!editable ? "readonly" : "")}
            disabled={!editable}
          >
            <Radio value={"beginner"}>Beginners</Radio>
            <Radio value={"intermediate"}>Intermediate</Radio>
            <Radio value={"advanced"}>Advance</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          name="tags"
          label="Tagging"
          className="media-div"
        >
          <Checkbox.Group
            onChange={onChangeTags}
            value={value}
            className={"custom-checkbox " + (!editable ? "readonly" : "")}
            disabled={!editable}
            options={tagOptions}
          ></Checkbox.Group>
        </Form.Item>
        <Form.Item style={{ display: "flex", gap: 8 }}>
          <Button
            type="default"
            className="discard-btn"
            onClick={() => setEditTaskModal(false)}
          >
            Cancel
          </Button>
          <Button
            className="dark-base-btn"
            style={{ background: darkButtonBackground }}
            type="primary"
            htmlType="submit"
          >
            Select Task
          </Button>
        </Form.Item>
      </Form>
      {/* <TaskList /> */}
      {updateTaskModal === true && (
        <UpdateTaskModal
          updateTaskModal={updateTaskModal}
          setUpdateTaskModal={setUpdateTaskModal}
          editTaskModal={editTaskModal}
          setEditTaskModal={setEditTaskModal}
          selectedTask={selectedTask}
          setNewTaskArray={setNewTaskArray}
          newTaskArray={newTaskArray}
          setExistingTaskModal={setExistingTaskModal}
          curriculum_group={generalDetail?.is_curriculum_group}
        />
      )}
    </Modal>
  );
};
const EditTask = ({
  editTaskModal,
  setEditTaskModal,
  selectedTask,
  setNewTaskArray,
  setExistingTaskModal,
  newTaskArray,
  generalDetail,
}) => {
  return (
    <EditTaskContextProvider>
      <EditTaskPage
        setExistingTaskModal={setExistingTaskModal}
        editTaskModal={editTaskModal}
        setEditTaskModal={setEditTaskModal}
        s
        selectedTask={selectedTask}
        setNewTaskArray={setNewTaskArray}
        newTaskArray={newTaskArray}
        generalDetail={generalDetail}
      />
    </EditTaskContextProvider>
  );
};
export default EditTask;
