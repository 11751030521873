import { useEffect, useState, useContext, useRef } from "react";
import Logo from "../../assets/images/logo.svg";
import BigLogo from "../../assets/images/big-logo.svg";
import "./chat.scss";
import Plus from "../../assets/images/minus-squared.svg";
import ChatLogo from "../../assets/images/chatLogo.svg";
import Copy from "../../assets/images/copy.svg";
import Message from "../../assets/images/message-text.svg";
import { RollbackOutlined, CheckOutlined } from "@ant-design/icons";
import { Button, Col, Layout, Row, Input, Empty } from "antd";
import ReactMarkdown from "react-markdown";

import Avatar from "react-avatar";
import {
  ChatAIContext,
  ChatAIContextProvider,
} from "../../coach-context/chat-ai-context";
import { FloatButton } from "antd/es";
import { useNavigate } from "react-router-dom/dist";
import { UserContext } from "../../context/user-context";
import FreemiumModal from "../freemium-modal/freemium-modal";
const { Header, Content, Footer, Sider } = Layout;
const style = {
  background: "#f9f9f9",
  padding: "24px",
  border: "1px solid #D8D8E5",
  height: "98px",
  borderRadius: "6px",
  color: "#5e6278",
  textAlign: "left",
  display: "flex",
  alignItems: "center",
  fontWeight: "500",
  fontSize: "16px",
};
const ChatPage = () => {
  const { darkButtonBackground, selectedSport } = useContext(UserContext);
  const navigate = useNavigate();
  const {
    collapsed,
    setCollapsed,
    profileData,
    setChat,
    chatHistory,
    recentChat,
    colorBgContainer,
    borderRadiusLG,
    chat,
    questionsList,
    setMessageText,
    messageText,
    SubmitQuestion,
    setUserId,
    setSessionId,
    freemiumDetail,
    freemiumQuestions,
  } = useContext(ChatAIContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const lastItemRef = useRef(null); // Reference to the last item
  const [listIndex, setListIndex] = useState(undefined);
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    const intervalCall = setInterval(() => {
      if (copied === true) {
        setCopied(false);
      }
    }, 1000);
    return () => {
      clearInterval(intervalCall);
    };
  }, [copied]);
  useEffect(() => {
    setTimeout(() => {
      if (lastItemRef.current) {
        lastItemRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    }, 100);
  }, [chat]);
  return (
    <Layout
      style={{
        minHeight: "100vh",
      }}
      className="chat-bot"
    >
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        theme="light"
        width={360}
        trigger={null}
      >
        <div
          className="demo-logo-vertical"
          style={{ textAlign: "left", padding: "0 32px" }}
        >
          <img src={Logo} style={{ width: 120 }} />
        </div>

        <Button
          className="new-chat-button"
          icon={<img src={Plus} />}
          onClick={() => setChat((chat) => [])}
        >
          New Chat
        </Button>
        <div className="recent">Recent</div>
        <div className="list">
          {chatHistory?.length > 0 ? (
            chatHistory.map((item, index) => (
              <div
                className="chat-list"
                key={index}
                onClick={() => {
                  setMessageText("");
                  recentChat(item.userId, item.sessionId);
                  setUserId(item.userId);
                  setSessionId(item.sessionId);
                }}
                style={{ cursor: "pointer" }}
              >
                <img src={Message} />
                <div href="">{item.msg}</div>
                {/* <img src={Trash} /> */}
              </div>
            ))
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              style={{ marginBlock: 130 }}
            />
          )}
        </div>
        {/* <Button className="show-more" onClick={() => getListChatHistory()}>Show more</Button> */}
        <div className="user-name">
          <Avatar
            name={profileData?.first_name + " " + profileData.last_name}
            color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
            className="user-avatar"
            style={{ borderRadius: "50%" }}
          />
          <span style={{ marginLeft: 20 }}>
            {profileData?.first_name + " " + profileData.last_name}
          </span>
        </div>
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        />
        <Content
          ref={lastItemRef}
          style={{
            margin: "0 16px 50px",
          }}
        >
          <div
            style={{
              // padding: 24,
              minHeight: 360,
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
              height: "100%",
            }}
          >
            {chat?.length === 0 ? (
              <>
                <img src={BigLogo} />
                <h1>How can I help you today?</h1>
                <span>You can ask me anything related to Basketball.</span>
                <Row gutter={[16, 16]} style={{ margin: "30px 0" }}>
                  {questionsList?.length > 0 &&
                    questionsList.map((item, index) => (
                      <Col
                        className="gutter-row"
                        span={12}
                        key={index}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          freemiumDetail?.is_premium === false &&
                          freemiumDetail?.can_create_conversation === false
                            ? setIsModalOpen(true)
                            : setMessageText(item.question);
                        }}
                      >
                        <div style={style}>{item.question}</div>
                      </Col>
                    ))}
                </Row>
              </>
            ) : (
              chat &&
              chat.map((item, index) => {
                return item.type === "Human" ? (
                  <div
                    className="comment-box"
                    style={{
                      textAlign: "left",
                      display: "flex",
                    }}
                    onMouseEnter={() =>
                      listIndex === index
                        ? setListIndex(undefined)
                        : setListIndex(index)
                    }
                    key={item._id}
                    ref={index === item.length - 1 ? lastItemRef : null}
                  >
                    <Avatar
                      name={item.type}
                      color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
                      className="user-avatar"
                      style={{ borderRadius: "50%" }}
                    />
                    <div style={{ marginLeft: 20 }}>
                      <div
                        className="name-div"
                        style={{ position: "relative" }}
                      >
                        <h3>You</h3>
                        <h4 className="comment">
                          <ReactMarkdown>{item?.msg}</ReactMarkdown>
                        </h4>
                        <img
                          src={Copy}
                          key={item._id}
                          onClick={() => {
                            navigator.clipboard.writeText(item?.msg);
                            setCopied(true);
                          }}
                          style={{
                            marginBottom: "-10px",
                            cursor: "pointer",
                            visibility:
                              index === listIndex && copied === false
                                ? "visible"
                                : "hidden",
                          }}
                        />
                        <CheckOutlined
                          className="check-icon"
                          key={index}
                          style={{
                            visibility:
                              copied === true && index === listIndex
                                ? "visible"
                                : "hidden",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  item?.length !== 0 && (
                    <div
                      className="comment-box"
                      style={{ textAlign: "left", display: "flex" }}
                      key={item._id}
                      onMouseEnter={() =>
                        listIndex === index
                          ? setListIndex(undefined)
                          : setListIndex(index)
                      }
                      ref={index === item.length - 1 ? lastItemRef : null}
                    >
                      <div className="img-div">
                        <img src={ChatLogo} style={{ margin: "-2px" }}></img>
                      </div>
                      <div style={{ marginLeft: 20 }}>
                        <div
                          className="name-div"
                          style={{ position: "relative" }}
                        >
                          <h3>Ballogy</h3>
                          {item?.length === 0 ? (
                            <div id="typing-loader"></div>
                          ) : (
                            <>
                              <h4 className="comment">
                                <ReactMarkdown>{item?.msg}</ReactMarkdown>
                              </h4>
                              <img
                                src={Copy}
                                key={item?._id}
                                onClick={() => {
                                  navigator.clipboard.writeText(item?.msg);
                                  setCopied(true);
                                }}
                                style={{
                                  marginBottom: "-10px",
                                  cursor: "pointer",
                                  visibility:
                                    index === listIndex && copied === false
                                      ? "visible"
                                      : "hidden",
                                }}
                              />
                              <CheckOutlined
                                className="check-icon"
                                key={index}
                                style={{
                                  visibility:
                                    copied === true && index === listIndex
                                      ? "visible"
                                      : "hidden",
                                }}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                );
              })
            )}
          </div>
        </Content>
        <Footer style={{ background: "#fff" }}>
          <div style={{ display: "flex" }}>
            <Input
              style={{ height: 38, width: "85%" }}
              placeholder="Type a message"
              value={messageText}
              onChange={(e) => {
                freemiumDetail?.is_premium === false &&
                freemiumDetail?.can_create_conversation === false &&
                chat?.length === 0
                  ? setIsModalOpen(true)
                  : freemiumQuestions?.is_premium === false && (freemiumQuestions?.count >= freemiumQuestions?.free_count ||
                    chat?.count >= chat?.free_count)
                  ? setIsModalOpen(true)
                  : setMessageText(e.target.value);
              }}
            />
            <div style={{ textAlign: "end", marginLeft: "auto" }}>
              <Button
                className="dark-base-btn"
                // )}
                onClick={() => SubmitQuestion()}
                style={{
                  opacity: messageText.length === 0 ? 0.5 : 1,
                  cursor: messageText.length === 0 ? "not-allowed" : "pointer",
                  background: darkButtonBackground,
                }}
                disabled={messageText.length === 0}
              >
                Send
              </Button>
            </div>
          </div>
          <FloatButton
            onClick={() => navigate("../coach/ballogy-team-training")}
            shape="square"
            className={
              "chat-ai " + (selectedSport === 1 ? "orange-theme" : "blue-theme")
            }
            icon={
              <RollbackOutlined
                className={
                  selectedSport === 1 ? "orange-theme-icon" : "blue-theme-icon"
                }
              />
            }
          ></FloatButton>
        </Footer>
      </Layout>
      <FreemiumModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </Layout>
  );
};

const ChatAi = () => {
  return (
    <ChatAIContextProvider>
      <ChatPage />
    </ChatAIContextProvider>
  );
};
export default ChatAi;
