import { useContext, useEffect, useState } from "react";
import { Modal, Row, Col, Radio, Tabs } from "antd";
import { UserContext } from "../../../context/user-context";
import OvalLogo from "../../../assets/images/oval-logo.svg";
import BlueOvalLogo from "../../../assets/images/Group 5.svg";
import { getDataTraining } from "../../../util/api-calls";
import { getDataTrainingCoach } from "../../../util/coach-api-calls";
const CategoryModal = ({
  categoryModal,
  setCategoryModal,
  selectedCategory,
  setSelectedCategory,
  categoryType,
  setFeaturedWorkoutModal,
  getAllFeatureWorkouts,
  mode,
  setMode
}) => {
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const { selectedSport, allTags, darkButtonBackground, getAllTags } =
    useContext(UserContext);
  const [groupTags, setGroupTags] = useState([]);
  const handleModeChange = (e) => {
    setMode(e.target.value);
  };
  useEffect(() => {
    if (categoryType === "task") {
      getAllTags(2);
    } else {
      getAllTags(1);
    }
  }, []);
  const getGroupsWorkouts = () => {
    let url = ""
    if (window.location.href.includes("home")) {
      url = getDataTraining(`sport/${selectedSport ? selectedSport : 1}/tags?category=${categoryType === "task" ? 2 : 1}&group_id=${params.id}`)
    } else {
      if(params.id){
      url = getDataTrainingCoach(`sport/${selectedSport ? selectedSport : 1}/tags?category=${categoryType === "task" ? 2 : 1}&group_id=${params.id}`)
      }else{
        url = getDataTrainingCoach(`sport/${selectedSport ? selectedSport : 1}/tags?category=${categoryType === "task" ? 2 : 1}`)
      }
    }
    url.then((res) => {
        setGroupTags(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if(mode && params?.id){
    getGroupsWorkouts();
    }
  }, [categoryType]);
  return (
    <Modal
      className="category-modal"
      wrapClassName={
        selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal"
      }
      open={categoryModal}
      //   onOk={handleOk}
      onCancel={() => setCategoryModal(false)}
      okText={"Finish Editing"}
      cancelText={"Cancel"}
      width={"769px"}
      footer={false}
    >
      <h1>Select {categoryType === "task" ? "Task" : "Workout"} category</h1>
      <p>
        Select category below to assign existing{" "}
        {categoryType === "task" ? "Task" : "Workout"}
      </p>
      {mode &&
      <Radio.Group
        onChange={handleModeChange}
        value={mode}
        style={{
          marginBottom: 30,
          justifyContent: "center",
          display: "flex",
        }}
        className={selectedSport === 1 ? "category-radio-tabs-orange" : "category-radio-tabs-blue" }
      >
        <Radio.Button value="all">All {categoryType === "task" ? "Tasks" : "Workouts"}</Radio.Button>
        <Radio.Button value="group">This Group's {categoryType === "task" ? "Tasks" : "Workouts"}</Radio.Button>
      </Radio.Group>
      }
      {mode === "all" || !mode ? (
        <Row gutter={[14, 14]}>
          <Col
            className="gutter-row"
            span={8}
            onClick={() => {
              setSelectedCategory("");
              setCategoryModal(false);
            }}
          >
            <div
              className={"card " + (selectedCategory === "" ? "bordered" : "")}
              style={{ color: "#3f4254" }}
            >
              All {categoryType === "task" ? "Tasks" : "Workouts"}
            </div>
          </Col>
          {/* {categoryType !== "task" && 
        <Col className="gutter-row" span={8} onClick={() => {setCategoryModal(false);setFeaturedWorkoutModal(true);getAllFeatureWorkouts();setSelectedCategory("featured") }}>
            <div className={"card " + (selectedCategory === "" ? "bordered" : "")} style={{ color: "#f9f9f9" , background:darkButtonBackground,flexDirection:"column"}}>
                <img src={selectedSport === 1 ? OvalLogo : BlueOvalLogo} style={{width:65 , height:65}}/>
            Featured Workouts
            </div>
        </Col>
        } */}
          {allTags.map((item, index) => {
            return (
              <Col
                className={"gutter-row"}
                span={8}
                key={index}
                onClick={() => {
                  setSelectedCategory(item.tag);
                  setCategoryModal(false);
                }}
              >
                <div
                  style={{
                    backgroundImage: item.id === 26 ? "" : `url(${item.image})`,
                    background:
                      item.id === 26
                        ? darkButtonBackground
                        : `url(${item.image})`,
                    flexDirection: "column",
                  }}
                  className={
                    "card " + (selectedCategory === item.tag ? "bordered" : "")
                  }
                >
                  {item.id === 26 ? (
                    <div
                      style={{
                        backgroundImage:
                          selectedSport === 1
                            ? `url(${OvalLogo})`
                            : `url(${BlueOvalLogo})`,
                      }}
                      className="feature-bg"
                    >
                      {" "}
                      <span>{item.tag}</span>{" "}
                    </div>
                  ) : (
                    item.tag
                  )}
                </div>
              </Col>
            );
          })}
        </Row>
      ) : (
        <Row gutter={[14, 14]}>
          <Col
            className="gutter-row"
            span={8}
            onClick={() => {
              setSelectedCategory("");
              setCategoryModal(false);
            }}
          >
            <div
              className={"card " + (selectedCategory === "" ? "bordered" : "")}
              style={{ color: "#3f4254" }}
            >
              All {categoryType === "task" ? "Tasks" : "Workouts"}
            </div>
          </Col>
          {/* {categoryType !== "task" && 
    <Col className="gutter-row" span={8} onClick={() => {setCategoryModal(false);setFeaturedWorkoutModal(true);getAllFeatureWorkouts();setSelectedCategory("featured") }}>
        <div className={"card " + (selectedCategory === "" ? "bordered" : "")} style={{ color: "#f9f9f9" , background:darkButtonBackground,flexDirection:"column"}}>
            <img src={selectedSport === 1 ? OvalLogo : BlueOvalLogo} style={{width:65 , height:65}}/>
        Featured Workouts
        </div>
    </Col>
    } */}
          {groupTags.map((item, index) => {
            return (
              <Col
                className={"gutter-row"}
                span={8}
                key={index}
                onClick={() => {
                  setSelectedCategory(item.tag);
                  setCategoryModal(false);
                }}
              >
                <div
                  style={{
                    backgroundImage: item.id === 26 ? "" : `url(${item.image})`,
                    background:
                      item.id === 26
                        ? darkButtonBackground
                        : `url(${item.image})`,
                    flexDirection: "column",
                  }}
                  className={
                    "card " + (selectedCategory === item.tag ? "bordered" : "")
                  }
                >
                  {item.id === 26 ? (
                    <div
                      style={{
                        backgroundImage:
                          selectedSport === 1
                            ? `url(${OvalLogo})`
                            : `url(${BlueOvalLogo})`,
                      }}
                      className="feature-bg"
                    >
                      {" "}
                      <span>{item.tag}</span>{" "}
                    </div>
                  ) : (
                    item.tag
                  )}
                </div>
              </Col>
            );
          })}
        </Row>
      )}
    </Modal>
  );
};
export default CategoryModal;
