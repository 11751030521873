import React, { useEffect, useState } from "react";
import {
  deleteItemTest,
  formPostDataTest,
  getDataTest,
  patchDataTest,
} from "../../util/api-calls";
import { message } from "antd";
export const VisionShootingContext = React.createContext({});
export const VisionShootingContextProvider = ({ children = {} }) => {
  const [visionLeaderboards, setVisionLeaderboards] = useState([]);
  const [leaderboardProfile, setLeaderboardProfile] = useState({});
  const [sessionsData, setSessionsData] = useState([]);
  const [teamSessionsData, setTeamSessionsData] = useState([]);
  const [selectedShooting, setSelectedShooting] = useState("");
  const [shootingDetailModal, setShootingDetailModal] = useState(false);
  const [shootingDetail, setShootingDetail] = useState({});
  const [shootingDetailView, setShootingDetailView] = useState(false);
  const [titlesList, setTitlesList] = useState([]);
  const [selectedTeamSession, setSelectedTeamSession] = useState("all");
  const [totalSessions, setTotalSessions] = useState(0);
  const [activeTab, setActiveTab] = useState("individual");
  const [suggestionsList, setSuggestionsList] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [hasMoreTeam, setHasMoreTeam] = useState(true);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [teamOffset, setTeamOffset] = useState(0);
  const [teamLimit, setTeamLimit] = useState(10);
  const [sort, setSort] = useState("desc");
  const [teamSort, setTeamSort] = useState("desc");
  const [search, setSearch] = useState("");
  const [searchTitle, setSearchTitle] = useState("");
  const [sessionTitle, setSessionTitle] = useState("");
  const [titleUpdate, setTitleUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editingKey, setEditingKey] = useState(false);
  const [make, setMake] = useState();
  const [attempts, setAttempts] = useState();
  const [tableData, setTableData] = useState([]);
  const [newTableData, setNewTableData] = useState(tableData);
  const [ptr2Make, setPtr2Make] = useState(0);
  const [ptr3Make, setPtr3Make] = useState(0);
  const [ptr3Attempts, setPtr3Attempts] = useState(0);
  const [ptr2Attempts, setPtr2Attempts] = useState();
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const getVisionLeaderBoard = () => {
    setLoading(true);
    getDataTest(
      `vision/group/${params.id}/leaderboard?filters=total_shots&sort=${sort}&search=${search}`
    )
      .then((res) => {
        setVisionLeaderboards(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getProfileSessions = () => {
    getDataTest(
      `vision/profile/${leaderboardProfile?.profile_id}/session/list?offset=${offset}&limit=${limit}`
    )
      .then((res) => {
        setSessionsData([...sessionsData, ...res.data.sessions]);
        if (offset === 0) {
          setOffset(offset + limit);
        } else {
          setHasMore(true);
          setOffset(offset + limit);
        }
        if (res.data.sessions.length === 0) {
          setHasMore(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getTeamSessions = () => {
    if (teamOffset === 0) {
      setLoading(true);
    }
    getDataTest(
      `team/vision/${params.id}/session/list?title=${
        selectedTeamSession === "all" ? "" : selectedTeamSession
      }&offset=${teamOffset}&limit=${teamLimit}&sort=${teamSort}`
    )
      .then((res) => {
        setTeamSessionsData([...teamSessionsData, ...res.data.sessions]);
        if (teamOffset === 0) {
          setTeamOffset(teamOffset + teamLimit);
        } else {
          setHasMoreTeam(true);
          setTeamOffset(teamOffset + teamLimit);
        }
        if (res.data.sessions.length === 0) {
          setHasMoreTeam(false);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getShootingDetail = (id) => {
    let url = "";
    if (activeTab === "teams" || params.tab === "team_shooting_sessions") {
      url = `team/vision/session/${id ? id : params.session_id}`;
    } else {
      url = `vision/profile/${leaderboardProfile?.profile_id}/session/${id}`;
    }
    getDataTest(url)
      .then((res) => {
        setShootingDetail(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updataSession = () => {
    setLoading(true);
    var attemptsList = newTableData?.map(function (item, index) {
      let startTime;
      let endTime;
      if(typeof(item.shot_start_time) === "object" ){
        startTime = (item.shot_start_time.$m * 60 * 1000) + (item.shot_start_time.$s * 1000)
      }else{
        startTime = item.shot_start_time;
      }
      if(typeof(item.shot_end_time) === "object"){
        endTime = (item.shot_end_time.$m * 60 * 1000) + (item.shot_end_time.$s * 1000)
      }else{
        endTime = item.shot_end_time;
      }
      return {
        id: item.id,
        score: item.make === true ? 1 : 0,
        make: item.make,
        shot_end_time: endTime,
        shot_start_time: startTime,
        timestamp: item.timestamp,
      };
    });
    const updatedData = attemptsList.map(item => {
      if (typeof item.id === "string" && item.id.startsWith("temp_id_")) {
          const { id, ...rest } = item; // Remove `id`
          return rest;
      }
      return item; // Keep original if `id` doesn't start with "temp_id_"
  });
    let data = {
      group_id: shootingDetail?.group_id,
      title: shootingDetail?.title,
      group_name: shootingDetail?.group_name,
      completion_time: shootingDetail.completion_time,
      total_attempts: attempts,
      total_make: make,
      court: shootingDetail?.court,
      media_uri: shootingDetail.media_uri,
      inaccurate_stats: shootingDetail.inaccurate_stats,
      attempts: updatedData,
    };
    patchDataTest(`team/vision/session/${shootingDetail.id}`, data)
      .then((res) => {
        message.success({
          content: "Session updated successfully",
        });
        getShootingDetail(shootingDetail?.id);
        setEditingKey(false);
        // setShootingDetailModal(false)
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (Object.keys(leaderboardProfile).length > 0) {
      // getProfileSessions()
    }
  }, [leaderboardProfile]);
  const deleteTeamSession = (id) => {
    deleteItemTest(`team/vision/session/${id}`)
      .then((res) => {
        message.success({
          content: res.data.details,
        });
        const newArray = teamSessionsData.filter((item) => item.id !== id);
        setTeamSessionsData(newArray);
        setShootingDetailModal(false);
        // getTeamSessions()
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const deleteVisionSession = (id) => {
    deleteItemTest(`vision/session/${id}`)
      .then((res) => {
        setSessionsData((sessionsData) => []);
        message.success({
          content: res.data.details,
        });
        setShootingDetailModal(false);
        getProfileSessions();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const listAllTitles = () => {
    getDataTest(`team/vision/${params.id}/title/list?title=${searchTitle}`)
      .then((res) => {
        setTitlesList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getSuggestionsList = () => {
    getDataTest(`team/vision/${params.id}/title/list?title=${searchTitle}`)
      .then((res) => {
        if (res.data?.length > 0) {
          setShowDropdown(true);
        }
        setSuggestionsList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const reviewSession = () => {
    let url = "";
    // if(activeTab === "individual"){
    url = `vision/session/${shootingDetail?.id}/review?group_id=${params.id}`;
    // }else{
    //   url = `team/vision/session/review/${shootingDetail?.id}?group_id=${params.id}`
    // }
    formPostDataTest(url)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };
  const updateSession = () => {
    if (sessionTitle === "") {
      setTitleError(true);
    } else {
      setTitleError(false);
      let data = {
        group_id: params.id,
        title: sessionTitle,
      };
      patchDataTest(`team/vision/session/title/${shootingDetail?.id}`, data)
        .then((res) => {
          message.success({
            content: res.data.details,
          });
          setTeamSessionsData([]);
          setTitleUpdate(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const removeSuggestion = (e) => {
    deleteItemTest(`team/vision/session/title/${e}`)
      .then((res) => {
        getSuggestionsList();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const defaultContext = {
    visionLeaderboards,
    setLeaderboardProfile,
    sessionsData,
    setSelectedShooting,
    shootingDetailModal,
    setShootingDetailModal,
    setShootingDetail,
    getShootingDetail,
    shootingDetail,
    selectedShooting,
    leaderboardProfile,
    loading,
    setLoading,
    shootingDetailView,
    setShootingDetailView,
    getVisionLeaderBoard,
    setVisionLeaderboards,
    sort,
    setSearch,
    setSort,
    search,
    params,
    activeTab,
    setActiveTab,
    listAllTitles,
    getTeamSessions,
    teamSessionsData,
    titlesList,
    selectedTeamSession,
    setSelectedTeamSession,
    getProfileSessions,
    hasMore,
    setHasMore,
    totalSessions,
    setTotalSessions,
    hasMoreTeam,
    setTeamOffset,
    setTeamSessionsData,
    searchTitle,
    setSearchTitle,
    deleteTeamSession,
    deleteVisionSession,
    setSessionsData,
    setOffset,
    teamSort,
    setTeamSort,
    reviewSession,
    sessionTitle,
    setSessionTitle,
    updateSession,
    titleUpdate,
    setTitleUpdate,
    setHasMoreTeam,
    suggestionsList,
    getSuggestionsList,
    showDropdown,
    setShowDropdown,
    setSuggestionsList,
    removeSuggestion,
    titleError,
    setTitleError,
    editingKey,
    setEditingKey,
    make,
    setMake,
    attempts,
    setAttempts,
    updataSession,
    tableData,
    setTableData,
    newTableData,
    setNewTableData,
    setPtr2Make,
    setPtr3Make,
    setPtr2Attempts,
    ptr2Make,
    setPtr3Attempts,
    ptr3Make,
    ptr2Attempts,
    ptr3Attempts,
  };
  return (
    <VisionShootingContext.Provider value={defaultContext}>
      {children}
    </VisionShootingContext.Provider>
  );
};
