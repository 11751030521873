import React, { useContext, useState } from "react";
import "./header-wrapper.scss";
import { Menu, Dropdown, Space, FloatButton, Select, message } from "antd";
import ChatAI from "../../assets/images/ai.svg";
import { Link, useNavigate } from "react-router-dom";
import DownArrow from "../../assets/images/down-arrow.svg";
import Logo from "../../assets/images/ballogy-logo.svg";
import User from "../../assets/images/user.svg";
import Logout from "../../assets/images/logout.svg";
import CryptoJs from "crypto-js";
import { jsonPostDataCoach } from "../../util/coach-api-calls";
import { UserContext } from "../../context/user-context";
const HeaderWrapper = () => {
  const { selectedSport, sportsList, postSelectedSport } =
    useContext(UserContext);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("");
  function getItem(label, key, children) {
    return {
      key,
      children,
      label,
    };
  }
  let url = window.location.pathname;

  const logout = () => {
    let tokenData = "";
    let decryptStr = "";
    decryptStr = CryptoJs.AES.decrypt(
      localStorage.getItem("coach_info"),
      "sec"
    ).toString(CryptoJs.enc.Utf8);
    if (decryptStr !== "") {
      tokenData = JSON.parse(decryptStr);
    }
    const refresh_token = tokenData.refresh_token;
    let refreshToken = { refresh_token: refresh_token };
    jsonPostDataCoach(`coach/logout`, refreshToken)
      .then((res) => {
        localStorage.removeItem("coach_info");
        localStorage.removeItem("coach_id");
        localStorage.removeItem("profile_id");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  React.useEffect(() => {
    if (url.includes("settings")) {
      setActiveTab("");
    } else if (url.includes("ballogy-team-training")) {
      // if (key.includes("groups")) {
      setActiveTab("ballogy-team-training");
    } else {
      setActiveTab("groups");
    }
  }, [url]);
  const items = [
    {
      key: "1",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => navigate("./settings")}
        >
          Account Settings
        </a>
      ),
    },
  ];
  const centerMenu = [
    // getItem(<Link to="/coach/groups">Group</Link>, "groups", null),
    getItem(
      <Link to="/coach/ballogy-team-training">Team Training Home</Link>,
      "ballogy-team-training",
      null
    ),
  ];
  const rightMenu = [
    // getItem(<img src={MessageIcon} />, "message", null),
    // getItem(<img src={NotificationIcon} />, "notification", null),
    getItem(
      <Dropdown
        overlayClassName="header-dropdown-menu"
        className="header-dropdown"
        placement="bottomRight"
        menu={{
          items,
        }}
      >
        <a onClick={(e) => e.preventDefault()}>
          <Space>
            <img
              src={User}
              className={
                selectedSport === 1 ? "orange-theme-icon" : "blue-theme-icon"
              }
              style={{ borderRadius: 6 }}
            />
          </Space>
        </a>
      </Dropdown>,
      "user",
      null
    ),
    getItem(<img src={Logout} onClick={logout} />, "logout", null),
  ];
  const changeMenu = (e) => {
    let key = e.key;
    if (key.includes("settings")) {
      setActiveTab("");
    } else if (key.includes("ballogy-team-training")) {
      // if (key.includes("groups")) {
      setActiveTab("ballogy-team-training");
    } else {
      setActiveTab("groups");
    }
  };
  const onClick = ({ key }) => {
    message.info(`Click on item ${key}`);
  };
  console.log(sportsList)
  let sportOptions = [];
  for (let i = 0; i < sportsList.length; i++) {
    sportOptions.push({
      value: sportsList[i].id,
      label: sportsList[i].name,
    });
  }
  return (
    <div
      className={
        "header-wrapper-main " +
        (selectedSport === 1 ? "orange-theme-header" : "blue-theme-header")
      }
    >
      <img src={Logo} />
      {selectedSport && (
        <Select
          placeholder="Select Group"
          optionFilterProp="children"
          onChange={postSelectedSport}
          suffixIcon={<img src={DownArrow} />}
          className="sport-select"
          popupClassName={
            "custom-action-select-dropdown " +
            (selectedSport === 1 ? "orange-theme-select" : "blue-theme-select")
          }
          options={sportOptions}
          defaultActiveFirstOption
          defaultValue={selectedSport}
        />
      )}
      <Menu
        theme="light"
        mode="horizontal"
        activeKey={activeTab}
        items={centerMenu}
        className="center-menu"
        onClick={changeMenu}
        style={{ width: "100%" }}
      />
      <Menu
        theme="light"
        mode="horizontal"
        items={rightMenu}
        className="right-menu"
      />
      <FloatButton
        onClick={() => navigate("../../chatbot")}
        shape="square"
        className={
          "chat-ai " + (selectedSport === 1 ? "orange-theme" : "blue-theme")
        }
        icon={
          <img
            src={ChatAI}
            className={
              "chat-ai " +
              (selectedSport === 1 ? "orange-theme-icon" : "blue-theme-icon")
            }
            style={{ marginTop: 8 }}
          />
        }
      ></FloatButton>
    </div>
  );
};
export default HeaderWrapper;
