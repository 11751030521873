import Logo from "../assets/images/logo.svg";
import { Row, Button } from "antd";
import "./main-screen.scss";
import { MdOutlineSportsBasketball } from "react-icons/md";
import { CiUser } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
const MainScreen = () => {
  const navigate = useNavigate();
  const goToAdmin = () => {
    if (localStorage.getItem("u_info")) {
      navigate("/home/users");
    } else {
      navigate("/login");
    }
  };
  const goToCoach = () => {
    if (localStorage.getItem("coach_info")) {
      navigate("/coach/ballogy-team-training");
    } else {
      navigate("/coach/login");
    }
  };
  return (
    <Row
      className="login-main"
      style={{
        width: "100vw",
        height: "100vh",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="form-div">
        <img src={Logo} />
        <h1>Sign in as</h1>
        <div className="button-div">
          <Button icon={<CiUser />} onClick={() => goToAdmin()}>
            Admin
          </Button>
          <Button
            icon={<MdOutlineSportsBasketball />}
            onClick={() => goToCoach()}
          >
            Coach
          </Button>
        </div>
      </div>
    </Row>
  );
};
export default MainScreen;
