import React, { useState, useRef, useContext } from "react";
import { Tabs, Button } from "antd";
import "./owners-members.scss";
import Owners from "./owners";
import Members from "./member";
import MembersModal from "./members-modal";
import {
  MemberContextProvider,
  MemberContext,
} from "../../../context/group-context/members-context";
import { UserContext } from "../../../context/user-context";
import TrialPeriodModal from "./trial-period-modal/index";
const OwnersMembersPage = ({ generalDetail, getGroupDetail }) => {
  const { darkButtonBackground, selectedSport, color, lightButtonBackground } =
    useContext(UserContext);
  const {
    memberModal,
    setMemberModal,
    selectedTab,
    onChangeTabs,
    setMemberPageNumber,
    setOwnerPageNumber,
    setTrialModal,
  } = useContext(MemberContext);
  const items = [
    {
      key: "1",
      label: `Owners`,
      children: (
        <Owners generalDetail={generalDetail} getGroupDetail={getGroupDetail} />
      ),
    },
    {
      key: "2",
      label: `Members`,
      children: (
        <Members
          generalDetail={generalDetail}
          getGroupDetail={getGroupDetail}
        />
      ),
    },
  ];
  const addMembers = () => {
    setMemberPageNumber(1);
    setOwnerPageNumber(1);
    setMemberModal(true);
  };
  return (
    <div className="owners-members-main">
      <h1>Owners/Members</h1>
      <div className="owners-members">
        <Tabs
          defaultActiveKey="1"
          items={items}
          onChange={onChangeTabs}
          className={
            "owners-members-tabs " +
            (selectedSport === 1 ? "orange-theme" : "blue-theme")
          }
        />

        <div
          style={{ display: "flex", marginLeft: "auto", gap: 15 }}
          className="add-button"
        >
          <Button
            className="red-base-btn"
            style={{ color: color, background: lightButtonBackground }}
            onClick={() => setTrialModal(true)}
          >
            Trial Period Code
          </Button>
          <Button
            className="dark-base-btn"
            style={{ background: darkButtonBackground }}
            onClick={() => addMembers()}
          >
            Add
            {selectedTab === "1" ? " Owners" : " Members"}
          </Button>
        </div>
      </div>
      <MembersModal
        memberModal={memberModal}
        setMemberModal={setMemberModal}
        selectedTab={selectedTab}
      />
      <TrialPeriodModal />
    </div>
  );
};
const OwnersMembers = ({ generalDetail, getGroupDetail }) => {
  return (
    <MemberContextProvider>
      <OwnersMembersPage
        generalDetail={generalDetail}
        getGroupDetail={getGroupDetail}
      />
    </MemberContextProvider>
  );
};
export default OwnersMembers;
