import { useContext } from "react";
import { Form, Button, Input, Checkbox, Radio, Upload, Divider, Spin, message } from "antd";
import "./create-new-group.scss";
import Edit from "../../../assets/images/edit.svg";
import { FaArrowLeftLong } from "react-icons/fa6";
import Close from "../../../assets/images/close.svg";
import { useNavigate } from "react-router-dom";
import {
  CreateGroupContextProvider,
  CreateGroupContext,
} from "../../../coach-context/group-context/create-group-context";
import { UserContext } from "../../../context/user-context";
const { TextArea } = Input;
const dateFormat = "ddd. MMMM D, YYYY";

const formItemLayout = {
  labelCol: {
    span: 9,
  },
  wrapperCol: {
    span: 15,
  },
};
const CreateGroupPage = () => {
  const { darkButtonBackground, selectedSport, color } =
    useContext(UserContext);
  const {
    createGroup,
    setImageUrl,
    setBsaLeaderBoard,
    setWorkoutLeaderBoard,
    imgSrc,
    setImgSrc,
    loading,
    imageError,
    setImageError,
  } = useContext(CreateGroupContext);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onchangeLeaderBoard = (e) => {
    if (e.indexOf("display_workout_leaderboard") > -1) {
      setWorkoutLeaderBoard(true);
    } else {
      setWorkoutLeaderBoard(false);
    }
    if (e.indexOf("display_bsa_leaderboard") > -1) {
      setBsaLeaderBoard(true);
    } else {
      setBsaLeaderBoard(false);
    }
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const handleBeforeUpload = (file) => {
    const isPNG = file.type === "image/png";
    const isJPEG = file.type === "image/jpeg";
    const isJPG = file.type === "image/jpg";
    if (!isPNG && !isJPEG && !isJPG) {
      message.error(`Only PNG, JPG, or JPEG files are allowed.`);
    } else if (file.size <= 50000 || file.size > 10000000) {
      message.error(`File size should be greater than 50KB or less than 10MB.`);
    } else {
      setImageError(false);
      setImageUrl(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        setImgSrc(reader.result);
      };
      console.log(file);
      reader.readAsDataURL(file);
      // Prevent upload
      return false;
    }
  };
  const removeImage = () => {
    setImageUrl();
    setImgSrc();
  };
  return (
    <>
      {loading && loading == true ? (
        <Spin
          className={
            "loader " +
            (selectedSport === 1 ? "orange-theme-loader" : "blue-theme-loader")
          }
          size="large"
          style={{
            position: "absolute",
            left: "49%",
            top: "39%",
          }}
        />
      ) : (
        <div className="create-group-main">
          <div className="heading-row">
            <FaArrowLeftLong
              className="left-arrow"
              onClick={() => navigate("/coach/ballogy-team-training")}
              style={{ stroke: color, color: color }}
            />
            <h1>Create New Group</h1>
          </div>
          <Form
            form={form}
            name="dynamic_rule"
            className="group-form"
            onFinish={createGroup}
            initialValues={{
              display_announcements_section: true,
              accessible_leaderboards: ["display_workout_leaderboard"],
            }}
          >
            <h2>General</h2>
            <Form.Item
              {...formItemLayout}
              name="avatar"
              label="Display Photo"
              className="img-div"
            >
              <div className="image-previewer">
                {!imgSrc ? (
                  <Upload
                    className="uploader"
                    showUploadList={false}
                    type="file"
                    customRequest={dummyRequest}
                    beforeUpload={handleBeforeUpload}
                  >
                    Upload pic
                  </Upload>
                ) : (
                  <>
                    <img
                      src={imgSrc}
                      alt="avatar"
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                      className="img"
                    />
                    <Upload
                      className="avatar-uploader"
                      showUploadList={false}
                      type="file"
                      customRequest={dummyRequest}
                      beforeUpload={handleBeforeUpload}
                    >
                      <img src={Edit} alt="edit" className="edit"></img>
                    </Upload>
                    <img
                      src={Close}
                      alt="close"
                      className="delete"
                      onClick={removeImage}
                    />
                  </>
                )}
              </div>
              <span className="img-type" style={{ marginBottom: 0 }}>
                Allowed file types: PNG, JPG, JPEG.
              </span>
              <span className="img-type">Min Size:50KB Max Size:10MB</span>
              {imageError && (
                <span style={{ color: "red" }}> Please upload group image</span>
              )}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="name"
              label="Full Name"
              className="name-row"
              rules={[
                {
                  required: true,
                  message: "Please enter group name.",
                },
              ]}
            >
              <Input
                placeholder="Please input your name"
                className="custom-input"
              />
            </Form.Item>
            <Divider />

            <h2>Announcements</h2>
            <Form.Item
              {...formItemLayout}
              name="display_announcements_section"
              label="Display Announcements Section"
              className="type-div"
            >
              <Radio.Group className="custom-radio">
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              name="accessible_leaderboards"
              label="Accessible Leaderboards"
              className="sport-div"
            >
              <Checkbox.Group
                className="custom-checkbox"
                onChange={onchangeLeaderBoard}
              >
                <Checkbox value={"display_workout_leaderboard"}>
                  Workouts/Challenges
                </Checkbox>
                <Checkbox value="display_bsa_leaderboard">BSA</Checkbox>
                {/* <Checkbox value="display_combine_measurement_leaderboard">
                  Combine Measurements
                </Checkbox> */}
              </Checkbox.Group>
            </Form.Item>
            <Divider />

            <Form.Item style={{ textAlign: "end", marginBottom: "0" }}>
              <Button
                htmlType="submit"
                type="primary"
                className="discard-btn"
                onClick={() => form.resetFields()}
              >
                Discard
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  margin: "0 8px",
                  background: darkButtonBackground,
                }}
                className="dark-base-btn"
              >
                Create
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
    </>
  );
};

const CreateGroup = () => {
  return (
    <CreateGroupContextProvider>
      <CreateGroupPage />
    </CreateGroupContextProvider>
  );
};
export default CreateGroup;
