import { useContext, useEffect } from "react";
import {
  Form,
  Input,
  Modal,
  Checkbox,
  Row,
  Col,
  Spin,
  Skeleton,
  message,
  Button,
} from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import Avatar from "react-avatar";
import { deleteItemTraining } from "../../../util/coach-api-calls";
import { UserContext } from "../../../context/user-context";
import { AssignMultipleGroupsContext } from "../../../coach-context/team-training-context/assign-multiple-groups-context";
const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};
const EditSubgroupModal = ({ clickedSubgroup }) => {
  const { selectedSport, darkButtonBackground } = useContext(UserContext);
  const {
    members,
    updateSubGroup,
    loading,
    imageLoaded,
    getGroupMembers,
    setImageLoaded,
    setSubGroupMembers,
    subGroupMembers,
    setEditSubGroup,
    setEditSubGroupName,
    editSubGroup,
    selectedGroup,
    setClickedSubGroup
  } = useContext(AssignMultipleGroupsContext);
  const [form] = Form.useForm();
  const handleOk = () => {
    setClickedSubGroup({})
    setEditSubGroup(editSubGroup=>[])
    setEditSubGroup(false);
    updateSubGroup();
    form.resetFields();
  };
  const handleCancel = () => {
    setClickedSubGroup({})
    setEditSubGroup(editSubGroup=>[])
    setEditSubGroup(false);
  };
  const deleteSubgroup = () => {
    setEditSubGroup(false);
    deleteItemTraining(`subgroup/${clickedSubgroup.id}`)
      .then((res) => {
        if (res && res.status === 204) {
          message.success({
            content: "Subgroup deleted successfully.",
          });
          setClickedSubGroup({})
          setEditSubGroup(editSubGroup=>[])
          getGroupMembers(selectedGroup?.id);
          setEditSubGroup(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleClick = (e, item) => {
    const { id, checked } = e.target;
    setSubGroupMembers([...subGroupMembers, item]);
    if (!checked) {
      setSubGroupMembers(subGroupMembers.filter((prev) => prev !== item));
    }
  };
  useEffect(() => {
    form.setFieldsValue({
      subgroup_name: clickedSubgroup.name,
    });
  }, []);
  const filteredList = members?.filter(
    obj => !clickedSubgroup?.members.some(removeObj => removeObj.id === obj.id)
  );
  return (
    <Modal
      className="edit-subgroup-modal"
      wrapClassName={
        selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal"
      }
      open={editSubGroup}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={"Finish Editing"}
      okButtonProps={{ disabled: subGroupMembers?.length === 0 }}
      cancelText={"Cancel"}
      width={"624px"}
    >
      <h1>Edit Subgroup</h1>
      <p>Edit the subgroup details</p>
      <Form form={form}>
        <Form.Item
          label="Subgroup Name"
          name={"subgroup_name"}
          {...formItemLayout}
        >
          <Input
            placeholder="Please enter subgroup name."
            onChange={(e) => setEditSubGroupName(e.target.value)}
          />
        </Form.Item>
      </Form>
      <div className="members-div">
        <div style={{ display: "flex", width: "100%" }}>
          {/* <Checkbox className="custom-checkbox" onChange={handleClick}/> */}
          <h2>Members ({subGroupMembers.length}) </h2>
        </div>
        {loading && loading == true ? (
          <Spin
            className={
              "loader " +
              (selectedSport === 1
                ? "orange-theme-loader"
                : "blue-theme-loader")
            }
            size="large"
            style={{
              position: "absolute",
              left: "32%",
              marginTop: -16,
            }}
          />
        ) : (
          <Row className="member-row">
            <Col span={24}>
              <InfiniteScroll
                dataLength={members.length}
                next={getGroupMembers}
                loader={<h4>Loading...</h4>}
              >
                {clickedSubgroup.members.map(function (item, index) {
                  return (
                    <Row className="owner-row" key={index}>
                      <Col span={24} style={{ display: "flex" }}>
                        <Checkbox
                          key={index}
                          type="checkbox"
                          name={item.name}
                          id={item.id}
                          className="custom-checkbox"
                          onChange={(e) => handleClick(e, item)}
                          style={{ marginRight: 26 }}
                          checked={subGroupMembers?.some(subgroup => subgroup.id === item.id)}
                          defaultChecked={subGroupMembers?.some(subgroup => subgroup.id === item.id)}
                        />
                        {item.avatar !== null && imageLoaded === "" && (
                          <Skeleton.Image
                            active={true}
                            className="user-skeleton"
                          />
                        )}
                        {!item.avatar ? (
                          <Avatar
                            name={item.first_name + " " + item.last_name}
                            color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
                            className="user-avatar"
                          />
                        ) : (
                          <img
                            src={item.avatar}
                            style={
                              imageLoaded === ""
                                ? { display: "none" }
                                : { display: "block" }
                            }
                            onLoad={() => setImageLoaded("loaded")}
                          ></img>
                        )}
                        <div className="name-div">
                          <h3 className="user-name">
                            {item.first_name + " " + item.last_name}
                          </h3>
                          <h4 className="user-handle">@{item.username}</h4>
                        </div>
                      </Col>
                      <hr />
                    </Row>
                  );
                })}
              </InfiniteScroll>
              <InfiniteScroll
                dataLength={filteredList.length}
                next={getGroupMembers}
                loader={<h4>Loading...</h4>}
              >
                {filteredList.map(function (item, index) {
                  return (
                    <Row className="owner-row" key={index}>
                      <Col span={24} style={{ display: "flex" }}>
                        <Checkbox
                          type="checkbox"
                          className="custom-checkbox"
                          onChange={(e) => handleClick(e, item)}
                          style={{ marginRight: 26 }}
                        />
                        {item.avatar !== null && imageLoaded === "" && (
                          <Skeleton.Image
                            active={true}
                            className="user-skeleton"
                          />
                        )}
                        {!item.avatar ? (
                          <Avatar
                            name={item.first_name + " " + item.last_name}
                            color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
                            className="user-avatar"
                          />
                        ) : (
                          <img
                            src={item.avatar}
                            style={
                              imageLoaded === ""
                                ? { display: "none" }
                                : { display: "block" }
                            }
                            onLoad={() => setImageLoaded("loaded")}
                          ></img>
                        )}
                        <div className="name-div">
                          <h3 className="user-name">
                            {item.first_name + " " + item.last_name}
                          </h3>
                          <h4 className="user-handle">@{item.username}</h4>
                        </div>
                      </Col>
                      <hr />
                    </Row>
                  );
                })}
              </InfiniteScroll>
            </Col>
          </Row>
        )}
      </div>
      <Button
        type="primary"
        onClick={deleteSubgroup}
        style={{
          position: "absolute",
          marginTop: 12,
          background: darkButtonBackground,
        }}
      >
        Delete Subgroup
      </Button>
    </Modal>
  );
};
export default EditSubgroupModal;
