import { Modal, Skeleton, Spin } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/user-context";
import { MessagingServiceContext } from "../../../coach-context/messaging-service-context";
import DoubleTick from "../../../assets/images/double-tick.svg";
import Avatar from "react-avatar";
import { getTime } from "../../../util/config";
import ReactPlayer from "react-player";
import Play from "../../../assets/images/play.svg";
import { FaCheck } from "react-icons/fa";

const MessageBubble = ({ direction, message, messageIndex}) => {
  const { readIndex } = useContext(MessagingServiceContext);
  const { selectedSport } = useContext(UserContext);
  const [mediaUrl, setMediaUrl] = useState(null);
  const [imageLoaded, setImageLoaded] = useState("");
  const [hasMedia, setHasMedia] = useState(
    message.type === "media" || message?.attributes?.media_url !== null
      ? true
      : false
  );
  const [mediaDownloadFailed, setMediaDownloadFailed] = useState(false);
  useEffect(() => {
    if (hasMedia && message.media) {
      message?.media !== null &&
        message?.media
          .getContentTemporaryUrl()
          .then((url) => {
            setMediaUrl(url);
          })
          .catch((e) => setMediaDownloadFailed(true));
    }
  }, []);
  const isReadByAll =
    Object.keys(readIndex).length > 1 &&
    Object.values(readIndex).every(
      (readItem) => readItem !== null && readItem >= messageIndex
      // messageIndex * 2
    );
  return (
    // message?.attributes?.is_group_chat !== false &&
    <li
      id={message.sid}
      className={direction === "incoming" ? "received_msg" : "outgoing_msg"}
      style={{ display: message?.attributes?.profanity_status === "FAILED" ? "none" : "flex" }}
    >
      {direction === "incoming" && (
        <div style={{ marginRight: 24 }}>
          {message?.attributes?.sender_avatar !== null &&
            imageLoaded === "" && (
              <Skeleton.Image
                active={true}
                style={{ width: "90px", height: "90px" }}
              />
            )}
          {!message?.attributes?.sender_avatar ? (
            <Avatar
              name={message?.attributes?.sender_username}
              color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
              maxmaxInitials={2}
              className="author-avatar"
            />
          ) : (
            <>
              <img
                src={message?.attributes?.sender_avatar}
                alt="avatar"
                className="user-logo"
                style={
                  imageLoaded === ""
                    ? { display: "none" }
                    : { display: "block" }
                }
                onLoad={() => setImageLoaded("loaded")}
              />
            </>
          )}
        </div>
      )}
      <div
        className={`${
          hasMedia && message.attributes?.media_url ? "with_media " : ""
        } ${direction === "incoming" ? "received_withd_msg" : "sent_msg"}`}
      >
        <div>
          <div className={"medias"}>
            {hasMedia && (
              <div
                className={
                  "media " +
                  (!mediaUrl || message?.attributes?.media_url === null
                    ? "placeholder"
                    : "")
                }
                onClick={() => {
                  Modal.info({
                    centered: true,
                    icon: null,
                    okText: "Close",
                    width: "856px",
                    title: "Preview",
                    closable: true,
                    wrapClassName:
                      selectedSport === 1
                        ? "orange-preview-modal"
                        : "blue-preview-modal",
                    rootClassName:
                      selectedSport === 1
                        ? "orange-preview-modal"
                        : "blue-preview-modal",
                    className:
                      selectedSport === 1
                        ? "orange-preview-modal"
                        : "blue-preview-modal",
                    content: (
                      <div
                        className={"picture_container"}
                        style={{
                          height:
                            message.messageType === "VIDEO" ? "auto" : "498px",
                        }}
                      >
                        {message.messageType === "VIDEO" ? (
                          <ReactPlayer
                            url={message.mediaUrl}
                            light={message.thumbnail}
                            playIcon={<img src={Play} />}
                            controls
                            config={{
                              file: {
                                attributes: {
                                  controlsList: "nodownload noplaybackrate",
                                  disablePictureInPicture: true,
                                },
                              },
                            }}
                          />
                        ) : (
                          <img
                            style={{ width: "100%", height: "100%"  }}
                            src={
                              mediaUrl
                                ? mediaUrl
                                : message?.attributes?.media_url
                            }
                          />
                        )}
                      </div>
                    ),
                  });
                }}
              >
                {!mediaUrl ||
                  (message?.attributes?.media_url === null &&
                    !mediaDownloadFailed && <Spin />)}

                {mediaDownloadFailed && (
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>warning</div>
                    <p>Failed to load media</p>
                  </div>
                )}

                {(!mediaDownloadFailed && mediaUrl) ||
                  (message?.attributes?.media_url && (
                    <div className={"media_icon"}>
                      <div className={"picture_preview"}>
                        {message.messageType === "VIDEO" ? (
                          <ReactPlayer
                            url={message.mediaUrl}
                            light={message.thumbnail}
                            playIcon={
                              <img src={Play} style={{ width: "50px" }} />
                            }
                            controls
                            config={{
                              file: {
                                attributes: {
                                  controlsList: "nodownload noplaybackrate",
                                  disablePictureInPicture: true,
                                },
                              },
                            }}
                          />
                        ) : (
                          <img
                            src={
                              mediaUrl
                                ? mediaUrl
                                : message?.attributes?.media_url
                            }
                            style={{height:"100%" , objectFit:"cover"}}
                          />
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
          <div className="message-body" id={messageIndex}>
            {message.body ? message.body : message.messageBody}
          </div>
        </div>
        <span className={"time_date"} style={{textAlign:direction === "outgoing" ? "end" : "start"}}>
          {getTime(message?.attributes?.timestamp)}
          {direction === "outgoing" && (
            <>
              {message?.attributes?.profanity_status === "LIVE" ? (
                <img
                  src={DoubleTick}
                  className={isReadByAll ? "orange-check" : "black-check"}
                  style={{ marginLeft: 5 }}
                />
              ) : (
                <FaCheck
                  style={{ color: "#000", fontSize: "small", marginLeft: 5 }}
                />
              )}
            </>
          )}
        </span>
      </div>
    </li>
  );
};
export default MessageBubble;
