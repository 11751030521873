import { useContext, useEffect } from "react";
import {
  Spin,
  Checkbox,
  Row,
  Col,
  Skeleton,
  Button,
  Upload,
  message,
  Input,
} from "antd";
import "./index.scss";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { CloseCircleFilled, PaperClipOutlined } from "@ant-design/icons";
import ReactPlayer from "react-player";
import { UserContext } from "../../../context/user-context";
import Avatar from "react-avatar";
import {
  MessageMultipleGroupsContext,
  MessageMultipleGroupsContextProvider,
} from "../../../coach-context/team-training-context/message-multiple-group-context";
const MessageMultiGroupPage = () => {
  const { darkButtonBackground, selectedSport, color } =
    useContext(UserContext);
  const {
    loading,
    allGroups,
    getAllGroups,
    imageLoaded,
    setImageLoaded,
    setFileType,
    setImageUrl,
    setImgSrc,
    messageBox,
    setMessageBox,
    imageUrl,
    imgSrc,
    messageText,
    setCheckedGroups,
    checkedGroups,
    setCheckAllGroups,
    setAssignGroupMembers,
    checkAllGroups,
    setMessageText,
    fileType,
    sendMessage,
  } = useContext(MessageMultipleGroupsContext);

  const navigate = useNavigate();

  const items = [
    {
      key: "1",
      label: "Last Name A-Z",
    },
    {
      key: "2",
      label: "Last Name Z-A",
    },
  ];
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const handleBeforeUpload = (file) => {
    if (file.size <= 50000  === false && file.size > 25000000 === false) {
    if (file.type.includes("image")) {
      setFileType("image");
      const reader = new FileReader();
      reader.onload = (e) => {
        setImgSrc(reader.result);
      };
      reader.readAsDataURL(file);
    } else if (file.type.includes("video")) {
      setFileType("video");
    }
  }
    if (!file.type.includes("image") && !file.type.includes("video")) {
      message.error(`Only Images/Videos files are allowed.`);
      setImageUrl();
      setImgSrc();
    } else if (file.size <= 50000 || file.size > 25000000) {
      message.error(`File size should be greater than 50KB or less than 25MB.`);
      setImageUrl();
      setImgSrc();
    } else {
      // setImageError(false)
      setImageUrl(file);

      // Prevent upload
      return false;
    }
  };

  const removeImage = () => {
    setImageUrl();
    setImgSrc();
  };
  const selectAllGroups = (e, item) => {
    const { id, checked } = e.target;
    setCheckAllGroups(true);
    if (!checked) {
      setCheckAllGroups(false);
      setCheckedGroups([]);
    }
  };
  const goBack = () => {
    if (messageBox) {
      setMessageBox(false);
    } else {
      navigate("/coach/ballogy-team-training");
    }
  };
  useEffect(() => {
    getAllGroups();
  }, []);

  const handleGroup = (e, item) => {
    const { id, checked } = e.target;
    setCheckedGroups([...checkedGroups, item]);
    if (!checked) {
      setCheckedGroups(checkedGroups.filter((prev) => prev !== item));
    }
  };

  return (
    <div className="message-group-main">
      <div className="heading-row">
        <FaArrowLeftLong
          className="left-arrow"
          onClick={goBack}
          style={{ stroke: color, color: color }}
        />
        <h1>{messageBox ? "New Message" : "Message Multiple Groups"}</h1>
      </div>
      {messageBox ? (
        <>
          <div className="new-message-box">
            <div className="input-div" style={{ height: imageUrl ? 90 : 38 }}>
              <Input
                style={{ width: imgSrc ? "90%" : "100%", marginLeft: "auto" }}
                placeholder={!imgSrc ? "Type your message here..." : ""}
                autoFocus
                value={messageText}
                onChange={(e) => setMessageText(e.target.value)}
                onKeyDown={(e) => (e.key === "Enter" ? sendMessage() : false)}
              />
              <Upload
                className={"uploader " + (imgSrc ? "margin" : "")}
                style={{ padding: "4px 11px" }}
                showUploadList={false}
                type="file"
                customRequest={dummyRequest}
                beforeUpload={handleBeforeUpload}
                disabled={imageUrl}
              >
                <Button icon={<PaperClipOutlined />} disabled={imageUrl}>
                  Add Attachment{" "}
                </Button>
              </Upload>
              <div style={{ position: "relative", padding: "4px 11px" }}>
                {imageUrl && imgSrc ? (
                  <>
                    <img
                      src={imgSrc}
                      alt="avatar"
                      style={{
                        width: "64px",
                        height: "64px",
                        borderRadius: 4,
                      }}
                      className="img"
                    />
                    <CloseCircleFilled
                      style={{ color: "#5E6278", fontSize: 15, top: "-5px" }}
                      onClick={removeImage}
                    />
                  </>
                ) : (
                  imageUrl && (
                    <div className="player-preview">
                      <ReactPlayer url={URL.createObjectURL(imageUrl)} />
                      <CloseCircleFilled
                        style={{
                          color: "#5E6278",
                          fontSize: 15,
                          right: "-8px",
                          left: 128,
                        }}
                        onClick={removeImage}
                      />
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
          <Button
            className="red-base-btn"
            disabled={messageText.length === 0 && !imageUrl && !imgSrc}
            onClick={sendMessage}
            style={{
              background: darkButtonBackground,
              marginLeft: 0,
              color: "#fff",
              height: 38,
              width: 65,
            }}
          >
            Send
          </Button>
        </>
      ) : (
        <>
          <h2>Send To</h2>

          <p>
            Select multiple groups and send a message to all members of that
            group. Messages will be received individually.
          </p>
          <div className="groups-div">
            <div
              style={{ display: "flex", width: "100%", marginBottom: "25px" }}
            >
              <Checkbox
                className="custom-checkbox"
                onChange={selectAllGroups}
              />

              <h2>Groups</h2>
            </div>
            {loading && loading == true ? (
              <Spin
                className={
                  "loader " +
                  (selectedSport === 1
                    ? "orange-theme-loader"
                    : "blue-theme-loader")
                }
                size="large"
                style={{
                  position: "absolute",
                  left: "25%",
                  marginTop: -25,
                }}
              />
            ) : (
              <Row className="group-row">
                {allGroups.map((item, index) => {
                  return (
                    item.is_active &&
                    <Row
                      key={index}
                      style={{
                        width: "100%",
                        color:
                          item.name === "Basketball" ? "#F15A29" : "#4EA7F8",
                      }}
                      className="member-row"
                    >
                      <Col
                        span={2}
                        key={item.id}
                        style={{ display: "contents" }}
                      >
                        <Checkbox
                          className="custom-checkbox"
                          onChange={(e) => handleGroup(e, item)}
                          checked={
                            checkedGroups.includes(item) || checkAllGroups
                          }
                        />
                      </Col>
                      <Col
                        span={20}
                        style={{ display: "flex", paddingLeft: 24 }}
                      >
                        {item?.group?.avatar && imageLoaded === "" && (
                          <Skeleton.Image
                            active={true}
                            className="user-skeleton"
                          />
                        )}
                        {!item?.group?.avatar ? (
                          <Avatar
                            name={item?.group?.name}
                            color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
                            className="user-avatar"
                            maxInitials={1}
                          />
                        ) : (
                          <img
                            src={item?.group?.avatar}
                            style={
                              imageLoaded === ""
                                ? { display: "none" }
                                : { display: "block" }
                            }
                            onLoad={() => setImageLoaded("loaded")}
                          ></img>
                        )}
                        <div className="name-div">
                          <div style={{ display: "flex" }}>
                            <h3 className="user-name">{item?.group?.name}</h3>{" "}
                          </div>
                        </div>
                      </Col>
                      <Col span={2}></Col>
                    </Row>
                  );
                })}
              </Row>
            )}
          </div>
          <div style={{ marginTop: "8px", marginBottom: 30 }}>
            <Button
              className="cancel-btn"
              onClick={() => navigate("/coach/ballogy-team-training")}
            >
              Back
            </Button>
            <Button
              className="next-btn"
              onClick={() => setMessageBox(true)}
              disabled={!checkAllGroups && checkedGroups?.length === 0}
              style={{ background: darkButtonBackground }}
            >
              Continue
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

const MessageMultiGroup = () => {
  return (
    <MessageMultipleGroupsContextProvider>
      <MessageMultiGroupPage />
    </MessageMultipleGroupsContextProvider>
  );
};
export default MessageMultiGroup;
