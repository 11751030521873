import React, { useContext, useState, useRef, useEffect } from "react";
import {
  Col,
  Collapse,
  Input,
  Row,
  Select,
  Table,
  Progress,
  Empty,
} from "antd";
import Profile from "../../../assets/images/avatar.svg";
import Search from "../../../assets/images/search.svg";
import { WorkoutDetailContext } from "../../../coach-context/workouts-context/workout-detail-context";
import DownArrow from "../../../assets/images/arrows.svg";
import Expand from "../../../assets/images/expand.svg";
import CollapseIcon from "../../../assets/images/collapse.svg";
import Avatar from "react-avatar";
import { LineChartOutlined } from "@ant-design/icons";
import GraphModal from "./graph-modal";
import { UserContext } from "../../../context/user-context";
const WorkoutProgress = ({ tasks, setViewSubmissions, setWorkoutDetail }) => {
  const {selectedSport , color} = useContext(UserContext)
  const { Panel } = Collapse;
  const {
    setSort,
    setSelectedTask,
    setSearch,
    workoutProgress,
    setWorkoutProgress,
    setGraphModal,
    graphModal,
    setGraphData,
    getSubmissions,
    setTaskDetail,
    setSubmissionRecord,profileId , setProfileId
  } = useContext(WorkoutDetailContext);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  let tasksOption = [{ value: "all", label: "All" }];
  for (let i = 0; i < tasks?.length; i++) {
    tasksOption.push({
      value: tasks[i].id,
      label: tasks[i].title,
    });
  }
  const trainingTaskcolumns = [
    {
      title: "Tasks",
      dataIndex: "member",
      width: "50%",
      render: (text, record) => (
        <div span={20} className="member-div" style={{ color: "#3f4254" }}>
          {record.title}{" "}
          <span className="task-type" style={{marginTop:record.scored_task ? 1 : 2}}>
            {record.scored_task ? "Scored Task" : "Training Task"}
          </span>
        </div>
      ),
    },
    // {
    //   title: "Reps/Score",
    //   dataIndex: "reps",
    //   render: (text, record) => (
    //     record.scored_task === false ?
    //     <span>
    //        {record.reps_done}/{record.total_reps}
    //      </span> : <span>{record.score}</span>
    //   ),
    // },
    {
      title: "Completion",
      dataIndex: "completion_percentage",
      render: (text, record) =>
        record.completion_percentage === 100 ? (
          <div className="status">Completed</div>
        ) : record.completion_percentage === 0 ? (
          <button className="yellow-tag" style={{ marginTop: "-3px" }}>
            Pending
          </button>
        ) : (
          <Progress
            percent={record.completion_percentage}
            showInfo={true}
            className={selectedSport === 1 ? "orange-progress-bar" : "blue-progress-bar" }
          />
        ),
    },
    // {
    //   title: "",
    //   dataIndex: "grpah",
    //   width: 100,
    //   render: (text, record) =>
    //     record.scored_task === true ? (
    //       <div
    //         onClick={
    //           record.completion_percentage !== 0
    //             ? () => {
    //               setGraphModal(true);
    //               setGraphData(record.graph);
    //             }
    //             : null
    //         }
    //         style={{
    //           cursor:
    //             record.completion_percentage === 0 ? "not-allowed" : "pointer",
    //         }}
    //       >
    //         <LineChartOutlined disabled={record.completion_percentage === 0} />
    //       </div>
    //     ) : (
    //       ""
    //     ),
    // },
    {
      render: (record) => {
        return (
          <div style={{ display: "flex" }}>
            <div
              style={{ 
                // display: record.recent_upload === true ? "block" : "block",
                 cursor: "pointer" , color : color }}
              onClick={() => {
                // setWorkoutDetail(false);
                setSubmissionRecord(record)
                setTaskDetail(record.id)
                getSubmissions(profileId, record.id)
                setViewSubmissions(true);
              }}
              className="view-submissions"
            >
              View submissions
            </div>
            {record?.recent_upload === true &&
              <span className="new-sub"></span>
            }
          </div>
        )
      }
    }
  ];
  const scoredTaskColumns = [
    {
      title: "Completed By",
      dataIndex: "member",
      width: "50%",
      render: (text, record) => (
        <div span={20} className="member-div">
          {record.title}
          <span className="task-type" style={{marginTop:record.scored_task ? 1 : 2}}>
            {record.scored_task ? "Scored Task" : "Training Task"}
          </span>
        </div>
      ),
    },
    // {
    //   title: "Score",
    //   dataIndex: "score",
    //   render: (text, record) => (
    //     record.scored_task === false ?
    //     <span>
    //        {record.reps_done}/{record.total_reps}
    //      </span> : <span>{record.score}</span>
    //   ),
    //     },
    {
      title: "Completion",
      dataIndex: "completion_percentage",
    },
    // {
    //   title: "",
    //   dataIndex: "grpah",
    //   render: (text, record) =>
    //     record.scored_task === true ? (
    //       <div onClick={() => setGraphModal(true)}>
    //         <LineChartOutlined />
    //       </div>
    //     ) : (
    //       ""
    //     ),
    // },
    {
      render: (record) => (
        <div style={{ display: "flex" }}>
          <div
            style={{
              // display: record.recent_upload === true ? "block" : "none",
               cursor: "pointer" , color : color}}
            onClick={() => {
              // setWorkoutDetail(false);
              setSubmissionRecord(record)
              setTaskDetail(record.id)
              getSubmissions(record)
              setViewSubmissions(true);
            }}
            className="view-submissions"
          >
            View submissions
          </div>
          {record?.recent_upload === true &&
            <span className="new-sub"></span>
          }
        </div>
      )
    },
  ];
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const onChangeSelect = (value) => {
    setSort(value);
  };
  const onChangeTask = (value) => {
    if (value === "all") {
      setSelectedTask("");
    } else {
      setSelectedTask(value);
    }
  };
  const searchWorkout = (e) => {
    if (e.target.value.length >= 3) {
      setWorkoutProgress([]);
      setSearch(e.target.value);
    } else if (e.target.value.length === 0) {
      setWorkoutProgress((workoutProgress) => []);
      setSearch("");
    }
  };
  return (
    <div className="table-div">
      <h2 className="task-heading">Workout Progress</h2>
      <div style={{ display: "flex" }}>
        <Input
          addonBefore={<img src={Search} />}
          placeholder="Search"
          className="search-input"
          onChange={searchWorkout}
        />
        <div className="right-div">
          <span className="tasks">Tasks</span>
          <Select
            placeholder="All"
            suffixIcon={<img src={DownArrow} />}
            onChange={onChangeTask}
            className="select-input select-1"
            popupClassName={"custom-action-select-dropdown " + (selectedSport === 1 ? "orange-theme-select" : "blue-theme-select")}
            options={tasksOption}
          />
          <Select
            placeholder="Most Reps. Completed"
            suffixIcon={<img src={DownArrow} />}
            onChange={onChangeSelect}
            className="select-input select-2"
            popupClassName={"custom-action-select-dropdown " + (selectedSport === 1 ? "orange-theme-select" : "blue-theme-select")}
            options={[
              {
                value: "desc",
                label: "Most Reps. Completed",
              },
              {
                value: "asc",
                label: "Least Reps. Completed",
              },
            ]}
          />
        </div>
      </div>
      {workoutProgress?.length > 0 ? (
        <Collapse
          expandIconPosition="end"
          expandIcon={({ isActive }) =>
            !isActive ? <img src={Expand} /> : <img src={CollapseIcon} className={"minus-icon " + (selectedSport === 1 ? "orange-theme-icon" : "blue-theme-icon")}/>
          }
          className="collapsible-rows"
        >
          {workoutProgress?.map((item, index) => {
            return (
              <Panel
                key={index}
                onClick={() =>
                  setProfileId(item)
                }
                header={
                  <div className="collapse-label">
                    <Row style={{ width: "100%" }}>
                      <Col span={17} style={{ fontSize: 16 }}>
                        <div span={20} className="member-div">
                          {item.avatar !== null ? (
                            <img src={item.avatar}></img>
                          ) : (
                            <Avatar
                              name={item.name}
                              maxInitials={1}
                              color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
                              className="user-avatar"
                            />
                          )}{" "}
                          <div className="name-div">
                            <h3 className="user-name">{item.name}</h3>
                            <h4 className="user-handle">@{item.username}</h4>
                          </div>
                        </div>
                      </Col>
                      {/* <Col span={3} style={{margin:"auto"}}>
                      {" "}
                      {item.is_competed === true ? (
                        <div className="status">Completed</div>
                      ) : item.is_competed === false &&
                        item.completion_percentage === 0 ? (
                        <button
                          className="yellow-tag"
                          style={{ marginTop: "-3px" }}
                        >
                          Pending
                        </button>
                      ) : (
                        <Progress
                          percent={tasks.completion_percentage}
                          showInfo={false}
                          className={selectedSport === 1 ? "orange-progress-bar" : "blue-progress-bar" }
                        />
                      )}
                    </Col> */}
                      <Col span={3} style={{ margin: "auto" }}>
                        <div className="reps">
                          Reps:{" "}
                          <span>
                            {item.reps_done}/{item.total_reps}
                          </span>
                        </div>
                      </Col>
                      <Col span={4} style={{ margin: "auto" }}>
                        <div className="points">
                          Completion: <span>{item.completion_percentage}%</span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                }
              >
                <Table
                  columns={
                    item.scored_task === true
                      ? scoredTaskColumns
                      : trainingTaskcolumns
                  }
                  pagination={false}
                  dataSource={item.tasks}
                  className="tasks-table"
                />
              </Panel>
            );
          })}
        </Collapse>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          style={{ marginBlock: 70 }}
        />
      )}
      {/* <GraphModal graphModal={graphModal} setGraphModal={setGraphModal} /> */}
    </div>
  );
};

export default WorkoutProgress;
