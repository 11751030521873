import React, { useEffect, useState, useCallback, useContext } from "react";
import { getDataCoach, jsonPostDataCoach } from "../../util/coach-api-calls";
import { UserContext } from "../../context/user-context";
import { message } from "antd";
export const TeamTrainingContext = React.createContext({});
export const TeamTrainingContextContextProvider = ({ children = {} }) => {
  const { selectedSport } = useContext(UserContext);
  const [noSeatModal, setNoSeatModal] = useState(false);
  const [licensesData, setLicensesData] = useState([]);
  const [accessCode, setAccessCode] = useState();
  const [loading, setLoading] = useState(false);
  const [clickedRow, setClickedRow] = useState({});
  const [groupsData , setGroupsData] = useState([])
  let profileId = localStorage.getItem("profile_id");

  const getLicenses = () => {
    setLoading(true);
    getDataCoach(`profile/team/management/licenses/${profileId}`)
      .then((res) => {
        setLicensesData(res.data);
        setGroupsData(res?.data?.team_management_tiers[0]?.groups)
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const syncAcessCode = () => {
    let data = {
      profile_id: profileId,
      access_code: accessCode,
      sport_id: selectedSport,
    };
    jsonPostDataCoach(`sync/team/training/code`, data)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
        message.error({
          content:err?.response?.data?.message
        })
        setAccessCode("")
      });
  };
  const changeGroupStatus = (status) => {
    setLoading(true);
    let data = {
      action: status,
      group_id: clickedRow?.group?.id,
      profile_id: profileId,
      team_management_id:licensesData?.team_management_tiers[0]?.team_management_detail?.id
    };
    jsonPostDataCoach(`profile/team/management/group`, data)
      .then((res) => {
        // setGroupsData([])
        // setLicensesData([])
        getLicenses();
      })
      .catch((err) => {
        if(err?.response?.data?.not_enough_seats === true){
          setNoSeatModal(true)
        }
      }).finally(()=>{
        setLoading(false)
      })
  };
  useEffect(() => {
    getLicenses();
  }, []);
  const defaultContext = {
    noSeatModal,
    setNoSeatModal,
    licensesData,
    syncAcessCode,
    accessCode,
    setAccessCode,
    changeGroupStatus,
    loading,
    setLoading,
    clickedRow,
    setClickedRow,
    groupsData,
    setGroupsData
  };
  return (
    <TeamTrainingContext.Provider value={defaultContext}>
      {children}
    </TeamTrainingContext.Provider>
  );
};
