import { useContext, useEffect } from "react";
import "./index.scss";
import { Button, Divider, Form, Input, Modal } from "antd";
import { UserContext } from "../../context/user-context";
import ArrowsRight from "../../assets/images/arrow-right-trans.svg";
import GroupHome from "../Groups/groups-home/group-home";
import {
  TeamTrainingContext,
  TeamTrainingContextContextProvider,
} from "../../coach-context/team-training-context/team-training-context";
import { useNavigate } from "react-router-dom";
import { getDate } from "../../util/config";
let teamManagementDetail 
const TeamTrainingPage = () => {
  const navigate = useNavigate();
  const {
    noSeatModal,
    setNoSeatModal,
    licensesData,
    syncAcessCode,
    accessCode,
    setAccessCode,
    groupsData
  } = useContext(TeamTrainingContext);
  const { darkButtonBackground, lightButtonBackground, color, selectedSport } =
    useContext(UserContext);
  const [form] = Form.useForm();
  const handleOk = () => {
    setNoSeatModal(false);
  };
  const handleCancel = () => {
    setNoSeatModal(false);
  };
     useEffect(()=>{
       if(Object.keys(licensesData)?.length > 0){
        teamManagementDetail = licensesData?.team_management_tiers[0]?.team_management_detail;
       }
     },[licensesData])
  return (
    <div className="team-training-main">
      <div className="access-code-form">
        <label htmlFor="code">Enter a Team Training Code</label>
        <div style={{ marginTop: 5 }}>
          <Input
            placeholder="Type your access code..."
            className="custom-input"
            onChange={(e) => setAccessCode(e.target.value)}
            value={accessCode}
          />
          <Button
            type="primary"
            htmlType="submit"
            style={{
              margin: "0 8px",
              background: darkButtonBackground,
            }}
            onClick={syncAcessCode}
            className="dark-base-btn"
            icon={<img src={ArrowsRight} className="right-arrow" />}
          ></Button>
        </div>
      </div>
      <div className="training-management">
        <label>Team Training Management</label>
        <Divider />
        {licensesData?.length === 0 ? (
          <div className="no-data">
            You are not an owner of any Training Groups.
          </div>
        ) : (
          <>
            <div className="data-div">
              <div style={{ display: "flex" }}>
                <h3>
                  {teamManagementDetail?.tier_type === "tier1"
                    ? "Tier 1 Subscription"
                    : teamManagementDetail?.tier_type === "tier2"
                    ? "Tier 2 Subscription"
                    : teamManagementDetail?.tier_type === "tier3"
                    ? "Tier 3 Subscription"
                    : teamManagementDetail?.tier_type === "free_tier" 
                    ? "Free Tier" 
                    : "Tier 4 Subscription"}{" "}
                </h3>
                <button
                  className="manage-sub"
                  onClick={() => navigate("./manage-your-subscription")}
                >
                  Manage Your Subscription
                </button>
              </div>
              <div style={{ display: "flex", marginTop: 16 }}>
                <div className="seats">
                  Member Seats (<span>{teamManagementDetail?.used_seats}</span>{" "}
                  Used | <span>{teamManagementDetail?.pending_seats}</span>{" "}
                  Pending | <span>{teamManagementDetail?.remaining_seats}</span>{" "}
                  Remaining)
                </div>
                {teamManagementDetail?.tier_type !== "free_tier" &&
                <div className="sub-end">
                  Subscription Ends:{" "}
                  {getDate(teamManagementDetail?.subscription_end_date)}
                </div>
                }
              </div>
              <GroupHome teamManagementDetail={teamManagementDetail}/>
            </div>
            {groupsData?.length !== 0 && (
              <div style={{ display: "flex", marginTop: 30, gap: 10 }}>
                <button
                  className="red-base-btn"
                  style={{
                    color: color,
                    background: lightButtonBackground,
                    marginLeft: 0,
                  }}
                  onClick={() => navigate(`./message-multiple-group`)}
                  disabled={(groupsData?.length === 1 && (groupsData?.some((item)=>item.is_active === false))|| 
                    (groupsData?.length !== 0 && groupsData?.every(obj => 
                      obj.is_active === false)
                      ))
                  }
                >
                  Message Multiple Groups
                </button>
                <button
                  className="red-base-btn"
                  onClick={() => navigate(`./assign-multiple-group`)}
                  style={{
                    color: color,
                    background: lightButtonBackground,
                    marginLeft: 0,
                  }}
                  disabled={(groupsData?.length === 1 && (groupsData?.some((item)=>item.is_active === false))|| 
                    (groupsData?.length !== 0 && groupsData?.every(obj => 
                      obj.is_active === false)
                      ))
                  }                
                  >
                  Assign to Multiple Groups
                </button>
              </div>
            )}
          </>
        )}
      </div>
      <Modal
        title="Not enough seats!"
        open={noSeatModal}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={"Got it!"}
        // cancelButtonProps={false}
        destroyOnClose
        width={550}
        className="no-seat-modal"
        wrapClassName={
          "no-seat-modal " +
          (selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal")
        }
      >
        <p>
          Your subscription does not have enough remaining member seats to
          activate this group (which has 16 members). Remove members from other
          groups before activating this group, or consider upgrading to a higher
          tier subscription..
        </p>
      </Modal>
    </div>
  );
};

const TeamTraining = () => {
  return (
    <TeamTrainingContextContextProvider>
      <TeamTrainingPage />
    </TeamTrainingContextContextProvider>
  );
};

export default TeamTraining;
