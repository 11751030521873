import React, { useEffect, useState } from "react";
import {
  formPostData,
  getDataCoach,
  patchData,
  jsonPostDataCoach,
} from "../../util/coach-api-calls";
export const MessagesContext = React.createContext({});
export const MessagesContextProvider = ({ children = {} }) => {
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const [newMessage, setNewMessage] = useState(false);
  const [senderView, setSenderView] = useState(true);
  const [members, setMembers] = useState({});
  const [subGroups, setSubgroups] = useState([]);
  const [ownerSubGroups, setOwnerSubGroups ] = useState([])
  const [imageLoaded, setImageLoaded] = useState("");
  const [checkedItems, setCheckedItems] = useState(false);
  const [isCheckMembers, setIsCheckMembers] = useState([]);
  const [isCheckOwners, setIsCheckOwners] = useState([]);
  const [subgroupModal, setSubgroupModal] = useState(false);
  const [subGroupMembers, setSubGroupMembers] = useState([]);
  const [subGroupOwners, setSubGroupOwners] = useState([]);
  const [editSubGroupName, setEditSubGroupName] = useState("");
  const [subGroupName, setSubGroupName] = useState("");
  const [editSubGroup, setEditSubGroup] = useState(false);
  const [clickedSubgroup, setClickedSubGroup] = useState({});
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [imgSrc, setImgSrc] = useState();
  const [allIndiviually, setAllIndiviually] = useState(false);
  const [messageText, setMessageText] = useState("");
  const [continueDisabled, setContinueDisabled] = useState(true);
  const [checkedGroups, setCheckedGroups] = useState([]);
  const [checkAllMembers, setCheckAllMembers] = useState(false);
  const [chatRoomDetail, setChatRoomDetail] = useState();
  const [activeTab, setActiveTab] = useState("inbox");
  const [messageListing, setMessageListing] = useState([]);
  const [search, setSearch] = useState("");
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedUserProfileData, setSelectedUserProfileData] = useState({});
  const [fileType, setFileType] = useState("image");
  const [messageData, setMessageData] = useState();
  const [sendLoading, setSendLoading] = useState(false);
  const [owners, setOwners] = useState({});
  const [profileType, setProfileType] = useState("members");
  const [selectedProfile , setSelectedProfile] = useState({})
  const [selectedSubgroup , setSelectedSubgroup] = useState({})
  const listingMembers = () => {
    setLoading(true);
    getDataCoach(`messages/group/${params.id}/subgroup`)
      .then((res) => {
        setMembers(res.data.members);
        setSubgroups(res.data.subgroups);
        setOwnerSubGroups(res.data.owner_subgroups)
        setOwners(res.data.owners);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const createSubGroup = () => {
    let profiles = [];
    isCheckMembers.map((e) => profiles.push(e.id));
    let ownersProfile = [parseFloat(localStorage.getItem("profile_id"))];
    isCheckOwners.map((e) => ownersProfile.push(e.id));
    let data = {
      name: subGroupName,
      members: profiles,
      owners: ownersProfile,
      group_id: params.id,
    };
    jsonPostDataCoach(`messages/group/${params.id}/subgroup`, data)
      .then((res) => {
        setMembers({});
        setSubgroups((subGroups) => []);
        setOwnerSubGroups((ownerSubGroups)=>[])
        setIsCheckMembers([]);
        setIsCheckOwners([]);
        setIsCheckMembers((isCheckMembers) => []);
        setSubGroupName("");
        listingMembers();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const updateSubGroup = () => {
    let subGroupProfiles = [];
    subGroupMembers.map((e) => subGroupProfiles.push(e.id));
    let subGroupOwnerProfiles = [parseFloat(localStorage.getItem("profile_id"))];
    subGroupOwners.map((e) => subGroupOwnerProfiles.push(e.id));
    let filterMembers = [...new Set(subGroupProfiles)];
    let filterOwners = [...new Set(subGroupOwnerProfiles)];
    let data = {
      name: editSubGroupName === "" ? clickedSubgroup.name : editSubGroupName,
      members: filterMembers,
      owners: filterOwners,
      group_id: params.id,
    };
    patchData(
      `messages/group/${params.id}/subgroup/${clickedSubgroup.id}`,
      data
    )
      .then((res) => {
        setSubGroupMembers((subGroupMembers) => []);
        listingMembers();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const sendIndividual = () => {
    setMessageListing([]);
    let formData = new FormData();
    let checkMembers = [];
    let checkOwners = [];
    formData.append("send_all_individually", true);
    formData.append("message", messageText);
    if (imageUrl) {
      formData.append("file", imageUrl);
      formData.append("file_type", fileType);
    }
    if (isCheckMembers?.length > 1 || isCheckOwners?.length > 1) {
      isCheckMembers.map((e) => checkMembers.push(e.id));
      isCheckOwners.map((e) => checkOwners.push(e.id));
      formData.append(
        "receiver_ids",
        checkMembers.join(",") + "," + checkOwners.join(",")
      );
    }

    formPostData(`messages/group/${params.id}/create`, formData)
      .then((res) => {
        setAllIndiviually(false);
        setNewMessage(false);
        setSenderView(true);
        setMessageText("");
        getMessageListing();
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setSendLoading(false);
      });
  };
  const postSingleMemberChat = () => {
    setSelectedProfile(isCheckMembers[0])
    let formData = new FormData();
    formData.append("receiver_id", isCheckMembers[0].id);
    formPostData(`messages/group/${params.id}/create`, formData)
      .then((res) => {
        setChatRoomDetail(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const postWholeGroupChat = () => {
    let formData = new FormData();
    formData.append("select_all", checkAllMembers);
    formPostData(`messages/group/${params.id}/create`, formData)
      .then((res) => {
        setChatRoomDetail(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const readMessges = (id) => {
    // if (activeTab === "inbox") {
    if(id){
      getDataCoach(`app/messages/${id}/read`)
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const postSubgroupChat = () => {
    let formData = new FormData();
    setSelectedSubgroup(checkedGroups[0])
    formData.append("subgroup_id", checkedGroups[0].id);
    formPostData(`messages/group/${params.id}/create`, formData)
      .then((res) => {
        setChatRoomDetail(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const sendMessageChat = (formData) => {
    // console.log(imageUrl)
    // let formData = new FormData();
    // formData.append("sender_id", localStorage.getItem("profile_id"));
    // formData.append("message", messageText);
    // if (imageUrl) {
    //   formData.append("file", imageUrl);
    //   formData.append("file_type", fileType);
    // }
    return formPostData(
      `app/messages/${chatRoomDetail.chatroom}/send`,
      formData
    )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getMessageListing = () => {
    if (pageNumber === 1) {
      setLoading(true);
    }
    getDataCoach(
      `messages/group/${params.id}/list?page=${pageNumber}&type=${activeTab}&search=${search}`
    )
      .then((res) => {
        setMessageListing((prev) => [...prev, ...res.data.results]);
        if (res.data.next !== null) {
          setPageNumber(pageNumber + 1);
          setHasMoreMessages(true);
        } else {
          setHasMoreMessages(false);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getMessageListing();
  }, [activeTab, search]);
  const defaultContext = {
    newMessage,
    setNewMessage,
    senderView,
    setSenderView,
    listingMembers,
    subGroups,
    ownerSubGroups,
    members,
    imageLoaded,
    setImageLoaded,
    checkedItems,
    setCheckedItems,
    isCheckMembers,
    setIsCheckMembers,
    subgroupModal,
    setSubgroupModal,
    setSubGroupName,
    subgroupModal,
    setSubgroupModal,
    createSubGroup,
    subGroupName,
    editSubGroup,
    setEditSubGroup,
    clickedSubgroup,
    setClickedSubGroup,
    members,
    updateSubGroup,
    loading,
    imageLoaded,
    setImageLoaded,
    setSubGroupMembers,
    subGroupMembers,
    setEditSubGroup,
    setEditSubGroupName,
    editSubGroup,
    checkedGroups,
    setCheckedGroups,
    continueDisabled,
    setContinueDisabled,
    allIndiviually,
    setAllIndiviually,
    sendIndividual,
    sendMessageChat,
    messageText,
    setMessageText,
    imageUrl,
    setImageUrl,
    imgSrc,
    setImgSrc,
    postSingleMemberChat,
    postSubgroupChat,
    postWholeGroupChat,
    checkAllMembers,
    setCheckAllMembers,
    chatRoomDetail,
    activeTab,
    setActiveTab,
    messageListing,
    getMessageListing,
    hasMoreMessages,
    fileType,
    setFileType,
    setChatRoomDetail,
    selectedUserProfileData,
    setSelectedUserProfileData,
    messageData,
    setMessageData,
    setSearch,
    setMessageListing,
    readMessges,
    setPageNumber,
    sendLoading,
    setSendLoading,
    owners,
    isCheckOwners,
    setIsCheckOwners,
    profileType,
    setProfileType,
    subGroupOwners,
    setSubGroupOwners,
    selectedProfile,
    setSelectedProfile,
    selectedSubgroup,
    setSelectedSubgroup
  };
  return (
    <MessagesContext.Provider value={defaultContext}>
      {children}
    </MessagesContext.Provider>
  );
};
