import { useContext } from "react";
import {
  Input,
  Row,
  Col,
  Modal,
  Skeleton,
  Button,
  Form,
  Spin,
} from "antd";
import Search from "../../../assets/images/search.svg";
import Avatar from "react-avatar";
import InfiniteScroll from "react-infinite-scroll-component";
import { UserContext } from "../../../context/user-context";
import { ManageSubscriptionContext } from "../../../coach-context/team-training-context/manage-subscription-context";
const InviteAccountHolders = ({ inviteModal, setInviteModal }) => {
  const { color, lightButtonBackground, selectedSport } =
    useContext(UserContext);
  const {
    allMembers,
    listAllMembers,
    hasMore,
    setAllMembers,
    imageLoaded,
    setImageLoaded,
    setPageNumber,
    setSearch,
    inviteMembers,
    setHasMore,
    search,
    loading,
  } = useContext(ManageSubscriptionContext);
  const [form] = Form.useForm();
  const handleCancel = () => {
    form.resetFields();
    setAllMembers([]);
    setAllMembers((allMembers) => []);
    setSearch("");
    setHasMore(false);
    setPageNumber(1);
    setInviteModal(false);
  };
  const searchMembers = (e) => {
    if (e.target.value.length >= 2) {
      setPageNumber(1);
      setAllMembers([]);
      setSearch(e.target.value);
    } else if (e.target.value.length === 0) {
      setPageNumber(1);
      setAllMembers((allMembers) => []);
      setSearch("");
    }
  };
  const sendInvites = (item) => {
    inviteMembers(item);
  };
  const updatedArr = [];

  allMembers.forEach((item) => {
    const existingIndex = updatedArr.findIndex((el) => el.id === item.id);
    if (existingIndex !== -1) {
      // If the item with the same id exists, replace it
      updatedArr[existingIndex] = item;
    } else {
      // Otherwise, add the new item
      updatedArr.push(item);
    }
  });
  return (
    <Modal
      wrapClassName={
        "invite-modal " +
        (selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal")
      }
      open={inviteModal}
      onOk={handleCancel}
      onCancel={handleCancel}
      okText={"Close"}
      width={"937px"}
    >
      {/* {contextHolder} */}
      <div className="add-invite-main">
        <h1>Invite Account Holders</h1>
        <br />
        <Form form={form}>
          <Form.Item name="search">
            <Input
              addonBefore={<img src={Search} />}
              placeholder={"Search profile by name"}
              className="search-input"
              onChange={searchMembers}
              style={{ width: 413 }}
            />
          </Form.Item>
        </Form>
        <div className="members-list">
          {loading && loading == true ? (
            <Spin
              className={
                "loader " +
                (selectedSport === 1
                  ? "orange-theme-loader"
                  : "blue-theme-loader")
              }
              size="large"
              style={{
                position: "absolute",
                left: "48%",
                marginTop: -16,
              }}
            />
          ) : (
            <InfiniteScroll
              dataLength={updatedArr.length}
              next={listAllMembers}
              height={400}
              hasMore={hasMore}
              loader={<h4>Loading...</h4>}
            >
              {updatedArr.map((item, index) => (
                <Row className="owner-row" key={index}>
                  <Col span={21} style={{ display: "flex" }}>
                    {item.avatar !== null && imageLoaded === "" && (
                      <Skeleton.Image active={true} className="user-skeleton" />
                    )}
                    {!item.avatar ? (
                      <Avatar
                        name={item.first_name + " " + item.last_name}
                        color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
                        className="user-avatar"
                      />
                    ) : (
                      <img
                        src={item.avatar}
                        style={
                          imageLoaded === ""
                            ? { display: "none" }
                            : { display: "block" }
                        }
                        onLoad={() => setImageLoaded("loaded")}
                      ></img>
                    )}
                    <div className="name-div">
                      <h3 className="user-name">
                        {item.first_name + " " + item.last_name}
                      </h3>
                      <h4 className="user-handle">@{item.username}</h4>
                    </div>
                  </Col>
                  <Col span={3} style={{ textAlign: "end" }}>
                    {item.already_invited === true ? (
                      // &&
                      // item.rejected_invite === false
                      <Button className="gray-base-btn">Invite Sent</Button>
                    ) : item.already_joined === true ? (
                      <Button
                        className="red-base-btn"
                        style={{
                          color: color,
                          background: lightButtonBackground,
                        }}
                      >
                        Joined
                      </Button>
                    ) : (
                      // :
                      // item.rejected_invite === true &&
                      // item.already_invited ? (
                      //   <>
                      //     <Button className="resend-invite">Resend Invite</Button>
                      //     <Button
                      //       className="red-base-btn"
                      //       style={{
                      //         color: color,
                      //         background: lightButtonBackground,
                      //       }}
                      //     >
                      //       Rejected
                      //     </Button>
                      //   </>
                      // )
                      <Button
                        className="red-base-btn"
                        onClick={() => {
                          setAllMembers([]);
                          setAllMembers((allMembers) => []);
                          sendInvites(item);
                        }}
                        style={{
                          color: color,
                          background: lightButtonBackground,
                        }}
                      >
                        Invite
                      </Button>
                    )}
                  </Col>
                  <hr />
                </Row>
              ))}
            </InfiniteScroll>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default InviteAccountHolders;
