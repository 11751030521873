import { useContext, useEffect, useState } from "react";
import {
  Checkbox,
  DatePicker,
  Col,
  Row,
  Button,
  Skeleton,
  Spin,
  Collapse,
} from "antd";
import Calendar from "../../../assets/images/calendar.svg";
import Plus from "../../../assets/images/plus-red.svg";
import Avatar from "react-avatar";
import DownArrow from "../../../assets/images/down-arrow.svg";
import SubgroupModal from "./subgroup-modal";
import User from "../../../assets/images/dual-user.svg";
import EditSubgroupModal from "./edit-subgroup-modal";
import { AssignedWorkoutContextProvider } from "../../../coach-context/group-context/assigned_workout_context";
import { UserContext } from "../../../context/user-context";
import { AssignMultipleGroupsContext } from "../../../coach-context/team-training-context/assign-multiple-groups-context";
import { useNavigate } from "react-router-dom";
const AssignedWorkoutPage = ({
  setShowAllWorkouts,
  // createdWorkout,
  setMembersView,
  setNewWorkout,
}) => {
  const navigate = useNavigate();
  const { color, lightButtonBackground, selectedSport, darkButtonBackground } =
    useContext(UserContext);
  const {
    allGroupMembers,
    setAllGroupMembers,
    imageLoaded,
    setImageLoaded,
    checkedGroups,
    setCheckedGroups,
    setSubgroupModal,
    getAllGroups,
    allGroups,
    loading,
    subLoading,
    getGroupMembers,
    createdWorkout,
    isCheckMembers,
    setIsCheckMembers,
    members,
    subGroups,
    assignWorkout,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
    selectedGroup,
    setSelectedGroup,
    assignGroupMembers,
    setAssignGroupMembers,
    setCheckAllGroups,
    checkAllGroups,
    setEditSubGroup,
    setClickedSubGroup,
    setSubGroupMembers,
    clickedSubgroup,
  } = useContext(AssignMultipleGroupsContext);
  const [startDateError, setStartDateError] = useState("");
  const [endDateError, setEndDateError] = useState("");

  const showSubGroupModal = () => {
    setSubgroupModal(true);
  };
  const dateFormat = "ddd. MMMM D, YYYY @ h:mm A";
  const items = [
    {
      key: "1",
      label: "Last Name A-Z",
    },
    {
      key: "2",
      label: "Last Name Z-A",
    },
  ];

  useEffect(() => {
    getAllGroups();
  }, []);
  const selectAllGroups = (e, item) => {
    const { id, checked } = e.target;

    allGroups?.filter(item => item.is_active !== false).map((e) => {
      setAssignGroupMembers((prevGroup) => [
        ...prevGroup,
        {
          group_id: e.group.id,
          group_name: e.group.name,
          select_all: true,
        },
      ]);
    });
    setCheckAllGroups(true);

    if (!checked) {
      setCheckAllGroups(false);

      setAssignGroupMembers([]);
    }
  };
  const handleClick = (e, item) => {
    const { id, checked } = e.target;
    setIsCheckMembers([...isCheckMembers, item]);
    if (checked === true) {
      if (
        assignGroupMembers.some((group) => group.group_id === selectedGroup?.id)
      ) {
        setAssignGroupMembers((prevData) =>
          prevData.map((group) => {
            if (group.group_id === selectedGroup?.id) {
              return {
                ...group,
                profiles: [...group.profiles, item],
              };
            }
            return group;
          })
        );
      } else {
        setAssignGroupMembers([
          ...assignGroupMembers,
          {
            group_id: selectedGroup?.id,
            group_name: selectedGroup?.name,
            select_all: false,
            sub_group_id: [],
            profiles: [item],
          },
        ]);
      }
    }
    if (!checked) {
      setIsCheckMembers(isCheckMembers.filter((prev) => prev !== item));
      setAssignGroupMembers((prevState) => {
        return prevState.map((group) => {
          if (group?.profiles?.length === 1 && group?.select_all === false) {
            setAssignGroupMembers(
              assignGroupMembers.filter(
                (item) =>
                  item.select_all !== false && item.profiles?.length !== 0
              )
            );
          } else {
            return {
              ...group,
              profiles: group?.profiles?.filter(
                (profile) => profile.id !== item.id
              ),
            };
          }
        });
      });
    }
  };
  const handleSubGroupClick = (e, item) => {
    const updatedMembers = item?.members.map(obj => ({
      ...obj,
      subGroupProfiles : true
  }));
    const { id, checked } = e.target;
    setIsCheckMembers([...isCheckMembers, item]);
    if (checked === true) {
      if (
        assignGroupMembers.some((group) => group.group_id === selectedGroup?.id)
      ) {
        setAssignGroupMembers((prevData) =>
          prevData.map((group) => {
            if (group.group_id === selectedGroup?.id) {
              return {
                ...group,
                sub_group_id: [...group?.sub_group_id, item.id],
                // profiles: [...group.profiles, ...item?.members],
                profiles: [...group.profiles, ...updatedMembers],
              };
            }
            return group;
          })
        );
      } else {
        setAssignGroupMembers([
          ...assignGroupMembers,
          {
            group_id: selectedGroup?.id,
            group_name: selectedGroup?.name,
            select_all: false,
            sub_group_id: [item.id],
            // profiles: item.members,
            profiles:updatedMembers
          },
        ]);
      }
    }
    if (!checked) {
      setIsCheckMembers(isCheckMembers.filter((prev) => prev !== item));
      setAssignGroupMembers((prevState) => {
        // const memberIds = item.members.map((member) => member.id);
        const memberIds = updatedMembers.map((member) => member.id);
        return prevState.map((group) => {
          if (
            group?.sub_group_id?.length === 1 &&
            group?.select_all === false &&
            // item?.members?.length === group?.profiles?.length
            updatedMembers?.length === group?.profiles?.length
          ) {
            setAssignGroupMembers(
              assignGroupMembers.filter(
                (item) =>
                  item.select_all !== false && item.sub_group_id?.length !== 0
              )
            );
          } else {
            return {
              ...group,
              sub_group_id: group?.sub_group_id?.filter(
                (subGroup) => subGroup !== item.id
              ),
              profiles: group?.profiles?.filter(
                (profile) => !memberIds.includes(profile.id)
              ),
            };
          }
        });
      });
    }
  };
  const handleGroup = (e, item) => {
    setSelectedGroup(item?.group);
    const { id, checked } = e.target;
    if (
      assignGroupMembers?.some(
        (items) => items?.group_id === item?.group?.id
        // || item?.group?.id === item?.id
      )
    ) {
      setAssignGroupMembers((prevData) =>
        prevData.map((group) => {
          if (group.group_id === item?.group?.id) {
            delete group["profiles"];
            delete group["sub_group_id"];
            return {
              ...group,
              select_all: true,
            };
          }
          return group;
        })
      );
    } else {
      setAssignGroupMembers([
        ...assignGroupMembers,
        {
          group_id: item.group.id,
          group_name: item.group.name,
          select_all: true,
        },
      ]);
    }
    getGroupMembers(item?.group?.id);
    if (!checked) {
      setAssignGroupMembers(
        assignGroupMembers.filter((prev) => prev.group_id !== item.group.id)
      );
    }
  };
  const profiles = [],
    subGroupId = [];
  const assignAllMembers = (e) => {
    setIsCheckMembers((isCheckMembers) => []);
    if (e.target.checked === true) {
      allGroups?.filter(item => item.is_active !== false).map((e) => {
        setAssignGroupMembers((prevGroup) => [
          ...prevGroup,
          {
            group_id: e.group.id,
            group_name: e.group.name,
            select_all: true,
          },
        ]);
      });
      setAllGroupMembers(true);
    } else {
      setAssignGroupMembers((assignGroupMembers) => []);
      setAllGroupMembers(false);
    }
  };
  const assignedWorkout = () => {
    if (
      startDate === "" ||
      startDate === null ||
      endDate === "" ||
      endDate === null
    ) {
      if (startDate === "" || startDate === null) {
        setStartDateError("Please select start date");
      }
      if (endDate === "" || endDate === null) {
        setEndDateError("Please select end date");
      }
    } else {
      assignWorkout(
        createdWorkout,
        setNewWorkout,
        setMembersView,
        profiles,
        subGroupId
      );
    }
  };
  const items1 = allGroups?.filter(item => item.is_active !== false).map((item) => ({
    key: item.group.id,
    onClick: () => setSelectedGroup(item?.group),
    label: (
      <Row
        style={{
          width: "100%",
          color: item.name === "Basketball" ? "#F15A29" : "#4EA7F8",
        }}
        className="member-row"
      >
        {/* {members?.length > 0 && */}
        <Col span={2} key={item.id} style={{ display: "contents" }}>
          <Checkbox
            className="custom-checkbox"
            onChange={(e) => handleGroup(e, item)}
            checked={
              checkAllGroups ||
              assignGroupMembers.some(
                (group) =>
                  group?.group_id === item?.group?.id &&
                  group?.select_all !== false
              )
            }
          />
        </Col>
        {/* } */}
        <Col span={20} style={{ display: "flex", paddingLeft: 24 }}>
          {item?.group?.avatar && imageLoaded === "" && (
            <Skeleton.Image active={true} className="user-skeleton" />
          )}
          {!item?.group?.avatar ? (
            <Avatar
              name={item?.group?.name}
              color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
              className="user-avatar"
              maxInitials={1}
            />
          ) : (
            <img
              src={item?.group?.avatar}
              style={
                imageLoaded === "" ? { display: "none" } : { display: "block" }
              }
              onLoad={() => setImageLoaded("loaded")}
            ></img>
          )}
          <div className="name-div">
            <div style={{ display: "flex" }}>
              <h3 className="user-name">{item?.group?.name}</h3>{" "}
              <img src={User} />
            </div>
          </div>
        </Col>
        <Col span={2}></Col>
      </Row>
    ),

    children:
      subLoading && subLoading == true ? (
        <Spin
          key={item.id}
          className={
            "loader " +
            (selectedSport === 1 ? "orange-theme-loader" : "blue-theme-loader")
          }
          size="large"
          style={{
            position: "absolute",
            left: "25%",
            // top: "39%",
            marginTop: -22,
          }}
        />
      ) : (
        <>
          {members.map(function (member, index) {
            return (
              <Row
                style={{
                  width: "100%",
                  color: member.name === "Basketball" ? "#F15A29" : "#4EA7F8",
                }}
                className="member-row"
                key={index}
              >
                <Col span={2} key={index} style={{ display: "contents" }}>
                  <Checkbox
                    key={index}
                    type="checkbox"
                    name={member.first_name}
                    id={member.id}
                    className="custom-checkbox"
                    onChange={(e) => handleClick(e, member)}
                    disabled={assignGroupMembers.some(
                      (group) =>
                        group?.group_id === item?.group?.id &&
                        group.select_all === true
                    )}
                    checked={
                      assignGroupMembers.some(
                        (group) =>
                          (group?.group_id === item?.group?.id &&
                            group?.select_all === true) ||
                          group?.profiles?.some(
                            (profile) =>  
                            profile?.subGroupProfiles !== true && group?.group_id === item?.group?.id 
                            && profile.id === member.id
                          )
                      ) || checkAllGroups
                    }
                  />
                </Col>
                <Col span={22} style={{ display: "flex", paddingLeft: 24 }}>
                  {member?.avatar && imageLoaded === "" && (
                    <Skeleton.Image active={true} className="user-skeleton" />
                  )}
                  {!member?.avatar ? (
                    <Avatar
                      name={member?.username}
                      color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
                      className="user-avatar"
                      maxInitials={1}
                    />
                  ) : (
                    <img
                      src={member?.avatar}
                      style={
                        imageLoaded === ""
                          ? { display: "none" }
                          : { display: "block" }
                      }
                      onLoad={() => setImageLoaded("loaded")}
                    ></img>
                  )}
                  <div className="name-div">
                    <div style={{ display: "flex" }}>
                      <div style={{ width: 200 }}>
                        <h3 className="user-name">
                          {member.first_name + " " + member.last_name}
                        </h3>
                        <h4 className="user-handle">@{member.username}</h4>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            );
          })}

          {subGroups.map(function (item, index) {
            return (
              <Row
                style={{
                  width: "100%",
                  color: item.name === "Basketball" ? "#F15A29" : "#4EA7F8",
                }}
                className="member-row"
              >
                <Col span={2} key={index} style={{ display: "contents" }}>
                  <Checkbox
                    key={index}
                    type="checkbox"
                    name={item.name}
                    id={item.id}
                    className="custom-checkbox"
                    onChange={(e) => handleSubGroupClick(e, item)}
                    disabled={assignGroupMembers.some(
                      (group) =>
                        group.group_id === item.group_id &&
                        group.select_all === true
                    )}
                    checked={
                      assignGroupMembers.some(
                        (group) =>
                          (group.group_id === item.group_id &&
                            group.select_all === true) ||
                          group?.sub_group_id?.includes(item.id)
                      ) || checkAllGroups
                    }
                  />
                </Col>
                <Col span={18} style={{ display: "flex", paddingLeft: 24 }}>
                  {item?.avatar && imageLoaded === "" && (
                    <Skeleton.Image active={true} className="user-skeleton" />
                  )}
                  {!item?.avatar ? (
                    <Avatar
                      name={item?.name}
                      color={selectedSport === 1 ? "#F15A29" : "#4EA7F8"}
                      className="user-avatar"
                      maxInitials={1}
                    />
                  ) : (
                    <img
                      src={item?.name}
                      style={
                        imageLoaded === ""
                          ? { display: "none" }
                          : { display: "block" }
                      }
                      onLoad={() => setImageLoaded("loaded")}
                    ></img>
                  )}
                  <div className="name-div">
                    <div style={{ display: "flex" }}>
                      <div style={{ width: 200 }}>
                        <h3 className="user-name">{item.name}</h3>
                      </div>

                      {/* <img src={User} /> */}
                    </div>
                  </div>
                </Col>
                <Col span={4}>
                  <Button
                    className="edit-group"
                    onClick={() => {
                      // getSubGroups(item.id);
                      setEditSubGroup(true);
                      setClickedSubGroup(item);
                      setSubGroupMembers(item.members);
                    }}
                  >
                    Edit Group
                  </Button>
                </Col>
              </Row>
            );
          })}
        </>
      ),
  }));
  return (
    <div className="edit-selected-workout">
      <div style={{ display: "flex", marginBottom: "30px" }}></div>
      <div className="time-limit">
        <h3>Duration</h3>
        <div className="form-data">
          <div className="date">
            <label htmlFor="start-date">Start Date</label>
            <br />
            <DatePicker
              showTime
              className={
                "date-picker " +
                (selectedSport === 1 ? "orange-picker" : "blue-picker")
              }
              format={dateFormat}
              style={{ height: "38px" }}
              suffixIcon={<img src={Calendar} />}
              onChange={(e) => {
                setStartDate(e);
                setStartDateError("");
              }}
              rootClassName={
                selectedSport === 1 ? "orange-picker" : "blue-picker"
              }
            />
            {startDateError !== "" && (
              <span style={{ color: "red" }}>{startDateError}</span>
            )}
          </div>
          <div className="date" style={{ marginLeft: "30px" }}>
            <label htmlFor="end-date">End Date</label>
            <br />
            <DatePicker
              showTime
              className={
                "date-picker " +
                (selectedSport === 1 ? "orange-picker" : "blue-picker")
              }
              format={dateFormat}
              style={{ height: "38px" }}
              suffixIcon={<img src={Calendar} />}
              onChange={(e) => {
                setEndDate(e);
                setEndDateError("");
              }}
              rootClassName={
                selectedSport === 1 ? "orange-picker" : "blue-picker"
              }
            />
            {endDateError !== "" && (
              <span style={{ color: "red" }}>{endDateError}</span>
            )}
          </div>
        </div>
      </div>
      <Checkbox className="custom-checkbox" onChange={assignAllMembers}>
        <p
          style={{ margin: 0, fontSize: 13, color: "#5e6278", fontWeight: 600 }}
        >
          Assign to all group members
        </p>
      </Checkbox>
      <div
        className="assign-to"
        style={{
          opacity: allGroupMembers ? 0.5 : "unset",
        }}
      >
        <h3 style={{ marginBottom: 10 }}>Assign To Individuals</h3>

        <p>Select the group members you want to assign this task to.</p>
        <p>
          You can also create a subgroup using the selected members by clicking
          on “Create Subgroup”
        </p>
        <Button
          className="red-base-btn"
          style={{ color: color, background: lightButtonBackground }}
          disabled={
            assignGroupMembers?.length !== 1 ||
            assignGroupMembers?.some(
              (item) =>
                item?.select_all === true ||
                item?.profiles?.length <= 1 ||
                item?.sub_group_id?.length !== 0
            )
          }
          onClick={showSubGroupModal}
        >
          <img
            src={Plus}
            className={
              selectedSport === 1 ? "orange-theme-svg" : "blue-theme-svg"
            }
          />
          Create Subgroup
        </Button>
      </div>

      <div
        className="members-div"
        style={{
          opacity: allGroupMembers ? 0.5 : "unset",
        }}
      >
        <div style={{ display: "flex", width: "100%", marginBottom: "48px" }}>
          <Checkbox
            className="custom-checkbox"
            onChange={selectAllGroups}
            checked={
              checkAllGroups || allGroups?.length === assignGroupMembers?.length
            }
          />

          <h2 style={{ marginLeft: 20 }}>
            Groups ({assignGroupMembers?.length}){" "}
          </h2>
          {/* <Dropdown
            className="sort-dropdown"
            menu={{ items }}
            overlayClassName="sort-dropdown-menu"
          >
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                Last Name A-Z
                <img src={DownArrow} />
              </Space>
            </a>
          </Dropdown> */}
        </div>
        {loading && loading == true ? (
          <Spin
            className={
              "loader " +
              (selectedSport === 1
                ? "orange-theme-loader"
                : "blue-theme-loader")
            }
            size="large"
            style={{
              position: "absolute",
              left: "25%",
              marginTop: -16,
            }}
          />
        ) : (
          <Collapse
            accordion
            items={items1}
            ghost
            className="groups-collapse"
            onChange={(e) => e.length > 0 && getGroupMembers(e[0])}
            collapsible={"icon"}
            // activeKey={selectedGroup?.id}
            expandIconPosition="end"
            expandIcon={({ isActive }) =>
              !isActive ? (
                <img src={DownArrow} />
              ) : (
                <img src={DownArrow} className="arrow" />
              )
            }
          />
        )}
      </div>

      <div style={{ marginTop: "8px" }}>
        <Button className="cancel-btn" onClick={() => navigate("/coach/ballogy-team-training")}>
          Cancel
        </Button>
        <Button
          className="next-btn"
          onClick={() => assignedWorkout()}
          disabled={
            !checkAllGroups &&
            !allGroupMembers &&
            (assignGroupMembers?.length === 0 ||
              assignGroupMembers?.some(
                (item) =>
                  item?.select_all === false && item?.profiles?.length === 0
              ))
          }
          style={{ background: darkButtonBackground }}
        >
          Assign Workout
        </Button>
      </div>
      {Object.keys(clickedSubgroup).length > 0 && (
        <EditSubgroupModal clickedSubgroup={clickedSubgroup} />
      )}
      <SubgroupModal />
    </div>
  );
};

const AssignedWorkout = ({
  showModal,
  setShowAllWorkouts,
  createdWorkout,
  setMembersView,
  setNewWorkout,
}) => {
  return (
    <AssignedWorkoutContextProvider>
      <AssignedWorkoutPage
        showModal={showModal}
        setShowAllWorkouts={setShowAllWorkouts}
        createdWorkout={createdWorkout}
        setMembersView={setMembersView}
        setNewWorkout={setNewWorkout}
      />
    </AssignedWorkoutContextProvider>
  );
};

export default AssignedWorkout;
