import { Modal, Typography } from "antd";
import React, { useContext } from "react";
import "./freemium-modal.scss"
import { UserContext } from "../../context/user-context";
const { Text } = Typography;
const FreemiumModal = ({ isModalOpen, setIsModalOpen }) => {
const {selectedSport} = useContext(UserContext)
  const handleOk = () => {
    window.open("https://www.ballogy.com/subscription-plans/", "_blank");

  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <Modal
      open={isModalOpen}
      onOk={handleOk}
      okText={"Purchase Options"}
      onCancel={handleCancel}
      wrapClassName={"freemium-modal " + ( 
        selectedSport === 1 ? "orange-theme-modal" : "blue-theme-modal")
      }      className="freemium-modal"
      width={690}>
      <h1>Develop your athletes with Ballogy Team Training Groups</h1>
      <p>
      Ballogy Team Training offers a private, in-app group to deliver assigments
      to your athletes! Track results, create healthy competition with
      leaderboards, and keep all of your data and communication in one place.</p>
      <ul>
        <li>
          <Text>
            Create custom Tasks/Workouts or borrow from an expanding library of
            existing workouts and Ballogy Challenges.
          </Text>
        </li>
        <li>
          <Text>
            Assign your Workouts to your entire group, individuals, or
            customized sub-groups
          </Text>
        </li>
        <li>
          <Text>Group-specific Leaderboards</Text>
        </li>
        <li>
          <Text>Group Announcements and multimedia Posts capabilities</Text>
        </li>
        <li>
          <Text>
            Dedicated Ballogy representative to assist with team onboarding,
            best practices, and ongoing support
          </Text>
        </li>
        <li>
          <Text>
            Each member of your group receives 4 non-expiring BSA submission
            credits per month for the duration of your subscription (Basketball
            only)
          </Text>
        </li>
      </ul>
    </Modal>
  );
};
export default FreemiumModal;
