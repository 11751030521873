import React, { useContext } from "react";
import { Tabs, Button } from "antd";
import "./messages.scss";
import Edit from "../../../assets/images/notepad-edit.svg";
import {
  MessagesContext,
  MessagesContextProvider,
} from "../../../coach-context/group-context/messages-context";
import { UserContext } from "../../../context/user-context";
import InboxMessages from "./inbox";
import Recepients from "./recepients";
import {
  MessagingServiceContext,
} from "../../../coach-context/messaging-service-context";
const MessagesPage = () => {
  const { darkButtonBackground, selectedSport } = useContext(UserContext);
  const { messages } = useContext(MessagingServiceContext);
  const {
    setMessageListing,
    newMessage,
    setNewMessage,
    activeTab,
    setActiveTab,
    setPageNumber,
    setSearch,
  } = useContext(MessagesContext);
  const onChangeTabs = (e) => {
    setMessageListing([]);
    setSearch("");
    setPageNumber(1);
    setActiveTab(e);
  };
  const items = [
    {
      key: "inbox",
      label: `Inbox`,
      children: <InboxMessages />,
    },
    {
      key: "sent",
      label: `Sent`,
      children: <InboxMessages />,
    },
  ];
  return (
    <div className="messages-main">
      {!newMessage && messages.length === 0 ? (
        <>
          <h1>Messages</h1>
          <div className="owners-members">
            <Tabs
              defaultActiveKey={activeTab}
              activeKey={activeTab}
              items={items}
              onChange={onChangeTabs}
              destroyInactiveTabPane
              className={
                "message-tabs " +
                (selectedSport === 1 ? "orange-theme" : "blue-theme")
              }
            />
            <Button
              className="dark-base-btn add-button"
              style={{ background: darkButtonBackground }}
              onClick={() => {setPageNumber(1) ;setNewMessage(true)}}
              icon={<img src={Edit} />}
            >
              New Message
            </Button>
          </div>
        </>
      ) : (
        <Recepients />
      )}
    </div>
  );
};
const Messages = () => {
  return (
      <MessagesContextProvider>
        <MessagesPage />
      </MessagesContextProvider>
  );
};
export default Messages;
