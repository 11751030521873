import React, { useEffect, useState } from "react";
import { Client } from "@twilio/conversations";
import { getDataCoach } from "../util/coach-api-calls";
export const MessagingServiceContext = React.createContext({});
let allMessages = [];
export const MessagingServiceContextProvider = ({ children = {} }) => {
  const [selectedMessage, setSelectedMessage] = useState({});
  const [token, setToken] = useState(null);
  const [selectedConversationSid, setSelectedConversationSid] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loadingState, setLoadingState] = useState("initializing");
  const [readReceipts, setReadReceipts] = useState({});
  const [messageLoader, setMessageLoader] = useState(false);
  const [hasMoreMessages, setHasMoreMessages] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [client, setClient] = useState(null);
  const [activeConversation, setActiveConversation] = useState(null);
  const [readIndex, setReadIndex] = useState([]);
  const [selectedUserProfileData, setSelectedUserProfileData] = useState({});
  const [chat, setChat] = useState([]);
  const [loadingScrollData, setLoadingScrollData] = useState(true);
  const [shouldScrollToId, setShouldScrollToId] = useState("");
  const [lastMessage, setLastMessage] = useState();
  var url = window.location.pathname;
  var result = url.split("/");
  var Param = result[1];

  let profileId = localStorage.getItem("profile_id");

  useEffect(() => {
    if (token !== null) {
      initializeClient(token);
    }
  }, [token]);
  useEffect(() => {
    if (Param === "coach" && profileId !== null) {
      getAccessToken(false);
    }
  }, []);
  const getAccessToken = (expire) => {
    let url;
    if (expire === true && selectedConversationSid !== null) {
      url = `app/messages/access/token?profile_id=${profileId}&chatroomId=${selectedConversationSid}`;
    } else {
      url = `app/messages/access/token?profile_id=${profileId}`;
    }
    getDataCoach(url)
      .then((res) => {
        console.log(res);
        setToken(res.data.access_token);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const initializeClient = async (token) => {
    try {
      setIsLoading(true);
      const twilioClient = new Client(token);
      console.log("twilio acess token", token, selectedConversationSid);
      console.log("twilio twilioClient: ", twilioClient);
      twilioClient.on("stateChanged", (state) => {
        if (state === "initialized") {
          setClient(twilioClient);
          console.log("twilio client initialized", state);
        }
      });

      twilioClient.on("connectionStateChanged", (connectionState) => {
        console.log("twilio Connection state changed:", connectionState);
      });

      twilioClient.on("tokenAboutToExpire", () => {
        console.warn(
          "twilio Access token is about to expire. Please refresh it."
        );
      });

      twilioClient.on("tokenExpired", () => {
        console.error("twilio Access token has expired. Please refresh it.");
        getAccessToken(true);
      });

      twilioClient.on("error", (error) => {
        console.error("twilio Twilio client error:", error);
      });
    } catch (error) {
      setIsLoading(false);
      console.error("Error initializing Twilio client:", error);
    }
  };
  const getAllMessages = async (conversation) => {
    setLoadingScrollData(true);
    try {
      if (activeConversation === null) {
        allMessages = [];
      }
      let conversations = conversation ? conversation : activeConversation;
      let messageCount = await conversations.getMessagesCount();
      let messagePage = await conversations.getMessages(100, lastMessage);
      console.log("twilio All messagePage:", messagePage);
      if (messagePage.hasPrevPage === true) {
        setHasMoreMessages(true);
        setLastMessage(chat.length === 0 ? undefined : chat[0].messageIndex);
      } else {
        setLastMessage(0);
        setHasMoreMessages(false);
      }
      if (!lastMessage) {
        allMessages = allMessages.concat(messagePage.items);
      } else if (messagePage.hasPrevPage) {
        messagePage = await messagePage.prevPage();
        setShouldScrollToId(messagePage?.items[0].index);
        allMessages = messagePage.items.concat(allMessages);
      }
      const messageDetails = allMessages.map((message) => {
        const senderIdentity = message.author;
        const messageBody = message.body;
        const messageType =
          message?.attributes?.is_media == false
            ? "text"
            : message?.attributes?.media_type;
        const mediaUrl = message?.attributes?.media_url;
        const attributes = message.attributes;
        const thumbnail = message?.attributes?.media_thumbnail;
        const messageIndex = message?.index;
        return {
          senderIdentity,
          messageBody,
          messageType,
          mediaUrl,
          attributes,
          thumbnail,
          messageIndex,
        };
      });
      setChat(messageDetails);
      const participants = await conversations.getParticipants();
      const lastMessageIndex =
        (await conversations.getMessages()).items.length - 1;
      console.log("lastMessageIndex: ", lastMessageIndex);
      if (
        lastMessageIndex > 0 &&
        conversations.lastReadMessageIndex < lastMessageIndex
      ) {
        const lastMessage = allMessages[lastMessageIndex];
        console.log("lastMessage========>", lastMessage.author);

        await conversations.updateLastReadMessageIndex(lastMessageIndex);
      }
      const readIndexes = participants.reduce((acc, participant) => {
        acc[participant.identity] = participant.lastReadMessageIndex;
        return acc;
      }, {});
      setReadIndex(readIndexes);
      setTimeout(() => {
        setLoadingScrollData(false);
      }, 1000);
      return allMessages;
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };
  const [readStatuses, setReadStatuses] = useState({});
  const [participants, setParticipants] = useState([]);
  const fetchConversation = async (conv) => {
    setMessageLoader(true);
    try {
      const messagesPage = await conv.getMessages(100);
      setMessages(messagesPage.items);
      setHasMoreMessages(messagesPage.hasPrevPage);
      setLoadingState("ready");
    } catch (error) {
      console.error("Error fetching messages:", error);
      setLoadingState("failed");
    }
    setMessageLoader(false);
  };
  const defaultContext = {
    setToken,
    token,
    setSelectedMessage,
    messages,
    setMessages,
    setSelectedConversationSid,
    loadingState,
    readReceipts,
    selectedMessage,
    setReadReceipts,
    selectedConversationSid,
    messageLoader,
    fetchConversation,
    hasMoreMessages,
    selectedUserProfileData,
    setSelectedUserProfileData,
    readIndex,
    setReadIndex,
    chat,
    setChat,
    getAllMessages,
    setIsLoading,
    client,
    getAllMessages,
    setIsLoading,
    setActiveConversation,
    activeConversation,
    readStatuses,
    participants,
    setReadStatuses,
    isLoading,
    hasMoreMessages,
    setHasMoreMessages,
    lastMessage,
    loadingScrollData,
    shouldScrollToId,
    setLastMessage,
  };
  return (
    <MessagingServiceContext.Provider value={defaultContext}>
      {children}
    </MessagingServiceContext.Provider>
  );
};
