import { useContext } from "react";
import MailIcon from "../../assets/images/sms.svg";
import PhoneIcon from "../../assets/images/smartphone.svg";
import { Row, Form, Input, Button } from "antd";
import CryptoJs from "crypto-js";
import "./verification.scss";
import { jsonPostDataCoach } from "../../util/coach-api-calls";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context/user-context";
import { message } from "antd/es";
const Verification = () => {
  const { setUserAccessToken, setUser, darkButtonBackground } =
    useContext(UserContext);
  const navigate = useNavigate();
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }

  const [form] = Form.useForm();
  const submitVerification = (e) => {
    let data;
    if (params.phone) {
      data = {
        phone: "+" + params.phone.replace(/ /g, ""),
        otp: e.otp,
      };
    } else {
      data = {
        email: params.email,
        otp: e.otp,
      };
    }
    jsonPostDataCoach(`coach/login`, data)
      .then((res) => {
        console.log(res);
        setUser(res.data.user);
        setUserAccessToken(res.data.access_token);
        localStorage.setItem("coach_id", res.data.user.id);
        localStorage.setItem("profile_id", res.data.selected_profile.id);
        localStorage.setItem(
          "coach_info",
          CryptoJs.AES.encrypt(JSON.stringify(res.data), "sec").toString()
        );
        navigate({ pathname: `../coach/ballogy-team-training` });
        form.resetFields();
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        message.error({
          content: err?.response?.data?.details,
          duration: 2,
        });
        if (
          err.response?.data?.details ===
          "You dont have any active group as coach."
        ) {
          navigate({ pathname: `../coach/no-subscription` });
        }
      });
  };
  return (
    <Row
      className="verification-main"
      style={{
        width: "100vw",
        height: "100vh",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="form-div">
        <img src={params.phone ? PhoneIcon : MailIcon} />
        <h1>Two Step Verification</h1>
        <span className="span">Enter the OTP we sent to</span>
        {params.email ? (
          <span className="mail">
            {params.email.replace(
              /^(.)(.*)(.@.*)/,
              (...a) => a[1] + a[2].replace(/./g, "*") + a[3]
            )}
          </span>
        ) : (
          <span className="mail">
            {params.phone.substring(0, 0) +
              "*".repeat(params.phone.length - (0 + 3)) +
              params.phone.slice(-3)}
          </span>
        )}
        <Form form={form} name="dynamic_rule" onFinish={submitVerification}>
          {" "}
          <Form.Item
            name="otp"
            label=""
            className="name-row"
            rules={[
              {
                required: true,
                message: "Please enter otp.",
              },
            ]}
          >
            <Input.OTP formatter={(str) => str.toUpperCase()} />
          </Form.Item>
          <Form.Item style={{ textAlign: "end", marginBottom: "0" }}>
            <Button
              htmlType="submit"
              className="dark-base-btn"
              style={{ background: darkButtonBackground }}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>{" "}
      </div>
    </Row>
  );
};

export default Verification;
