import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Upload,
  message,
  Input,
  Spin,
  notification,
  Space,
} from "antd";
import { MessagesContext } from "../../../coach-context/group-context/messages-context";
import MessageBubble from "./message-bubble";
import Img from "../../../assets/images/upload-img.svg";
import { UserContext } from "../../../context/user-context";
import { CloseCircleFilled } from "@ant-design/icons";
import { MessagingServiceContext } from "../../../coach-context/messaging-service-context";
import { formPostData } from "../../../util/coach-api-calls";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar"; // ES 2015
import ReactPlayer from "react-player";
import InfiniteScroll from "react-infinite-scroll-component";
dayjs.extend(calendar);
const ConversationBox = () => {
  const { darkButtonBackground, selectedSport } = useContext(UserContext);
  const scrollRef = useRef(null);
  const {
    checkedGroups,
    isCheckMembers,
    allIndiviually,
    sendIndividual,
    messageText,
    setMessageText,
    setImageUrl,
    imgSrc,
    setImgSrc,
    chatRoomDetail,
    imageUrl,
    setFileType,
    checkAllMembers,
    fileType,
    readMessges,
    sendLoading,
    setSendLoading,
    selectedProfile,
    selectedSubgroup,
  } = useContext(MessagesContext);
  const {
    setSelectedConversationSid,
    setToken,
    setSelectedUserProfileData,
    setReadIndex,
    setChat,
    chat,
    token,
    selectedConversationSid,
    client,
    getAllMessages,
    setIsLoading,
    setActiveConversation,
    activeConversation,
    selectedUserProfileData,
    participants,
    isLoading,
    selectedMessage,
    isCheckOwners,
    hasMoreMessages,
    loadingScrollData,
    shouldScrollToId,
    lastMessage,
  } = useContext(MessagingServiceContext);
  const [api, contextHolder] = notification.useNotification();
  const close = () => {
    notification.destroy(true);
    console.log(
      "Notification was closed. Either the close button was clicked or duration time elapsed."
    );
  };
  const openNotification = (placement) => {
    const key = `open${Date.now()}`;
    const btn = (
      <Space>
        <Button
          type="primary"
          size="medium"
          onClick={() => api.destroy(key)}
          style={{
            background: darkButtonBackground,
            border: "none",
            boxShadow: "none",
          }}
        >
          Ok
        </Button>
      </Space>
    );
    api.info({
      description: (
        <p style={{ color: "#181c32", fontWeight: 500 }}>
          Your message wasn't sent.Our system detected content that might not be
          appropriate for everyone. Please revise your message and try again.
        </p>
      ),
      placement: "top",
      btn,
      key,
      closable: false,
      onClose: close,
    });
  };
  useEffect(() => {
    if (chatRoomDetail) {
      setSelectedConversationSid(chatRoomDetail?.twilio_sid);
      // setToken(chatRoomDetail?.access_token);
    }
  }, [chatRoomDetail]);
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const handleBeforeUpload = (file) => {
    if (file.type.includes("image")) {
      setFileType("image");
      const reader = new FileReader();
      reader.onload = (e) => {
        setImgSrc(reader.result);
      };
      reader.readAsDataURL(file);
    } else if (file.type.includes("video")) {
      setFileType("video");
    } else {
    }
    if (!file.type.includes("image") && !file.type.includes("video")) {
      message.error(`Only Images/Videos files are allowed.`);
    } else if (
      // file.size <= 50000 ||
      file.size > 100000000
    ) {
      // greater than 50KB or
      message.error(`File size should be less than 100MB.`);
    } else {
      // setImageError(false)
      setImageUrl(file);

      // Prevent upload
      return false;
    }
  };

  const removeImage = () => {
    setImageUrl();
    setImgSrc();
  };
  const SendChatMessage = async (file, file_type) => {
    setSendLoading(true);
    if (
      allIndiviually === true ||
      (isCheckMembers?.length === 1 && isCheckOwners?.length === 1) ||
      isCheckMembers?.length > 1 ||
      isCheckOwners?.length > 1
    ) {
      // if(token === null && selectedConversationSid === null){
      await sendIndividual();
    } else {
      try {
        let formData = new FormData();
        formData.append("sender_id", selectedUserProfileData);
        formData.append("message", messageText);
        if (imageUrl) {
          formData.append("file", imageUrl);
          formData.append("file_type", fileType);
        }
        const response = await formPostData(
          `app/messages/${
            chatRoomDetail ? chatRoomDetail?.chatroom : selectedMessage?.id
          }/send`,
          formData
        );
        console.log("twilio send message api=======", response);
        if (response?.status === 200) {
          console.log("twilio send msg response", response);
          setIsLoading(false);
          setSendLoading(false);
          setMessageText("");
          setImageUrl();
          setImgSrc();
          readMessges(selectedMessage?.id);
          // scrollRef.current?.scrollToBottom({ animated: true });
        }
      } catch (error) {
        setIsLoading(false);
        setSendLoading(false);
        console.error("error", error);
      }
    }
  };

  const joinConversation = async () => {
    try {
      const conversation = await client.getConversationBySid(
        selectedConversationSid
          ? selectedConversationSid
          : chatRoomDetail?.twilio_sid
      );
      console.log("twilio Joined conversation:", conversation?.state);
      if (conversation) {
        await getAllMessages(conversation);
      }

      setIsLoading(false);
      setActiveConversation(conversation);
      return conversation;
    } catch (error) {
      setIsLoading(false);
      console.log("twilio Error joining conversation:", error);
    }
  };

  useEffect(() => {
    if (scrollRef.current && isLoading === false) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [isLoading, chat]);
  const handleScroll = (e) => {
    const bottom = e.target.scrollTop === 0;
    if (bottom) {
      // loadOlderMessages();
    }
  };
  useEffect(() => {
    if (client && (chatRoomDetail || selectedConversationSid)) {
      joinConversation()
        .then((conversation) => {
          listenForMessages(conversation);
        })
        .catch((error) => {
          console.error(
            "twilio Failed to join or create conversation: twilio",
            error
          );
        });
    }
    return () => {
      console.log("Cleaning up listeners...");
      console.log("client removing", client, activeConversation);
      // if (client) {
      //   client.shutdown();
      //   console.log("Twilio client shutdown");
      // }
      if (activeConversation !== null) {
        activeConversation.removeAllListeners("messageUpdated");
        activeConversation.removeAllListeners("messageRemoved");
        activeConversation.removeAllListeners("messageAdded");
        activeConversation.removeAllListeners("participantUpdated");
      }
    };
  }, [client, chatRoomDetail, activeConversation]);

  useEffect(() => {
    setSelectedUserProfileData(localStorage.getItem("profile_id"));
  }, []);

  const listenForMessages = (conversation) => {
    if (conversation) {
      conversation.removeAllListeners("messageAdded");
      conversation.removeAllListeners("participantUpdated");
      conversation.removeAllListeners("messageUpdated");
      conversation.removeAllListeners("messageRemoved");
      conversation.on("messageAdded", async (message) => {
        const parsedMessage = {
          sid: message?.sid,
          senderIdentity: message?.author,
          messageBody: message?.body,
          timestamp: message?.dateCreated,
          attributes: message?.attributes,
          messageType:
            message?.attributes?.is_media == false
              ? "text"
              : message?.attributes?.media_type,
          mediaUrl: message?.attributes?.media_url,
          thumbnail: message?.attributes?.media_thumbnail,
          profanity_status: message?.attributes?.profanity_status,
          message_identifier: message?.attributes?.message_identifier,
          twilioIndex: message?.index,
          messageIndex: message?.index,
        };
        let isReceiver =
          parsedMessage?.senderIdentity != selectedUserProfileData;
        let messageInReview = parsedMessage?.profanity_status == "PENDING";
        console.log("twilio parsed message", parsedMessage);

        if (!(isReceiver && messageInReview)) {
          setChat((prevChat) => [...prevChat, parsedMessage]);
        }
        // setLastMessage(message?.index)
        let div = document.getElementById("scrollableDiv");
        div.scrollTo(0, div.scrollHeight);
      });
      conversation.on(
        "participantUpdated",
        async ({ participant, updateReasons }) => {
          const participants = await conversation.getParticipants();
          participants.forEach((participant) => {
            console.log(
              `${participant.identity} last read message index:`,
              participant.lastReadMessageIndex
            );
          });

          if (updateReasons.includes("lastReadMessageIndex")) {
            setReadIndex((prevIndexes) => ({
              ...prevIndexes,
              [participant.identity]: participant.lastReadMessageIndex,
            }));
          }
        }
      );
      conversation.on("messageUpdated", async (data) => {
        const message = data.message;
        const parsedMessage = {
          sid: message?.sid,
          senderIdentity: message?.author,
          messageBody: message?.body,
          timestamp: message?.dateCreated,
          attributes: message?.attributes,
          messageType:
            message?.attributes?.is_media == false
              ? "text"
              : message?.attributes?.media_type,
          mediaUrl: message?.attributes?.media_url,
          thumbnail: message?.attributes?.media_thumbnail,
          profanity_status: message?.attributes?.profanity_status,
          message_identifier: message?.attributes?.message_identifier,
          twilioIndex: message?.index,
        };
        console.log(
          "twilio parsed message updated",
          message?.index,
          parsedMessage
        );

        let isReceiver =
          parsedMessage?.senderIdentity != selectedUserProfileData;
        const isSender =
          parsedMessage?.senderIdentity == selectedUserProfileData;
        if (isReceiver) {
          setChat((prevChat) => [...prevChat, parsedMessage]);
        } else {
          if (parsedMessage?.messageType == "IMAGE") {
            setChat((prevChat) =>
              prevChat.map((item) =>
                item.message_identifier == parsedMessage.message_identifier
                  ? { ...item, ...parsedMessage }
                  : item
              )
            );
          } else if (parsedMessage?.messageType != "IMAGE") {
            setChat((prevChat) =>
              prevChat.map((item) =>
                item.sid == parsedMessage.sid
                  ? { ...item, ...parsedMessage }
                  : item
              )
            );
          }
        }
        if (isSender && parsedMessage?.profanity_status == "FAILED") {
          openNotification();
        }
        await conversation.updateLastReadMessageIndex(message?.index);
      });
    }
  };
  useEffect(() => {
    if (shouldScrollToId !== "") {
      const element = document.getElementById(lastMessage);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [shouldScrollToId, lastMessage]);
  return (
    <>
      <div
        className="conversation-box"
        id="scrollableDiv"
        style={{
          display: "flex",
          flexDirection: "column-reverse",
        }}
      >
        {isLoading ? (
          <Spin
            className={
              "loader " +
              (selectedSport === 1
                ? "orange-theme-loader"
                : "blue-theme-loader")
            }
            size="large"
            style={{
              position: "absolute",
              left: "47%",
              top: "40%",
            }}
          />
        ) : (
          <>
            <InfiniteScroll
              dataLength={chat.length}
              next={getAllMessages}
              hasMore={hasMoreMessages && !loadingScrollData}
              loader={<Spin />}
              style={{ display: "flex", flexDirection: "column-reverse" }} //To put endMessage and loader to the top.
              inverse={true} //
              scrollableTarget="scrollableDiv"
              scrollThreshold={0.8}
            >
              <ul>
                {chat.map((item, index) => {
                  const messageDate = dayjs(
                    item?.attributes?.timestamp
                  ).startOf("day");
                  const prevMessageDate =
                    index > 0
                      ? dayjs(chat[index - 1]?.attributes?.timestamp).startOf(
                          "day"
                        )
                      : null;
                  const shouldShowDateHeader =
                    !prevMessageDate || !messageDate.isSame(prevMessageDate);

                  const formattedDate = dayjs(
                    item?.attributes?.timestamp
                  ).calendar(null, {
                    sameDay: "[Today]",
                    lastDay: "[Yesterday]",
                    lastWeek: "[Last] dddd",
                    sameElse: "DD/MM/YYYY",
                  });
                  return (
                    // item?.attributes?.profanity_status !== "FAILED" && (
                    <div
                      key={index}
                      id={
                        item.messageIndex ? item.messageIndex : item.twilioIndex
                      }
                    >
                      {shouldShowDateHeader && (
                        <div style={{ textAlign: "center" }}>
                          <div
                            style={{
                              color: "rgb(133,133,133)",
                              fontSize: 14,
                            }}
                          >
                            {formattedDate}
                          </div>
                        </div>
                      )}
                      {((Object.keys(selectedMessage).length > 0 &&
                        selectedMessage?.is_subgroup === true) ||
                        checkedGroups?.length > 0) &&
                      item?.attributes?.sender_type === "subgroup" ? (
                        <MessageBubble
                          key={index}
                          messageIndex={
                            item.messageIndex
                              ? item.messageIndex
                              : item.twilioIndex
                          }
                          direction="outgoing"
                          message={item}
                        />
                      ) : ((Object.keys(selectedMessage).length > 0 &&
                          selectedMessage?.is_subgroup === true) ||
                          Object.keys(selectedSubgroup)?.length > 0) &&
                        item?.attributes?.sender_type === "profile" &&
                        parseInt(item.senderIdentity) !==
                          selectedMessage.created_by_id &&
                        item.senderIdentity !==
                          localStorage.getItem("profile_id") ? (
                        <MessageBubble
                          key={index}
                          messageIndex={
                            item.messageIndex
                              ? item.messageIndex
                              : item.twilioIndex
                          }
                          direction="incoming"
                          message={item}
                        />
                      ) : Object.keys(selectedMessage).length > 0 &&
                        selectedMessage?.group_chat === false &&
                        selectedMessage?.profile_id !==
                          parseInt(item?.senderIdentity) ? (
                        <MessageBubble
                          key={index}
                          messageIndex={
                            item.messageIndex
                              ? item.messageIndex
                              : item.twilioIndex
                          }
                          direction="outgoing"
                          message={item}
                        />
                      ) : Object.keys(selectedMessage).length > 0 &&
                        selectedMessage?.group_chat === false &&
                        selectedMessage?.profile_id ===
                          parseInt(item?.senderIdentity) ? (
                        <MessageBubble
                          key={index}
                          messageIndex={
                            item.messageIndex
                              ? item.messageIndex
                              : item.twilioIndex
                          }
                          direction="incoming"
                          message={item}
                        />
                      ) : parseInt(item?.author) !== selectedProfile?.id &&
                        parseInt(item?.senderIdentity) !==
                          selectedProfile?.id ? (
                        <MessageBubble
                          key={index}
                          messageIndex={
                            item.messageIndex
                              ? item.messageIndex
                              : item.twilioIndex
                          }
                          direction="outgoing"
                          message={item}
                        />
                      ) : (
                        <MessageBubble
                          key={index}
                          messageIndex={
                            item.messageIndex
                              ? item.messageIndex
                              : item.twilioIndex
                          }
                          direction="incoming"
                          message={item}
                        />
                      )}
                    </div>
                  );
                  // );
                })}
              </ul>
            </InfiniteScroll>
          </>
        )}
      </div>
      {contextHolder}
      <div style={{ display: "flex", gap: 10, marginBottom: 30 }}>
        <Upload
          className={"uploader " + (imgSrc ? "margin" : "")}
          showUploadList={false}
          type="file"
          customRequest={dummyRequest}
          beforeUpload={handleBeforeUpload}
          disabled={imageUrl}
        >
          <Button icon={<img src={Img} />} disabled={imageUrl}></Button>
        </Upload>

        <div className="input-div" style={{ height: imageUrl ? 90 : 38 }}>
          {imageUrl && imgSrc ? (
            <>
              <img
                src={imgSrc}
                alt="avatar"
                style={{
                  width: "64px",
                  height: "64px",
                }}
                className="img"
              />
              <CloseCircleFilled
                style={{ color: "#5E6278", fontSize: 15 }}
                onClick={removeImage}
              />
            </>
          ) : (
            imageUrl && (
              <div className="player-preview">
                <ReactPlayer url={URL.createObjectURL(imageUrl)} />
                <CloseCircleFilled
                  style={{
                    color: "#5E6278",
                    fontSize: 15,
                    right: 0,
                    left: "auto",
                  }}
                  onClick={removeImage}
                />
              </div>
            )
          )}

          <Input
            style={{ width: imgSrc ? "90%" : "100%", marginLeft: "auto" }}
            placeholder={!imgSrc ? "Type your message here..." : ""}
            autoFocus
            value={messageText}
            disabled={isLoading && !checkAllMembers && !allIndiviually}
            onChange={(e) => setMessageText(e.target.value)}
            onKeyDown={(e) =>
              e.key === "Enter" &&
              (messageText.length !== 0 || imageUrl || imgSrc)
                ? SendChatMessage()
                : false
            }
          />
        </div>
        <Button
          className="red-base-btn"
          disabled={messageText.length === 0 && !imageUrl && !imgSrc}
          onClick={SendChatMessage}
          style={{
            background: darkButtonBackground,
            marginLeft: 0,
            color: "#fff",
            height: 38,
            width: sendLoading ? 100 : 65,
          }}
          loading={sendLoading}
        >
          {sendLoading ? "Sending" : "Send"}
        </Button>
      </div>
    </>
  );
};

export default ConversationBox;
