import React, { useContext, useEffect } from "react";
import { Input, Row, Col, Divider, Skeleton, Spin, Empty } from "antd";
import Avatar from "react-avatar";
import SearchIcon from "../../../assets/images/search.svg";
import InfiniteScroll from "react-infinite-scroll-component";
import { UserContext } from "../../../context/user-context";
import { MessagesContext } from "../../../coach-context/group-context/messages-context";
import { graphDate, getTime } from "../../../util/config";
import { MessagingServiceContext } from "../../../coach-context/messaging-service-context";

const InboxMessages = () => {
  const { selectedSport } = useContext(UserContext);
  const {
    setToken,
    setSelectedMessage,
    selectedMessage,
    conversations,
    setSelectedConversationSid,
  } = useContext(MessagingServiceContext);
  const {
    imageLoaded,
    setImageLoaded,
    messageListing,
    getMessageListing,
    hasMoreMessages,
    setSenderView,
    setNewMessage,
    loading,
    setSearch,
    setMessageListing,
    readMessges,
    setPageNumber,
    activeTab
  } = useContext(MessagesContext);
  const searchMessage = (e) => {
    if (e.target.value.length >= 3) {
      setPageNumber(1);
      setMessageListing((messageListing) => []);
      setSearch(e.target.value);
    } else if (e.target.value.length === 0) {
      setMessageListing((messageListing) => []);
      setSearch("");
    }
  };
  useEffect(() => {
    if (Object.keys(selectedMessage).length > 0) {
      setSelectedConversationSid(
        selectedMessage?.twilio_creds?.conversation_sid
      );
      // setToken(selectedMessage?.twilio_creds?.access_token);
      setSenderView(false);
      setNewMessage(true);
    }
  }, [conversations, selectedMessage]);
  return (
    <>
      <Input
        addonBefore={<img src={SearchIcon} />}
        placeholder="Search username, first, or last..."
        className="search-input-md"
        onChange={searchMessage}
        style={{ width: 240, marginLeft: "auto", margin: "15px 0" }}
      />
      <div className="msg-list">
        <div style={{ width: "100%" }}>
          <Row
            style={{ borderBottom: "1px dashed #e1e3ea", padding: "15px 24px" }}
          >
            <h2 style={{ width: "85%" , paddingLeft:48 }}>Member</h2>
            <h2 style={{ marginLeft: "auto", width: activeTab === "inbox" ? "10%" : "15%" , textAlign:"center" }}>Date</h2>
          </Row>
          {loading ? (
            <div style={{ height: 400, position: "relative" }}>
              <Spin
                className={
                  "loader " +
                  (selectedSport === 1
                    ? "orange-theme-loader"
                    : "blue-theme-loader")
                }
                size="large"
                style={{
                  position: "absolute",
                  left: "48%",
                  top: "50%",
                }}
              />
            </div>
          ) : loading === false && messageListing?.length > 0 ? (
            <InfiniteScroll
              dataLength={messageListing?.length}
              next={getMessageListing}
              height={400}
              hasMore={hasMoreMessages}
              loader={<h4>Loading...</h4>}
            >
              {messageListing &&
                messageListing.map(function (item, index) {
                  return (
                    <Row
                      key={index}
                      onClick={() => {
                        setPageNumber(1)
                        readMessges(item.id);
                        // setToken(item.twilio_creds.access_token);
                        setSelectedConversationSid(
                          item?.twilio_creds?.conversation_sid
                        );
                        setSelectedMessage(item);
                      }}
                    >
                      <Row className="msg-row">
                        <Col span={21} style={{ display: "flex" }}>
                          {item.avatar !== null && imageLoaded === "" && (
                            <Skeleton.Image
                              active={true}
                              className="user-skeleton"
                            />
                          )}
                          {!item.avatar ? (
                            <Avatar
                              name={item.username}
                              color={
                                selectedSport === 1 ? "#F15A29" : "#4EA7F8"
                              }
                              className="user-avatar"
                            />
                          ) : (
                            <img
                              src={item.avatar}
                              style={
                                imageLoaded === ""
                                  ? { display: "none" }
                                  : { display: "block" }
                              }
                              onLoad={() => setImageLoaded("loaded")}
                            ></img>
                          )}
                          <div className="name-div">
                            <h3 className="user-name">{item.username}</h3>
                            <h4 className="user-handle">{item.content}</h4>
                          </div>
                        </Col>
                        <Col
                          span={activeTab === "inbox" ? 2 : 3}
                          style={{
                            justifyContent: activeTab === "inbox" ? "flex-end" : "center",
                            display: "flex",
                          }}
                        >
                          <div>
                            <div className="date">
                              {graphDate(item.timestamp)}
                            </div>
                            <div className="time">
                              {getTime(item.timestamp)}
                            </div>
                          </div>
                        </Col>
                        {activeTab === "inbox" &&
                        <Col
                          span={1}
                          style={{
                            justifyContent: "flex-end",
                            display: "flex",
                          }}
                        >
                          {!item.is_read && <span className="new-sub"></span>}
                        </Col>
                        }
                      </Row>
                      <Divider dashed style={{ margin: 0 }} />
                    </Row>
                  );
                })}
            </InfiniteScroll>
          ) : (
            loading === false && (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                style={{ marginBlock: 130 }}
              />
            )
          )}
        </div>
      </div>
    </>
  );
};

export default InboxMessages;
