import React, { useEffect, useState, useContext } from "react";
import {
  deleteItemTest,
  getDataTest,
  patchData,
  patchDataTest,
} from "../../util/api-calls";
import { message } from "antd";
import { UserContext } from "../user-context";
export const VisionShootingContext = React.createContext({});
export const VisionShootingContextProvider = ({ children = {} }) => {
  const { selectedNotification } = useContext(UserContext);
  const [sessionsData, setSessionsData] = useState([]);
  const [selectedShooting, setSelectedShooting] = useState("");
  const [shootingDetailModal, setShootingDetailModal] = useState(false);
  const [shootingDetail, setShootingDetail] = useState({});
  const [shootingDetailView, setShootingDetailView] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [newTableData, setNewTableData] = useState(tableData);
  const [ptr2Make, setPtr2Make] = useState(0);
  const [ptr3Make, setPtr3Make] = useState(0);
  const [sort, setSort] = useState("desc");
  const [pr2Make, setPr2Make] = useState();
  const [ptr2Attempts, setPtr2Attempts] = useState();
  const [editingKey, setEditingKey] = useState(false);
  const [totalMake, setTotalMake] = useState(0);
  const [totalAttempts, setTotalAttempts] = useState(0);
  const [ptr3Attempts, setPtr3Attempts] = useState(0);
  // const [activeTab , setActiveTab] = useState("individual")
  const [loading, setLoading] = useState(false);
  const query = window.location.search;
  const urlParams = new URLSearchParams(query);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  function paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const getProfileSessions = () => {
    getDataTest(`vision/profile/${params.id}/session/list`)
      .then((res) => {
        setSessionsData(res.data.sessions);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getShootingDetail = (id) => {
    getDataTest(`vision/profile/${params?.id}/session/${id}`)
      .then((res) => {
        setShootingDetail(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getProfileSessions();
  }, []);

  const updataVisions = () => {
    setLoading(true);
    var attempts = newTableData.map(function (item, index) {
      let startTime;
      let endTime;
      if(typeof(item.shot_start_time) === "object" ){
        startTime = (item.shot_start_time.$m * 60 * 1000) + (item.shot_start_time.$s * 1000)
      }else{
        startTime = item.shot_start_time;
      }
      if(typeof(item.shot_end_time) === "object"){
        endTime = (item.shot_end_time.$m * 60 * 1000) + (item.shot_end_time.$s * 1000)
      }else{
        endTime = item.shot_end_time;
      }
      return {
        score: item?.make === true ? 1 : 0,
        make: item.make,
        zone: item.zone,
        shot_end_time: endTime,
        shot_start_time: startTime,
        timestamp: item.timestamp,
        id: item.id,
      };
    });
    const updatedData = attempts.map(item => {
      if (typeof item.id === "string" && item.id.startsWith("temp_id_")) {
          const { id, ...rest } = item; // Remove `id`
          return rest;
      }
      return item; // Keep original if `id` doesn't start with "temp_id_"
  });
    let data = {
      completion_time: shootingDetail.completion_time,
      total_attempts: ptr2Attempts + ptr3Attempts,
      total_make: ptr2Make + ptr3Make,
      total_2ptr_attempts: ptr2Attempts,
      total_2ptr_make: ptr2Make,
      total_3ptr_attempts: shootingDetail?.court === "NO_THREE_POINT_LINE"  ? 0 : ptr3Attempts,
      total_3ptr_make:  shootingDetail?.court === "NO_THREE_POINT_LINE"  ? 0 : ptr3Make,
      profile_id: shootingDetail.profile_id,
      media_uri: shootingDetail.media_uri,
      inaccurate_stats: shootingDetail.inaccurate_stats,
      attempts: updatedData,
    };
    console.log(data)
    patchDataTest(`vision/session/${shootingDetail.id}`, data)
      .then((res) => {
        message.success({
          content: res.data.details,
        });
        getShootingDetail(shootingDetail?.id);
        setEditingKey(false);
        getProfileSessions();
        // setShootingDetailModal(false)
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const deleteVisionSession = (id) => {
    deleteItemTest(`vision/session/${id}`)
      .then((res) => {
        message.success({
          content: res.data.details,
        });
        setShootingDetailModal(false);
        getProfileSessions();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (params.tab === "shooting_sessions") {
      getShootingDetail(selectedNotification?.session_id);
    }
  }, [selectedNotification]);
  const defaultContext = {
    sessionsData,
    setSelectedShooting,
    shootingDetailModal,
    setShootingDetailModal,
    setShootingDetail,
    getShootingDetail,
    shootingDetail,
    selectedShooting,
    loading,
    setLoading,
    shootingDetailView,
    setShootingDetailView,
    sort,
    setSort,
    updataVisions,
    ptr2Make,
    setPtr2Make,
    ptr3Make,
    setPtr3Make,
    tableData,
    setTableData,
    newTableData,
    setNewTableData,
    params,
    editingKey,
    setEditingKey,
    pr2Make,
    setPr2Make,
    ptr2Attempts,
    setPtr2Attempts,
    deleteVisionSession,
    totalMake,
    setTotalMake,
    totalAttempts,
    setTotalAttempts,
    ptr3Attempts,
    setPtr3Attempts,
    // activeTab,
    // setActiveTab
  };
  return (
    <VisionShootingContext.Provider value={defaultContext}>
      {children}
    </VisionShootingContext.Provider>
  );
};
