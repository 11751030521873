import { useContext, useEffect, useState } from "react";
import { Table, Button, Tooltip, Dropdown, Divider } from "antd";
import "./group-home.scss";
import FreemiumModal from "../../freemium-modal/freemium-modal";
import DownArrow from "../../../assets/images/arrows.svg";
import { useNavigate } from "react-router-dom";
import { getDateWithoutDay } from "../../../util/config";
import Plus from "../../../assets/images/plus.svg";
import {
  GroupContext,
  GroupContextProvider,
} from "../../../coach-context/group-context/group-context";
import { UserContext } from "../../../context/user-context";
import { TeamTrainingContext } from "../../../coach-context/team-training-context/team-training-context";
const GroupHomePage = ({teamManagementDetail}) => {
  const { licensesData , changeGroupStatus , loading,setClickedRow , clickedRow , groupsData,setGroupsData} = useContext(TeamTrainingContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { selectedSport, darkButtonBackground } = useContext(UserContext);
  const {
    pageNumber,
    pageSize,
    groups,
    totalGroups,
    handleTableChange,
    // loading,
    setPageNumber,
    setGroups,
    setSearch,
  } = useContext(GroupContext);
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    useEffect(()=>{
      if(Object.keys(licensesData)?.length > 0){
       teamManagementDetail = licensesData?.team_management_tiers[0]?.team_management_detail;
      }
    },[licensesData])
  const activateMenu = [
    {
      key: "active",
      label: "Activate",
    },
    {
      key: "detail",
      label: "View Detail",
      disabled:!clickedRow?.is_active
    },
  ];
  const deActivateMenu = [
    {
      key: "inactive",
      label: "Deactivate",
      // disabled:!clickedRow?.is_active
    },
    {
      key: "detail",
      label: "View Detail",
      disabled:!clickedRow?.is_active
    },
  ];
  var indents = [];
  for (var i = 0; i <= totalGroups; i++) {
    indents.push(
      <span className="indent" key={i}>
        {i}
      </span>
    );
  }
  const columns = [
    {
      title: "GROUP NAME",
      dataIndex: "name",
      key: "name",
      ellipsis: {
        showTitle: false,
      },
      render: (text, record, index) => {
        return (
          <Tooltip placement="bottom" title={record?.group?.name}>
            {record?.group?.name}
          </Tooltip>
        );
      },
    },
    {
      title: "Joined Members",
      dataIndex: "seats",
      key: "seats",
      render: (text, record, index) => {
        return <div key={index}>{record.group.members_count}</div>;
      },
    },
    {
      title: "SUB. EXPIRES",
      dataIndex: "expiry_date",
      key: "expiry_date",
      width: 200,
      render: (text, record, index) => {
        return (
          <div key={index}>
            {getDateWithoutDay(
              teamManagementDetail?.subscription_end_date
            )}
          </div>
        );
      },
    },
    {
      title: "GROUP STATUS",
      dataIndex: "status",
      key: "status",
      render: (text, record, index) => {
        return (
          <div key={index}>
            {record?.group?.is_active === true ? (
              <Button className="green-tag" key={index}>
                Active
              </Button>
            ) : (
              <Button className="pink-tag">Deactivate</Button>
            )}
          </div>
        );
      },
    },
    {
      title: "ACTIONS",
      dataIndex: "member_limit",
      key: "member_limit",
      render: (text, record, key) => {
        return(
        <Dropdown
          placeholder="Actions"
          trigger={"click"}
          onOpenChange={()=>setClickedRow(record)}
          className={
            "custom-dropdown " +
            (selectedSport === 1
              ? "orange-theme-dropdown"
              : "blue-theme-dropdown")
          }
          menu={{
            items : record?.group?.is_active ? deActivateMenu : activateMenu,
            onClick: ({ key }) => {
              if (key === "detail") {
                navigate({
                  pathname: `./detail`,
                  search: `?name=${record?.group?.name}&id=${record?.group?.id}`,
                });
              }
              else{
                changeGroupStatus(key)
              }
              // else if(key === "activate"){
              //   changeGroupStatus(record?.group?.id , "active")
              // }else if(key === "deactivate"){
              //   changeGroupStatus(record?.group?.id , "inactive")
              // }
            },
          }}
          overlayClassName={
            "custom-dropdown-menu " +
            (selectedSport === 1
              ? "orange-theme-dropdown"
              : "blue-theme-dropdown")
          }
        >
          <a onChange={(e) => e.preventDefault()}>
            Actions
            <img src={DownArrow} />
          </a>
        </Dropdown>
      )},
    },
  ];
  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const searchGroup = (e) => {
    if (e.target.value.length >= 3) {
      setPageNumber(1);
      setGroups([]);
      setSearch(e.target.value);
    } else if (e.target.value.length === 0) {
      setGroups((groups) => []);
      setSearch("");
    }
  };
  const createGroup = () =>{
    if(teamManagementDetail?.is_freemium && groupsData?.length >= 1){
      setIsModalOpen(true)
    }else{
      setGroupsData(groupsData=>[])
      navigate(`./create-new-group?team_management_id=${teamManagementDetail?.id}`)
    }
  }
  return (
    <div className="group-home-coach">
      <div style={{ position: "absolute", right: 0, top: "8px" }}></div>
      <div className="table-div">
        <div style={{ display: "flex" }}>
          <h4 style={{ margin: "10px 0", fontWeight: 600, color: "#3f4254" }}>
            Your Groups
          </h4>
          <Button
            icon={<img src={Plus} />}
            className="dark-base-btn"
            style={{ marginLeft: "auto", background: darkButtonBackground }}
            onClick={createGroup}
          >
            Create Group
          </Button>
        </div>
        <Divider />
        {/* <div style={{ display: "flex" }}>
          <Input
            addonBefore={<img src={Search} />}
            placeholder="Search"
            className="search-input"
            onChange={searchGroup}
          />
          <div className="right-div">
            <h4>Total Groups: </h4>
            <h4 style={{ fontWeight: "700" }}> {groupsData?.length}</h4>
          </div>
        </div> */}
        {groupsData?.length === 0 ? 
               <div className="no-data">
              You haven’t created any Groups yet.
             </div>
        :
        <Table
          onRow={(record, rowIndex) => {
            return {
              // onClick: () => 
              // {      navigate({
              //   pathname: `./detail`,
              //   search: `?name=${record.name}&id=${record.id}`,
              // });}
                
                // gotoGroupDetail(record),
            };
          }}
          loading={loading}
          onChange={handleTableChange}
          rowKey={(obj) => obj.id}
          pagination={{
            pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
            showSizeChanger: true,
            locale: { items_per_page: "" },
            defaultPageSize: 10,
            current: pageNumber,
            pageSize: pageSize,
            total: totalGroups,
          }}
          columns={columns}
          dataSource={groupsData}
          className={
            "table-container " +
            (selectedSport === 1 ? "orange-theme" : "blue-theme")
          }
        />
}
      </div>
      <FreemiumModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </div>
  );
};

const GroupHome = ({teamManagementDetail}) => {
  return (
    <GroupContextProvider>
      <GroupHomePage teamManagementDetail={teamManagementDetail}/>
    </GroupContextProvider>
  );
};
export default GroupHome;
